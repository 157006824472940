import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit {

  timeout = false;
  private defaultTimeOut = 30;
  private timeRunning = false;

  @Input('message')
  message: string = null;

  @Input('timeoutInSeconds')
  set setTimeoutInSeconds(seconds: any) {
    if (!this.timeRunning) {
      this.startTimer(seconds);
    }
  }

  ngOnInit() {
    if (!this.timeRunning) {
      this.startTimer(this.defaultTimeOut);
    }
  }

  private startTimer(seconds: number) {
    this.timeRunning = true;
    setTimeout(() => {
      this.timeout = true;
    }, seconds * 1000);
  }
}
