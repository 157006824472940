<ion-content>
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button translucent="true" (click)="close()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <p *ngIf="!bbStorage.fixedGymMode">
    {{"guest-info-component.logged-in-as-guest"|translate}}
    <ion-chip (click)="logoutAndCreateAccount()">
      <ion-label color="primary">{{"guest-info-component.create-an-account"|translate}}</ion-label>
    </ion-chip>
  </p>
  <p *ngIf="bbStorage.fixedGymMode"
     [innerHTML]='"guest-info-component.install-the-app"|translate:{activeGymName: activeGymName}'>
  </p>
  <div class="center" *ngIf="bbStorage.fixedGymMode">
    <qrcode colordark="#313230" colorLight="#ffe367" [qrdata]="qrCodeUrl" [width]="256"
            [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</ion-content>
