export class EditableFootholdConfig {
  constructor(
    public withHandholds: boolean = false,
    public withSpax: boolean = false,
    public allowedColors: string[] = [],
    public allHoldsAllowed: boolean = false,
    public withSmallFootholds: boolean = false) {
  }

}
