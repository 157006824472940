import {UserSession} from '../model/user-session';
import {Storage} from '@ionic/storage';
import {Injectable} from '@angular/core';
import {BoulderFilter, SortType} from '../model/boulder-filter';
import {FeedItem} from '../model/feed-item';
import {GymDBDoc} from '../common/docs';
import {Boulder} from '../model/boulder';
import {FeedItemType} from '../model/feed-item-type';


const LANGUAGE_KEY = 'language';
const BOULDER_SORT_TYPE_KEY = 'boulderListSortType';
const BOULDER_FILTER_KEY = 'boulderFilter';
const LATEST_DOC_SHOWN_FEED_TIME_CREATED_KEY = 'latestDocShownInFeedTimeCreated';
const LAST_TIME_GYM_CHAT_VISITED = 'lastTimeGymChatVisited';
const FIRST_BOULDER_CREATION_KEY = 'firstBoulderCreation';
const AUDIO_ON_KEY = 'audioOn';
const FIXED_GYM_MODE_KEY = 'fixedGymMode';
const USER_SESSION_TOKEN_KEY = 'token';
const USER_SESSION_USER_KEY = 'user-id';
const GRADE_SYSTEM_ID = 'grade-system-id';
const ALWAYS_REBUILD_BOULDER_CACHE = 'allways-rebuild-boulder-cache';
const BETA_MODE = 'beta-mode';
const INSTA_MESSAGE_SHOWN = 'insta-message-shown';
const FEED_FILTER = 'feed-filter';

// active gym properties
const ACTIVE_CACHE_GYM_ID = 'active-cache-gym-id';
const ACTIVE_GYM_POUCH_UPDATE_SEQ_LAST_SYNC = 'active-gym-pouch-update-seq-last-sync';
const ACTIVE_GYM_COUCH_UPDATE_SEQ_LAST_SYNC = 'active-gym-couch-update-seq-last-sync';
const ACTIVE_GYM_BOULDER_CACHE_LAST_SYNC = 'active-gym-boulder-cache-last-sync';
const ACTIVE_GYM_FEED_ITEM_CACHE_LAST_SYNC = 'active-gym-feed-item-cache-last-sync';
const ACTIVE_GYM_BOULDERS = 'active-gym-boulders';
const ACTIVE_GYM_FEED_ITEMS = 'active-gym-feed-items';

// schema
const COUCH_SCHEMA_KEY = 'couchSchema';
const REST_SCHEMA_KEY = 'restSchema';
const TYPE_DEFINITIONS_KEY = 'typeDefinitions';

@Injectable()
export class BBStorage {

  private _language: string;
  private _lastTimeGymChatVisitedPerGym: any;
  private _userSession: UserSession;

  private _latestDocShownInFeedTimeCreated: any;
  private _activeCacheGymId: string;
  private _activeGymFeedItems: Map<string, FeedItem<GymDBDoc>>;
  private _activeGymPouchUpdateSeqLastSync: string;
  private _activeGymCouchUpdateSeqLastSync: string;
  private _activeGymBoulderCacheLastSync: string;
  private _activeGymFeedItemCacheLastSync: string;
  private _activeGymBouldersById: Map<string, Boulder>;
  private _audioOn: boolean;
  private _firstBoulderCreation: boolean;
  private _instagramMessageShown: boolean;
  private _boulderListSortType: SortType;
  private _boulderFilter: BoulderFilter;
  private _fixedGymMode: boolean;
  private _gradeSystemId: string;
  private _betaMode: boolean;
  private _feedFilter: FeedItemType[];

  /*
    For android devices it seems quering pouch for specific, e.g. newly added documents
    is far more expensive than loading all documents at once. To improve loading times for these devices this flag can be used
    to manually configure if the boulder cache should be rebuild entirely if something in the pouch has changed.
  */
  private _alwaysRebuildEntireBoulderCache = true;

  // schema
  private _restSchema: any;
  private _couchSchema: any;
  private _typeDefinitions: any;

  constructor(private storage: Storage) {
  }

  public async clear() {
    this._language = null;
    this._userSession = null;
    this._firstBoulderCreation = null;
    this._fixedGymMode = null;
    this._boulderListSortType = null;
    this._restSchema = null;
    this._couchSchema = null;
    this._typeDefinitions = null;
    this._latestDocShownInFeedTimeCreated = null;
    this._lastTimeGymChatVisitedPerGym = null;
    this._feedFilter = [];
    await this.storage.clear();
    localStorage.clear();
  }

  async initialize() {
    this._alwaysRebuildEntireBoulderCache = true; //await this.loadValueForKeyOrDefault(ALWAYS_REBUILD_BOULDER_CACHE, false);
    this._betaMode = await this.loadValueForKeyOrDefault(BETA_MODE, false);
    this._instagramMessageShown = await this.loadValueForKeyOrDefault(INSTA_MESSAGE_SHOWN, false);
    this._gradeSystemId = await this.loadValueForKeyOrDefault(GRADE_SYSTEM_ID, 'font');
    this._language = await this.loadValueForKeyOrDefault(LANGUAGE_KEY, null);
    this._audioOn = await this.loadValueForKeyOrDefault(AUDIO_ON_KEY, false);
    this._firstBoulderCreation = await this.loadValueForKeyOrDefault(FIRST_BOULDER_CREATION_KEY, true);
    this._fixedGymMode = await this.loadValueForKeyOrDefault(FIXED_GYM_MODE_KEY, false);
    this._boulderListSortType = await this.loadValueForKeyOrDefault(BOULDER_SORT_TYPE_KEY, 'date');
    this._boulderFilter = await this.loadValueForKeyOrDefault(BOULDER_FILTER_KEY, BoulderFilter.empty());
    this._couchSchema = await this.loadValueForKeyOrDefault(COUCH_SCHEMA_KEY);
    this._restSchema = await this.loadValueForKeyOrDefault(REST_SCHEMA_KEY);
    this._typeDefinitions = await this.loadValueForKeyOrDefault(TYPE_DEFINITIONS_KEY);
    this._activeCacheGymId = await this.loadValueForKeyOrDefault(ACTIVE_CACHE_GYM_ID);
    this._activeGymCouchUpdateSeqLastSync = await this.loadValueForKeyOrDefault(ACTIVE_GYM_COUCH_UPDATE_SEQ_LAST_SYNC);
    this._activeGymPouchUpdateSeqLastSync = await this.loadValueForKeyOrDefault(ACTIVE_GYM_POUCH_UPDATE_SEQ_LAST_SYNC);
    this._activeGymBoulderCacheLastSync = await this.loadValueForKeyOrDefault(ACTIVE_GYM_BOULDER_CACHE_LAST_SYNC);
    this._activeGymFeedItemCacheLastSync = await this.loadValueForKeyOrDefault(ACTIVE_GYM_FEED_ITEM_CACHE_LAST_SYNC);
    this._activeGymBouldersById = await this.loadValueForKeyOrDefault(ACTIVE_GYM_BOULDERS);
    this._activeGymFeedItems = await this.loadValueForKeyOrDefault(ACTIVE_GYM_FEED_ITEMS, new Map<string, FeedItem<GymDBDoc>>());
    this._latestDocShownInFeedTimeCreated = await this.loadValueForKeyOrDefault(LATEST_DOC_SHOWN_FEED_TIME_CREATED_KEY, {});
    this._lastTimeGymChatVisitedPerGym = await this.loadValueForKeyOrDefault(LAST_TIME_GYM_CHAT_VISITED, {});
    this._feedFilter = await this.loadValueForKeyOrDefault(FEED_FILTER, []);
    const token = await this.storage.get(USER_SESSION_TOKEN_KEY);
    const userName = await this.storage.get(USER_SESSION_USER_KEY);
    this._userSession = token && userName ? {
      user_name: userName,
      token: token
    } : null;
  }


  get feedFilter(): FeedItemType[] {
    return this._feedFilter;
  }

  get boulderFilter(): BoulderFilter {
    return this._boulderFilter;
  }

  get restSchema(): any {
    return this._restSchema;
  }

  get typeDefinitions(): any {
    return this._typeDefinitions;
  }

  get couchSchema(): any {
    return this._couchSchema;
  }

  get firstBoulderCreation(): boolean {
    return this._firstBoulderCreation;
  }


  get audioOn(): boolean {
    return this._audioOn;
  }

  set audioOn(value: boolean) {
    this._audioOn = value;
    this.updateValue(AUDIO_ON_KEY, value);
  }

  get boulderListSortType(): SortType {
    return this._boulderListSortType;
  }

  get userSession(): UserSession {
    return this._userSession;
  }

  get loggedIn(): boolean {
    return this._userSession !== null;
  }

  getLatestDocShownInFeedTimeCreatedForGym(gymId: string): Date {
    if (!this._latestDocShownInFeedTimeCreated[gymId]) {
      this.setLatestDocShownInFeedTimeCreated(new Date(), gymId);
    }
    return this._latestDocShownInFeedTimeCreated[gymId];
  }

  getLastTimeGymChatVisitedForGym(gymId: string): any {
    if (!this._lastTimeGymChatVisitedPerGym[gymId]) {
      this.setLastTimeGymChatVisited(new Date(), gymId);
    }
    return this._lastTimeGymChatVisitedPerGym[gymId];
  }

  get schemaAvailable(): boolean {
    return this._couchSchema != null && this._restSchema != null && this._typeDefinitions != null;
  }

  async setUserSession(session: UserSession) {
    await this.updateValue(USER_SESSION_TOKEN_KEY, session?.token);
    await this.updateValue(USER_SESSION_USER_KEY, session?.user_name);
    this._userSession = session?.token && session?.user_name ? {
      user_name: session.user_name,
      token: session.token
    } : null;
  }

  async resetActiveGymCache() {
    await this.setPouchUpdateSeqLastSync(null);
    await this.setCouchUpdateSeqLastSync(null);
    await this.setActiveGymBoulders(null);
    await this.setActiveGymBoulderCacheLastSync(null);
    await this.setActiveGymFeedItems(new Map<string, FeedItem<GymDBDoc>>());
    await this.setActiveGymFeedItemCacheLastSync(null);
    this.boulderFilter = null;
  }

  setAlwaysRebuildEntireBoulderCache(value: boolean) {
    this._alwaysRebuildEntireBoulderCache = value;
    this.updateValue(ALWAYS_REBUILD_BOULDER_CACHE, value);
  }

  get alwaysRebuildEntireBoulderCache() {
    return this._alwaysRebuildEntireBoulderCache;
  }

  setBetaMode(value: boolean) {
    this._betaMode = value;
    this.updateValue(BETA_MODE, value);
  }

  get betaMode() {
    return this._betaMode;
  }

  async setPouchUpdateSeqLastSync(updateSeq: string) {
    this._activeGymPouchUpdateSeqLastSync = updateSeq;
    await this.updateValue(ACTIVE_GYM_POUCH_UPDATE_SEQ_LAST_SYNC, updateSeq);
  }

  async setCouchUpdateSeqLastSync(updateSeq: string) {
    this._activeGymCouchUpdateSeqLastSync = updateSeq;
    await this.updateValue(ACTIVE_GYM_COUCH_UPDATE_SEQ_LAST_SYNC, updateSeq);
  }

  async setFeedFilter(filter: FeedItemType[]) {
    this._feedFilter = filter;
    await this.updateValue(FEED_FILTER, filter);
  }

  get gradeSystemId(): string {
    return this._gradeSystemId;
  }

  set gradeSystemId(value: string) {
    this._gradeSystemId = value;
    this.updateValue(GRADE_SYSTEM_ID, value);
  }

  get activeGymBoulderCacheLastSync(): string {
    return this._activeGymBoulderCacheLastSync;
  }

  async setActiveGymBoulderCacheLastSync(value: string) {
    this._activeGymBoulderCacheLastSync = value;
    await this.updateValue(ACTIVE_GYM_BOULDER_CACHE_LAST_SYNC, value);
  }

  get activeCacheGymId(): string {
    return this._activeCacheGymId;
  }

  async setActiveCacheGymId(value: string) {
    this._activeCacheGymId = value;
    await this.updateValue(ACTIVE_CACHE_GYM_ID, value);
  }

  get activeGymFeedItemCacheLastSync(): string {
    return this._activeGymFeedItemCacheLastSync;
  }

  async setActiveGymFeedItemCacheLastSync(value: string) {
    this._activeGymFeedItemCacheLastSync = value;
    await this.updateValue(ACTIVE_GYM_FEED_ITEM_CACHE_LAST_SYNC, value);
  }

  async setSchema(couchSchema, restSchema, typeDefinitions) {
    this._typeDefinitions = typeDefinitions;
    this._restSchema = restSchema;
    this._couchSchema = couchSchema;
    await this.updateValue(TYPE_DEFINITIONS_KEY, typeDefinitions);
    await this.updateValue(REST_SCHEMA_KEY, restSchema);
    await this.updateValue(COUCH_SCHEMA_KEY, couchSchema);
  }

  set firstBoulderCreation(value: boolean) {
    this._firstBoulderCreation = value;
    this.updateValue(FIRST_BOULDER_CREATION_KEY, value);
  }


  get fixedGymMode(): boolean {
    return this._fixedGymMode;
  }


  get activeGymFeedItems(): Map<string, FeedItem<GymDBDoc>> {
    return this._activeGymFeedItems;
  }

  async setActiveGymFeedItems(value: Map<string, FeedItem<GymDBDoc>>) {
    this._activeGymFeedItems = value;
    this.updateValue(ACTIVE_GYM_FEED_ITEMS, value);
  }

  set fixedGymMode(value: boolean) {
    this._fixedGymMode = value;
    this.updateValue(FIXED_GYM_MODE_KEY, value);
  }

  set boulderListSortType(value: SortType) {
    this._boulderListSortType = value;
    this.updateValue(BOULDER_SORT_TYPE_KEY, value);
  }

  set boulderFilter(value: BoulderFilter) {
    this._boulderFilter = value ? value : BoulderFilter.empty();
    this.updateValue(BOULDER_FILTER_KEY, value);
  }


  get activeGymBouldersById(): Map<string, Boulder> {
    return this._activeGymBouldersById;
  }

  async setActiveGymBoulders(value: Map<string, Boulder>) {
    this._activeGymBouldersById = value;
    await this.updateValue(ACTIVE_GYM_BOULDERS, value);
  }

  setLatestDocShownInFeedTimeCreated(date, gymId: string) {
    this._latestDocShownInFeedTimeCreated[gymId] = date;
    this.updateValue(LATEST_DOC_SHOWN_FEED_TIME_CREATED_KEY, this._latestDocShownInFeedTimeCreated);
  }

  setLastTimeGymChatVisited(date, gymId: string) {
    this._lastTimeGymChatVisitedPerGym[gymId] = date;
    this.updateValue(LAST_TIME_GYM_CHAT_VISITED, this._lastTimeGymChatVisitedPerGym);
  }

  private async loadValueForKeyOrDefault(key: string, defaultValue: any = null) {
    const value = await this.storage.get(key);
    return value !== null ? value : defaultValue;
  }

  async updateValue(key: string, value: any) {
    if (value == null) {
      await this.storage.remove(key);
    } else {
      await this.storage.set(key, value);
    }
  }


  get activeGymPouchUpdateSeqLastSync(): string {
    return this._activeGymPouchUpdateSeqLastSync;
  }

  get activeGymCouchUpdateSeqLastSync(): string {
    return this._activeGymCouchUpdateSeqLastSync;
  }

  get language(): string {
    return this._language;
  }

  set language(value: string) {
    this._language = value;
    this.updateValue(LANGUAGE_KEY, value);
  }


  get instagramMessageShown(): boolean {
    return this._instagramMessageShown;
  }

  set instagramMessageShown(value: boolean) {
    this._instagramMessageShown = value;
    this.updateValue(INSTA_MESSAGE_SHOWN, value);
  }
}
