import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { CommonUtils } from '../common';
import { BBStorage } from '../utils/bb-storage';
import { CouchService } from './couch.service';
import { PouchDBService } from './pouch-db.service';

@Injectable()
export class DbChangesService {

  constructor(
    private activeGymFacade: ActiveGymFacade,
    private logger: NGXLogger,
    private bbStorage: BBStorage,
    private couchService: CouchService,
    private pouchDBService: PouchDBService) {
  }

  public async couchHasChanged() {
    return !this.bbStorage.activeGymCouchUpdateSeqLastSync || this.couchHasChangedComparedTo(this.bbStorage.activeGymCouchUpdateSeqLastSync);
  }

  public async couchHasChangedComparedTo(seqNumber: string) {
    const latestSyncSeq = await this.getCouchUpdateSeq();
    this.logger.debug(`Comparing current couch seq ${latestSyncSeq} with ${seqNumber}`);
    return latestSyncSeq !== seqNumber;
  }

  public async pouchHasChangedComparedTo(seqNumber: string) {
    const pouchSeq = await this.getPouchUpdateSeq();
    this.logger.debug(`Comparing current pouch seq ${pouchSeq} with ${seqNumber}`);
    return pouchSeq !== seqNumber;
  }

  public async pouchHasChanged() {
    return !this.bbStorage.activeGymPouchUpdateSeqLastSync || this.pouchHasChangedComparedTo(this.bbStorage.activeGymPouchUpdateSeqLastSync);
  }

  public async getCouchUpdateSeq(): Promise<string> {
    try {
      const activeGymId = await this.activeGymId();
      if (!activeGymId) {
        return null;
      }
      const couch = this.couchService.getCouchAccess(CommonUtils.getDBNameForGymId(activeGymId));
      const info = await couch.info();
      return info.update_seq as string;
    } catch (e) {
      // catching errors here to avoid problems with bad connection
      this.logger.info('Could not receive update sequence of couchdb. Maybe connection problems? ', e);
      return null;
    }
  }

  public async getPouchUpdateSeq(): Promise<string> {
    const activeGymId = await this.activeGymId();
    try {
      if (activeGymId && await this.pouchDBService.gymReferenceExists(activeGymId)) {
        const pouch = await this.pouchDBService.getExistingPouch(activeGymId);
        const info = await pouch.info();
        return info.update_seq.toString();
      } else {
        this.logger.info(`Pouch for gym ${activeGymId} does not exist. Return '0' as pouch update sequence`);
        return '0';
      }
    } catch (error) {
      // trying to find issue
      const scope = new Sentry.Scope();
      scope.setTag('gym_id', activeGymId);
      scope.setTag('issue', 'pouch_update_seq');
      Sentry.captureException(error, scope);
      this.logger.error('Could not get pouch update seq ', error);
      return '0';
    }
  }

  private async updateCouchUpdateSeq() {
    await this.bbStorage.setCouchUpdateSeqLastSync(await this.getCouchUpdateSeq());
  }

  private async updatePouchUpdateSeq() {
    await this.bbStorage.setPouchUpdateSeqLastSync(await this.getPouchUpdateSeq());
  }

  public async updateUpdateSeq() {
    await this.updatePouchUpdateSeq();
    await this.updateCouchUpdateSeq();
  }

  public async resetUpdateSeq() {
    await this.bbStorage.setCouchUpdateSeqLastSync(null);
    await this.bbStorage.setPouchUpdateSeqLastSync(null);
  }

  private async activeGymId() {
    return await firstValueFrom(this.activeGymId$());
  }

  private activeGymId$() {
    return this.activeGymFacade.activeGymId$;
  }

}
