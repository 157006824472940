import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState, usersFeatureKey } from './users.reducer';
import { User } from 'src/model/user';

export const selectUsersState = createFeatureSelector<UsersState>(usersFeatureKey);
export const getUsers = createSelector(
    selectUsersState,
    (state: UsersState) => state?.userByUsername
);
export const getUser = (username: string) => createSelector(getUsers, (users) => {
    return users[username];
});
export const getSupporterType = (username: string) => createSelector(getUsers, (users) => {
    return users[username]?.supporterType;
});
