import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { GymDBDocType } from '../common/doc-types';
import { GymChatMessageDoc } from '../common/docs';
import { DocsCreator } from '../common/docs-creator';
import { Message } from '../model/message';
import { SortByDatePipe } from '../pipes/sort-by-date.pipe';
import { BBStorage } from '../utils/bb-storage';
import { AuthService } from './auth.service';
import { DocQueryService } from './doc.query.service';
import { DocWriteService } from './doc.write.service';
import { GymService } from './gym.service';

@Injectable()
export class GymChatService {

  private _numUnreadMessages = 0;

  constructor(
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private bbStorage: BBStorage,
    private gymService: GymService,
    private activeGymFacade: ActiveGymFacade,
    private authService: AuthService) {
  }

  public async createMessage(message: string) {
    await this.docWriteService.addDocument(DocsCreator.createGymChatMessage(message, this.authService.getCurrentUsername()
    ));
    await this.gymService.syncActiveGym();
  }

  public async deleteMessage(messageId: string) {
    await this.docWriteService.removeDocOfTypeWithId(GymDBDocType.GYM_CHAT_MESSAGE, messageId);
    await this.gymService.syncActiveGym();
  }

  public loadMessagesAndUpdateNumUnread(): Observable<Message[]> {
    return this.docQueryService.getDocsOfType$<GymChatMessageDoc>(GymDBDocType.GYM_CHAT_MESSAGE).pipe(
      map( messageDocs => {
        const messages = messageDocs.map(m => new Message(m._id, new Date(m.timeCreated), m.message,  m.author))
          .sort((a, b) =>
            SortByDatePipe.compareDates(a.date, b.date));
        this.updateNumUnread(messageDocs);
        return messages;
      }));
  }

  private async updateNumUnread(messages: GymChatMessageDoc[]) {
    const activeGymId = await firstValueFrom(this.activeGymFacade.activeGymId$);
    const lastTimeVisited = this.bbStorage.getLastTimeGymChatVisitedForGym(activeGymId);
    this._numUnreadMessages = messages.filter(
      m => m.author !== this.authService.getCurrentUsername() && new Date(m.timeCreated) > lastTimeVisited).length;
  }

  public async markChatForActiveGymAsVisited() {
    const activeGymId = await firstValueFrom(this.activeGymFacade.activeGymId$);
    this.bbStorage.setLastTimeGymChatVisited(new Date(), activeGymId);
  }

  get numUnreadMessages(): number {
    return this._numUnreadMessages;
  }

}
