export class CommonUtils {
  public static createUUID(): string {
    return this.uuid(30, true);
  }

  public static uuid(length, withUpper): string {
    let charset = 'abcdefghijklmnopqrstuvvwxyz0123456789';
    if (withUpper) {
      charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    let result = '';
    for (let i = 0; i < length; ++i) {
      result += charset[Math.floor(Math.random() * charset.length)];
    }
    return result;
  }

  static getDBNameForGymId(gymId: string) {
    if (!gymId.startsWith('gym_info+')) {
      throw new Error('expected gym id, but got: ' + gymId);
    }
    return gymId.replace('gym_info+', 'gym_');
  }

  static getGymIdForDBName(dbName: string) {
    if (!dbName.startsWith('gym_')) {
      throw new Error('expected gym db name, but got: ' + dbName);
    }
    return dbName.replace('gym_', 'gym_info+');
  }
}
