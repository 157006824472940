import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { Boulder } from 'src/model/boulder';
import { Wall } from 'src/model/wall';
import { WallImage } from 'src/model/wall-image';
import { BoulderService } from 'src/services/boulder.service';
import { WallService } from 'src/services/wall.service';

@Component({
  selector: 'app-wall-images',
  templateUrl: './wall-images.component.html',
  styleUrls: ['./wall-images.component.scss'],
})
export class WallImagesComponent implements OnInit {

  wall: Wall;
  public isLoading = false;
  private boulders: Boulder[];

  constructor(
    private modalController: ModalController,
    private boulderService: BoulderService,
    private wallService: WallService
  ) {
  }
  private async loadBoulders() {
    this.isLoading = true;
    try {
      this.boulders = await firstValueFrom(this.boulderService.getAllTimeBoulders$());
    } finally {
      this.isLoading = false;
    }
  }

  ngOnInit() {
    this.loadBoulders();
  }

  numBoulders(wallImageId: string) {
    return this.boulders?.filter(b => b.wallImage.wallImageId === wallImageId).length;
  }

  async activateWallImage(wallImage: WallImage) {
    this.isLoading = true;
    try {
      await this.wallService.updateCurrentness(wallImage.wallImageId, 'current');
      this.reloadWall();
    } finally {
      this.isLoading = false;
    }
  }

  async deactivateWallImage(wallImage: WallImage) {
    this.isLoading = true;
    try {
      await this.wallService.updateCurrentness(wallImage.wallImageId, 'expired');
      this.reloadWall();
    } finally {
      this.isLoading = false;
    }
  }

  private async reloadWall() {
    const walls = await firstValueFrom(this.wallService.loadWalls$());
    this.wall = walls.find(w => w.wallId === this.wall.wallId);
  }

  public async close() {
    await this.modalController.dismiss();
  }
}
