import {Component, Input, OnInit} from '@angular/core';
import {SchemaService} from '../../services/schema.service';
import {ValidationService} from '../../services/validation.service';
import {BoulderListService} from '../../services/boulder-list.service';
import {AlertController, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ErrorNotificationService} from '../../services/error-notification.service';
import {GuestService} from '../../services/guest-service';
import {firstValueFrom} from 'rxjs';
import {GuestInfoComponent} from '../../components/guest-info/guest-info.component';

@Component({
  selector: 'app-add-to-boulder-list',
  templateUrl: './add-to-boulder-list.page.html',
  styleUrls: ['./add-to-boulder-list.page.scss'],
})
export class AddToBoulderListPage implements OnInit {

  @Input('boulderId')
  boulderId: string;

  private changed = false;
  lists = [];

  constructor(
    private errorHandler: ErrorNotificationService,
    private schemaService: SchemaService,
    private validationService: ValidationService,
    private boulderListService: BoulderListService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    private guestService: GuestService
  ) {

  }

  ngOnInit() {
    this.reloadLists();
  }

  private async reloadLists() {
    this.lists = [];
    const boulderLists = await firstValueFrom(this.boulderListService.getOwnLists());
    for (const list of boulderLists) {
      this.lists.push({name: list.name, id: list.listId, isChecked: list.boulderIds.indexOf(this.boulderId) > -1});
    }
  }

  async createNewList() {
    if (this.guestService.isGuest()) {
      GuestInfoComponent.showAsModal(this.modalCtrl);
      return;
    }
    const alert = await this.alertCtrl.create({
      cssClass: 'create-list',
      header: this.translateService.instant('add-to-boulder-list-page.create-new-list'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: this.translateService.instant('add-to-boulder-list-page.name-for-new-list'),
          attributes: {
            minLength: 3,
            maxLength: 100
          }
        }
      ],
      buttons: [
        {
          text: this.translateService.instant('add-to-boulder-list-page.public'),
          handler: async (data) => {
            await this.boulderListService
              .createNewList(data.name, true);
            this.changed = true;
            await this.reloadLists();
          }
        },
        {
          text: this.translateService.instant('add-to-boulder-list-page.private'),
          handler: async (data) => {
            await this.boulderListService
              .createNewList(data.name, false);
            this.changed = true;
            await this.reloadLists();
          }
        }
      ]
    });

    await alert.present();
  }


  async addToSelectedLists() {
    for (const list of this.lists) {
      if (list.isChecked) {
        await this.boulderListService.addBoulderIdToList(this.boulderId, list.id);
      } else {
        await this.boulderListService.removeBoulderIdFromList(this.boulderId, list.id);
      }
    }
    this.changed = true;
    await this.close();
  }

  async close() {
    await this.modalCtrl.dismiss(this.changed);
  }
}
