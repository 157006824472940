import { Injectable, Signal, signal } from '@angular/core';
import { Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
import { NGXLogger } from 'ngx-logger';

const APP_STORE_PRODUCT_SUPPORTER_BASIC_ID = 'basicsupporter';
const APP_STORE_PRODUCT_SUPPORTER_MEDIUM_ID = 'mediumsupporter';
const APP_STORE_PRODUCT_SUPPORTER_HIGH_ID = 'highsupporter';
const APP_STORE_PRODUCT_SUPPORTER_ULTRA_ID = 'ultrasupporter';

const PRODUCT_IDS = [APP_STORE_PRODUCT_SUPPORTER_BASIC_ID, APP_STORE_PRODUCT_SUPPORTER_MEDIUM_ID, APP_STORE_PRODUCT_SUPPORTER_HIGH_ID, APP_STORE_PRODUCT_SUPPORTER_ULTRA_ID];

@Injectable()
export class AppStoreService {

    store?: CdvPurchase.Store;
    products = signal<DonateProduct[]>([]);

    public isSupporter = signal(false);

    constructor(
        private logger: NGXLogger,
        private platform: Platform) {
        if (this.platform.is('capacitor')) {
            this.platform.ready().then(() => {
                this.initStore();
            });
        }
    }

    private initStore() {
        this.store = CdvPurchase.store;
        this.registerProducts();
    }

    private async registerProducts() {
        if (this.platform.is('ios')) {
            this.logger.info('register supporter product for ios');
            this.store.register([
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_BASIC_ID,
                    platform: CdvPurchase.Platform.APPLE_APPSTORE,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_MEDIUM_ID,
                    platform: CdvPurchase.Platform.APPLE_APPSTORE,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_HIGH_ID,
                    platform: CdvPurchase.Platform.APPLE_APPSTORE,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_ULTRA_ID,
                    platform: CdvPurchase.Platform.APPLE_APPSTORE,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
            ]);
        } else if (this.platform.is('android')) {
            this.logger.info('register supporter product for android');
            this.store.register([
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_BASIC_ID,
                    platform: CdvPurchase.Platform.GOOGLE_PLAY,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_MEDIUM_ID,
                    platform: CdvPurchase.Platform.GOOGLE_PLAY,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_HIGH_ID,
                    platform: CdvPurchase.Platform.GOOGLE_PLAY,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
                {
                    id: APP_STORE_PRODUCT_SUPPORTER_ULTRA_ID,
                    platform: CdvPurchase.Platform.GOOGLE_PLAY,
                    type: CdvPurchase.ProductType.NON_CONSUMABLE,
                },
            ]);
        }
        await this.store.initialize();
        this.products.set(this.store.products.map(p => {
            return {
                productId: p.id,
                price: p.pricing.price
            } as DonateProduct
        }));
        this.logger.info('CdvPurchase products', this.products());
        await this.setupListeners();
        this.store.verbosity = CdvPurchase.LogLevel.DEBUG;
        this.store.error(console.warn);
    }

    public getProducts(): Signal<DonateProduct[]> {
        return this.products;
    }


    private setupListeners() {
        this.store.when().approved((t) => {
            t.products.forEach((p: CdvPurchase.Product) => {
                if (PRODUCT_IDS.includes(p.id)) {
                    this.isSupporter.set(true);
                }
            });
        });
        this.store.when().receiptsReady(() => {
            for (const product of this.store.products) {
                const owned = this.store.owned(product.id);
                if (owned) {
                    this.isSupporter.set(true);
                }
            }
        });
    }

    public async purchase(productId: string) {
        console.log('purchase', productId)
        const product = this.getProduct(productId);
        console.log('product ', product);
        const offer = product.getOffer();
        if (offer) {
            console.log('offer', offer)
            await this.store.order(offer);
        }
    }

    private getProduct(productId: string) {
        const { Platform } = CdvPurchase;
        const platform = this.platform.is('ios') ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY;
        return this.store.get(productId, platform);
    }

}

export interface DonateProduct {
    readonly productId: string;
    readonly price: string;
}
