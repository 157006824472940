import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Wall } from 'src/model/wall';
import { ImageService } from 'src/services/image.service';

@Directive({
  selector: '[wall]'
})
export class WallDirective implements OnInit {

  @Input('wall') wall: Wall;

  constructor(
    private imageService: ImageService,
    private renderer: Renderer2,
    private el: ElementRef) {
  }

  ngOnInit() {
    this.loadWallImageUrl();
  }

  private async loadWallImageUrl() {
    let src: any = 'assets/imgs/nowallimage.png';
    if (this.wall?.wallImages?.length > 0) {
      const latestWallImage = this.wall.wallImages[0];
      src = await this.imageService.wallImagePath(latestWallImage);
    }
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);

  }

}
