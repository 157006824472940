import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {GuestService} from '../services/guest-service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate, CanActivateChild {
  constructor(private guestService: GuestService, private router: Router) {
  }

  canActivate(): boolean {
    if (this.guestService.isGuest()) {
      this.guestService.redirectToGuestInfoPage();
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }

}
