import { Observable } from 'rxjs';
import { GymInfoDoc } from '../common/docs';
import { GymListEntry } from '../model/gym-list-entry';

export abstract class GymService {

  abstract getLocalGyms$(): Observable<GymListEntry[]>;

  abstract getLocalGym$(gymId: string): Observable<GymListEntry>;

  abstract removeRemoteGym(gymId: string): Promise<void>;

  abstract getRemoteGymsThatAreNotLocalYet$(): Observable<GymListEntry[]>;

  abstract getGymListEntry$(gymId: string): Observable<GymListEntry>;

  abstract isLocalGym(gymId: string): Promise<boolean>;

  abstract addLocalGym(gymId: string): Promise<void>;

  abstract deleteGym(gymId: string): Promise<void>;

  abstract syncActiveGym(): Promise<void>;

  abstract updateActiveGym(gymName, city, country, isPrivate, gymRules, gymAdmins: string[]): Promise<GymInfoDoc>;

}
