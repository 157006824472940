import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild} from '@angular/core';
import {GradeService} from '../../services/grade.service';
import {ActionSheetOptions, IonicModule, ModalController} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {register} from 'swiper/element/bundle';
import {Swiper} from 'swiper';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {GradeServiceModule} from '../../services/grade-service.module';

type ThumbType = 'thumb-up' | 'thumb-down' | 'no-thumb';

@Component({
  standalone: true,
  selector: 'add-ascent',
  imports: [GradeServiceModule, IonicModule, FormsModule, CommonModule, TranslateModule],
  templateUrl: 'AddAscentComponent.html',
  styleUrls: ['AddAscentComponent.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AddAscentComponent implements OnInit {

 @ViewChild('gradeSelector', {static: true}) gradeSelector: any;

  private swiper: Swiper;

  public gradeChooserOptions: ActionSheetOptions;

  public currentAverageGrade: number = null;

  public possibleGrades: SelectableGrade[] = [];

  public qualityRating: ThumbType = null;
  public isFlash: string = '';
  public personalGrade: any;
  public isOwnBoulder: boolean = false;

  constructor(
    translateService: TranslateService,
    private modalController: ModalController,
    private gradeService: GradeService) {
    this.possibleGrades = this.gradeService.getCurrentGradeSystem().possibleGrades().map(grade => {
      return {grade: grade, description: gradeService.getCurrentGradeSystem().displayName(grade)} as SelectableGrade;
    });
    register();
    this.gradeChooserOptions = {
      header: translateService.instant('add-ascent-component.choose-grade'),
      cssClass: 'grade-chooser',
      buttons: []
    };
  }

  ngOnInit(): void {
    this.initSwiper();
  }

  private initSwiper() {
    this.swiper = new Swiper('.swiper', {
      direction: 'vertical'
    });

  }


  public thumbSelected() {
    this.swiper.slideNext();
    this.gradeSelector.open();
  }

  flashChosen() {
    if (this.isOwnBoulder) {
      this.thumbSelected();
    } else {
      this.swiper.slideNext();
    }
  }

  async dismiss(newAscent: NewAscent = null) {
    await this.modalController.dismiss(newAscent);
  }

  public gradeSelected() {
    const newAscent: NewAscent = {
      personalGrade: this.personalGrade,
      thumb: this.qualityRating,
      isFlash: this.isFlash === 'flash'
    };
    this.dismiss(newAscent);
  }
}

class SelectableGrade {
  public readonly description: string;
  public readonly grade: number;

  constructor(description, grade) {
    this.description = description;
    this.grade = grade;
  }
}

export interface NewAscent {
  personalGrade: number;
  thumb: ThumbType;
  isFlash: boolean;
}
