export default class DateUtils {

  static formatDate(date: Date): string {
    const monthNames = [
      'Januar', 'Februar', 'März',
      'April', 'Mai', 'Juni', 'Juli',
      'August', 'September', 'Oktober',
      'November', 'Dezember'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  static formatDateAndTime(date: Date): string {
    const dateString = this.formatDate(date);
    return dateString + ' um ' + date.getHours() + ':' + date.getMinutes() + 'Uhr';
  }

  public static ageInDays(date) {
    const diffInMs = (Date.now() - date);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

}
