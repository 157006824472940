import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SupporterType } from 'src/model/user';
import { UsersFacade } from 'src/store/users/users.facade';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule],
  selector: 'username',
  template: `
    <span>{{username ?? 'anonymous'}}
      <ion-icon *ngIf="!hideSupporterIcon && supporterType ==='basic'" src="assets/icons/supporter-heart.svg"></ion-icon>
      <ion-icon *ngIf="!hideSupporterIcon && supporterType ==='medium'" src="assets/icons/supporter-heart.svg"></ion-icon>
      <ion-icon *ngIf="!hideSupporterIcon && supporterType ==='high'" src="assets/icons/supporter-heart.svg"></ion-icon>
      <ion-icon *ngIf="!hideSupporterIcon && supporterType ==='ultra'" src="assets/icons/supporter-heart.svg"></ion-icon>
      </span>
  `,
  styleUrls: [],
})
export class UsernameComponent {

  _username: string = null;

  @Input('hideSupporterIcon')
  hideSupporterIcon = false;

  supporterType: SupporterType = null;

  constructor(private usersFacade: UsersFacade) {
  }

  @Input('username')
  set username(username: string) {
    this._username = username;
    if (!this.hideSupporterIcon) {
      this.usersFacade.getSupporterType$(username).subscribe(supporterType => {
        this.supporterType = supporterType;
      });
    }

  }

  get username(): string {
    return this._username;
  }
}
