<ion-card>
    <img class="wallImage" [src]="imageUrl" *ngIf="imageUrl">
    <ion-spinner name="lines" *ngIf="!imageUrl"></ion-spinner>
    <ion-card-header>
        <ion-card-title>
            <ion-button (click)="isActionSheetOpen = true" fill="clear" shape="round" color="primary">
                <ion-icon slot="end" name="create"></ion-icon>
                {{wallName}} {{"edit-wall-card-component.wall" | translate}}
            </ion-button>
            <ion-action-sheet class="edit-wall" [isOpen]="isActionSheetOpen" [header]="wallName" [buttons]="actionSheetButtons"></ion-action-sheet>
        </ion-card-title>
    </ion-card-header>
</ion-card>