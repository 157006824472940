import { Component } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private screenOrientation: ScreenOrientation,
    private platform: Platform
  ) {
    this.platformReady();
  }

  platformReady() {
    this.platform.ready().then(() => {
      if (this.isCapacitor()) {
        SplashScreen.hide();
        if (!this.isIos()) {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color: '#ef4b89' });
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
      }
    });
  }

  private isCapacitor() {
    return this.platform.is('capacitor');
  }

  private isIos() {
    return this.platform.is('ios') || this.platform.is('iphone');
  }

}
