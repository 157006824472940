import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {HttpClient} from '@angular/common/http';
import {RestEndpoints} from '../utils/rest-endpoints';
import {NGXLogger} from 'ngx-logger';
import {firstValueFrom} from 'rxjs';

@Injectable()
export class VersionService {

  private serverVersion: string;
  private appVersion: string;

  constructor(
    private logger: NGXLogger,
    private restService: RestService,
    private httpClient: HttpClient) {
  }

  public async getAppVersion(): Promise<string> {
    if (this.appVersion) {
      return this.appVersion;
    }
    this.appVersion = (await firstValueFrom(this.httpClient.get('assets/version.txt', {responseType: 'text'}))).trim();
    this.logger.debug(`Loaded app-version: ${this.appVersion}`);
    return this.appVersion;
  }

  public async getServerVersion(): Promise<string> {
    if (this.serverVersion) {
      return this.serverVersion;
    }
    this.serverVersion = (await firstValueFrom(this.httpClient.get(RestEndpoints.VERSION, {responseType: 'text'}))).trim();
    this.logger.debug(`Loaded server-version: ${this.serverVersion}`);
    return this.serverVersion;
  }

  public async majorVersionUpToDate(): Promise<Boolean> {
    try {
      const appVersion = await this.getAppVersion();
      const serverVersion = await this.getServerVersion();

      const appMajor = parseInt(appVersion.split('.')[0]);
      const serverMajor = parseInt(serverVersion.split('.')[0]);

      return appMajor >= serverMajor;
    } catch (error) {
      this.logger.warn('Could not compare major version of app. We pretend it is up-to-date', error);
      return true;
    }
  }

  public async versionUpToDate(): Promise<Boolean> {
    try {
      const appVersion = await this.getAppVersion();
      const serverVersion = await this.getServerVersion();
      return appVersion === serverVersion;
    } catch (error) {
      this.logger.warn('Could not compare version of app. We pretend it is up-to-date', error);
      return true;
    }
  }

}
