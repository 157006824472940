import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Wall} from '../../model/wall';
import {WallService} from '../../services/wall.service';
import {UploadWallImageModalPage} from '../upload-wall-image-modal/upload-wall-image-modal.page';
import {ModalController} from '@ionic/angular';
import { WallResetPage } from '../wall-reset/wall-reset.page';

@Component({
  selector: 'app-walls',
  templateUrl: './walls.page.html',
  styleUrls: ['./walls.page.scss'],
})
export class WallsPage implements OnInit {

  walls$: Observable<Wall[]>;
  private wallEdited: boolean = false;

  constructor(
    private modalController: ModalController,
    private wallService: WallService) {
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.walls$ = this.wallService.loadWalls$();
  }

  async reset() {
    await this.dismiss();
    const modal = await this.modalController.create({
        component: WallResetPage,
        id: 'WallResetPage'
      }
    );
    await modal.present();
  }

  async wallAdded(wall: Wall) {
    this.loadData();
    const modal = await this.modalController.create({
        component: UploadWallImageModalPage,
        componentProps: {'wall': wall},
        id: 'UploadWallImageModalPageModal'
      }
    );
    modal.onDidDismiss().then((data) => {
      this.loadData();
    });
    await modal.present();
  }

  async dismiss() {
    await this.modalController.dismiss(this.wallEdited);
  }

  wallWasRemoved() {
    this.loadData();
  }
}
