import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SupporterType, User } from 'src/model/user';
import { addUser, updateUsers } from './users.actions';
import { getSupporterType, getUser } from './users.selector';

@Injectable({ providedIn: 'root' })
export class UsersFacade {

    #store = inject(Store);

    getUser$(username: string): Observable<User> {
        return this.#store.select(getUser(username));
    }

    getSupporterType$(username: string): Observable<SupporterType> {
        return this.#store.select(getSupporterType(username));
    }

    updateUsers() {
        this.#store.dispatch(updateUsers());
    }

    addUser(user: User) {
        this.#store.dispatch(addUser({ user: user }));
    }

}
