
export class Message {
  constructor(
    readonly messageId: string,
    readonly date: Date,
    readonly text: string,
    readonly author: string
  ) {
  }
}
