import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, map, Observable } from 'rxjs';
import { DBQueryResult } from '../common/db-query-result';
import { GymDBDocType } from '../common/doc-types';
import {
  AscentDoc,
  BoulderDoc,
  CommentDoc,
  DifficultyRatingDoc,
  FootholdConfig,
  QualityRatingDoc,
  WallImageDoc
} from '../common/docs';
import { DocsCreator } from '../common/docs-creator';
import { AscentListEntry } from '../model/ascent-list-entry';
import { Boulder } from '../model/boulder';
import { BoulderComment } from '../model/boulder-comment';
import { BoulderListEntry } from '../model/boulder-list-entry';
import { EditableBoulder } from '../model/editable-boulder';
import { WallImage } from '../model/wall-image';
import { AuthService } from './auth.service';
import { BoulderService } from './boulder.service';
import { DifficultyRatingService } from './difficulty-rating.service';
import { DocQueryService } from './doc.query.service';
import { DocWriteService } from './doc.write.service';
import { GymService } from './gym.service';
import { QualityRatingService } from './quality-rating.service';

@Injectable()
export class PouchBoulderService implements BoulderService {

  constructor(
    private logger: NGXLogger,
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private difficultyRatingService: DifficultyRatingService,
    private gymService: GymService,
    private authService: AuthService) {
  }

  public async deleteBoulder(boulderId: string) {
    await this.docWriteService.removeDocOfTypeWithId(GymDBDocType.BOULDER, boulderId);
    await this.docWriteService.removeDocsOfTypeWithQuery<CommentDoc>(GymDBDocType.COMMENT, {
      selector: {
        boulderId: boulderId
      }
    });
    await this.docWriteService.removeDocsOfTypeWithQuery<AscentDoc>(GymDBDocType.ASCENT, {
      selector: {
        boulderId: boulderId
      }
    });
    await this.docWriteService.removeDocsOfTypeWithQuery<DifficultyRatingDoc>(GymDBDocType.DIFFICULTY_RATING, {
      selector: {
        boulderId: boulderId
      }
    });
    await this.docWriteService.removeDocsOfTypeWithQuery<QualityRatingDoc>(GymDBDocType.QUALITY_RATING, {
      selector: {
        boulderId: boulderId
      }
    });
    await this.gymService.syncActiveGym();
  }

  public async addOrUpdateBoulder(boulder: EditableBoulder): Promise<string> {
    const boulderDoc = await this.getOrCreateDoc(boulder);
    await this.docWriteService.addDocument(boulderDoc);
    await this.difficultyRatingService.setPersonalRating(boulderDoc._id, boulder.proposedGrade);
    await this.gymService.syncActiveGym();
    return boulderDoc._id;
  }

  private async getOrCreateDoc(boulder: EditableBoulder): Promise<BoulderDoc> {
    if (boulder.boulderId) {
      const doc = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$<BoulderDoc>(GymDBDocType.BOULDER, boulder.boulderId));
      return {
        ...doc,
        boulderName: boulder.boulderName,
        wallId: boulder.wallId,
        wallImageId: boulder.wallImage.wallImageId,
        holds: boulder.holds,
        footholdConfig: boulder.footholdConfig,
        isPrivate: boulder.isPrivate
      };
    } else {
      return DocsCreator.createBoulder(
        boulder.boulderName,
        boulder.wallId,
        boulder.wallImage.wallImageId,
        this.authService.getCurrentUsername(),
        boulder.holds,
        boulder.footholdConfig as FootholdConfig,
        boulder.isPrivate, boulder.enumerateHolds);
    }
  }

  public async setBoulderToPublic(boulderId: string): Promise<void> {
    const boulder = await firstValueFrom(this.getBoulderDetails$(boulderId));
    const editableBoulder = new EditableBoulder(boulder);
    editableBoulder.isPrivate = false;
    await this.addOrUpdateBoulder(editableBoulder);
  }

  public getBouldersListEntries$(): Observable<BoulderListEntry[]> {
    return this.getBoulders$();
  }

  public getBoulders$(): Observable<Boulder[]> {
    return this.fetchBouldersFromDB$(false);
  }

  public getAllTimeBoulders$(): Observable<Boulder[]> {
    return this.fetchBouldersFromDB$(true);
  }

  public fetchBouldersFromDB$(allTime: boolean): Observable<Boulder[]> {
    const start = performance.now();
    return this.docQueryService.getAllDocs().pipe(
      map((dbQueryResult: DBQueryResult) => {
        const boulders = this.createBoulders(dbQueryResult, allTime);
        this.logger.debug('fetchBouldersFromDB$ took ', performance.now() - start);
        return boulders;
      }
      ));
  }

  private createBoulders(dbQueryResult: DBQueryResult, allTime: boolean): Boulder[] {
    const start = performance.now();
    const boulders = [];
    for (const boulderDoc of Array.from(dbQueryResult.boulderDocById.values()).filter(doc => !doc.isPrivate || doc.author === this.authService.getCurrentUsername())) {
      const wallImageDoc = dbQueryResult.wallImageDocs.filter(i => i._id === boulderDoc.wallImageId)[0];
      const wallDoc = dbQueryResult.wallDocs.filter(w => w._id === boulderDoc.wallId)[0];
      if (wallImageDoc?.currentness === 'current' || allTime) {
        const isProject: boolean = dbQueryResult.boulderListDocs.filter(b => b.author === this.authService.getCurrentUsername() && DocsCreator.isProjectBoulderListId(b._id, this.authService.getCurrentUsername()))[0]?.boulderIds.indexOf(boulderDoc._id) > -1
        boulders.push(PouchBoulderService.createBoulder(
          boulderDoc,
          boulderDoc.author,
          wallDoc?.wallName ?? '',
          wallImageDoc,
          dbQueryResult.difficultyRatingDocsByBoulderId.get(boulderDoc._id) ?? [],
          dbQueryResult.qualityRatingDocsByBoulderId.get(boulderDoc._id) ?? [],
          dbQueryResult.ascentDocsByBoulderId.get(boulderDoc._id) ?? [],
          dbQueryResult.commentDocsByBoulderId.get(boulderDoc._id) ?? [],
          this.authService.getCurrentUsername(), isProject)
        );
      }
    }
    this.logger.debug('createBoulders took ', performance.now() - start);
    return boulders;
  }

  public static createBoulder(
    boulderDoc: BoulderDoc,
    author: string,
    wallName: string,
    wallImageDoc: WallImageDoc,
    difficultyRatingDocs: DifficultyRatingDoc[],
    qualityRatingDocs: QualityRatingDoc[],
    ascentsDocs: AscentDoc[],
    commentDocs: CommentDoc[],
    currentUsername: string,
    isProject: boolean,
  ): Boulder {
    const wallImage = new WallImage(wallImageDoc._id, wallImageDoc.imageId, wallImageDoc.currentness, wallImageDoc.timeUploaded);
    const suggestedGradeByAuthor: number = difficultyRatingDocs.filter(d => d.author === boulderDoc.author)[0]?.difficulty;
    const commentsForBoulder = commentDocs.filter(c => c.boulderId === boulderDoc._id).map(c => new BoulderComment(new Date(c.timeCreated), c.comment, c.author, c._id));
    const ascents = ascentsDocs.map(a => new AscentListEntry(new Date(a.timeCreated), a.ascentType === 'flash', a.author, difficultyRatingDocs.filter(d => d.author === a.author)[0]?.difficulty, a._id));
    const boulderDifficulty =
      DifficultyRatingService.computeGrade(ascents.filter(a => a.personalGrade).map(a => a.personalGrade), suggestedGradeByAuthor);
    const quality =
      QualityRatingService.createBoulderQuality(qualityRatingDocs.filter(q => q.boulderId === boulderDoc._id && q.author !== boulderDoc.author && ascents.filter(a => a.userName === q.author).length > 0));
    const ticked = ascentsDocs.filter(a => a.author === currentUsername).length > 0;
    return new Boulder(wallName, boulderDoc.wallId, wallImage, boulderDoc.boulderName,
      boulderDoc._id, author, boulderDoc.holds,
      boulderDoc.footholdConfig, boulderDifficulty.grade, suggestedGradeByAuthor,
      new Date(boulderDoc.timeCreated), quality, ascents, commentsForBoulder, ticked, boulderDoc.isPrivate, isProject, boulderDoc.enumerateHolds);
  }

  public getBoulderDetails$(boulderId: string): Observable<Boulder> {
    return this.getBoulders$().pipe(
      map(boulders => boulders.filter(b => b.boulderId === boulderId)[0]));
  }

}
