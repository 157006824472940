<loader *ngIf="isLoading" [message]="'wall-images-component.loading-message' | translate"></loader>
<ion-content class="ion-padding" *ngIf="!isLoading">
  <ion-fab slot="fixed" horizontal="end" vertical="top">
    <ion-fab-button translucent="true" (click)="close()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <h1 class="header" [innerHTML]='"wall-images-component.header" | translate:{ wallName: wall.wallName}'></h1>
  <p class="header">{{"wall-images-component.subheader" | translate}}</p>
  <ion-card *ngFor="let wallImage of wall.wallImages">
    <img [wallImage]="wallImage">
    <ion-card-header>
      <ion-card-title><ion-icon class="active" slot="start" name="checkmark-circle-outline"></ion-icon>
        {{"wall-images-component.photos-from" | translate}}
        {{wallImage.timeUploaded | dateFormat}}</ion-card-title>
      <ion-card-subtitle>{{ numBoulders(wallImage.wallImageId) }}
        {{"wall-images-component.boulders-created-with-this-photo" | translate}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <p *ngIf="wallImage.currentness=='current'"><ion-icon class="active" slot="start"
          name="checkmark-circle-outline"></ion-icon><span
          [innerHTML]='"wall-images-component.photo-up-to-date" | translate:{ numBoulders: numBoulders(wallImage.wallImageId)}'></span><ion-chip
          (click)="deactivateWallImage(wallImage)">
          <ion-label>{{"shared.here" | translate}}</ion-label>
        </ion-chip></p>
      <p *ngIf="wallImage.currentness!='current'"><ion-icon class="inactive" slot="start"
          name="close-circle-outline"></ion-icon>{{"wall-images-component.photo-not-up-to-date" | translate}}<ion-chip
          (click)="activateWallImage(wallImage)">
          <ion-label>{{"shared.here" | translate}}</ion-label>
        </ion-chip></p>
    </ion-card-content>
  </ion-card>
</ion-content>