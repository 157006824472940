import { WallImage } from './wall-image';

export class Wall {

  private _wallImages: WallImage[] = [];

  constructor(
    readonly wallName: string,
    readonly wallId: string,
    readonly author: string) {
  }

  public set wallImages(value: WallImage[]) {
    this._wallImages = value;
  }

  public get wallImages(): WallImage[] {
    return this._wallImages;
  }

}
