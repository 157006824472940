import pako from 'pako';

export class HoldsResolverService {

  private getHoldsArray(holdsArrayBuffer: ArrayBuffer): Uint16Array {
    const array = pako.inflate(new Uint8Array(holdsArrayBuffer));
    const dataView = new DataView(array.buffer)
    return new Uint16Array(dataView.buffer, dataView.byteOffset, dataView.byteLength / Uint16Array.BYTES_PER_ELEMENT)
  }


  public async findNearestHolds(xClicked: number, yClicked: number, holdsArrayBuffer: ArrayBuffer, imageWidth: number, imageHeight: number) {
    const holds = this.getHoldsArray(holdsArrayBuffer);
    console.log('holds ', holds)
    const detectedHold: DetectedHold = this.findClosestHold(xClicked, yClicked, holds, imageWidth);
    console.log('detectedHold', detectedHold);
    if (!detectedHold) {
      return null;
    }
    let minX = 100_000;
    let maxY = 0;
    let minY = 100_000;
    let maxX = 0;
    for (let i = 0; i < holds.length; i++) {
      if (holds[i] === detectedHold.holdIndex) {
        const y = Math.floor(i / imageWidth);
        const x = Math.floor(i - y * imageWidth);
        console.log('x', x)
        console.log('y', y)
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      }
    }
    const width = Math.max(0, maxX - minX);
    const height = Math.max(0, maxY - minY);
    const maxRadius = Math.min(imageHeight, imageWidth) / 20;

    console.log('imageHeight', imageHeight)
    console.log('imageWidth', imageWidth)
    console.log('maxRadius', maxRadius)
    console.log('width', width)
    console.log('height', height)

    const padding = 20;
    const newRadius = Math.min(Math.max(width / 2, height / 2) + padding, maxRadius);
    console.log('newRadius', newRadius)
    let newCenterX = minX + width / 2;
    let newCenterY = minY + height / 2;
    if (Math.abs(newCenterX - detectedHold.x) > maxRadius || Math.abs(newCenterY - detectedHold.y) > maxRadius) {
      newCenterX = detectedHold.x;
      newCenterY = detectedHold.y
    }
    return {centerX: newCenterX, centerY: newCenterY, radius: newRadius};
  }

  private findClosestHold(xClicked: number, yClicked: number, holds: Uint16Array, imageWidth: number): DetectedHold {
    console.log('clicked on ', xClicked, yClicked)
    const holdIndex = holds[imageWidth * yClicked + xClicked];
    if (holdIndex === 0) {
      console.log('No hold clicked! trying to find closest hold...')
      for (let radius = 1; radius < 100; radius++) {
        const minX = xClicked - radius;
        const maxX = xClicked + radius;
        const minY = yClicked - radius;
        const maxY = yClicked + radius;
        console.log('radius ', radius);
        // upper and lower border
        for (let xStep = minX; xStep < maxX; xStep++) {
          console.log('xStep ', xStep);
          let hold = holds[imageWidth * maxY + xStep];
          if (hold !== 0) {
            console.log('Found hold on upper edge ', xStep, maxY, hold);
            return {x: xStep, y: maxY, holdIndex: hold};
          }
          hold = holds[imageWidth * minY + xStep];
          if (hold !== 0) {
            console.log('Found hold on lower edge ', xStep, minY, hold);
            return {x: xStep, y: minY, holdIndex: hold};
          }
        }
        // left and right border
        for (let yStep = minY; yStep <= maxY; yStep++) {
          let hold = holds[imageWidth * yStep + minX];
          if (hold !== 0) {
            console.log('Found hold on left edge ', maxX, yStep, hold);
            return {x: minX, y: yStep, holdIndex: hold};
          }
          hold = holds[imageWidth * yStep + maxX];
          if (hold !== 0) {
            console.log('Found hold on right edge ', maxX, yStep, hold);
            return {x: maxX, y: yStep, holdIndex: hold};
          }
        }

      }
      console.log('No closest hold found!')
      return {x: 0, y: 0, holdIndex: 0};
    } else {
      console.log('hold index ', holdIndex);
      return null;
    }
  }

}

interface DetectedHold {
  x: number;
  y: number;
  holdIndex: number;
}
