import {Pipe, PipeTransform} from '@angular/core';
import {Dated} from '../model/dated';

@Pipe({
  name: 'newestFirst',
  pure: false
})
export class SortByDatePipe implements PipeTransform {

  constructor() {
  }

  public static compareDates(a: Date, b: Date) {
    let result = 0;

    if (a < b) {
      result = 1;
    } else if (b < a) {
      result = -1;
    }
    return result;
  }

  transform(dates: Dated[]): any {
    if (!dates || dates.length == 0) {
      return dates;
    }
    dates.sort((a: Dated, b: Dated) => {
      return SortByDatePipe.compareDates(a.date, b.date);
    });
    return dates;
  }
}
