import { createFeature, createReducer, on } from '@ngrx/store';
import { User } from 'src/model/user';
import { addUser } from './users.actions';

export const usersFeatureKey = 'users';

export interface UsersState {
    userByUsername: Map<String, User>;
}

export const initialState: UsersState = {
    userByUsername: new Map<String, User>()
};


export const usersReducer = createReducer(
    initialState,
    on(addUser, (state, { user }) => ({ ...state, userByUsername: state.userByUsername.set(user.username, user)})),
);

export const usersFeature = createFeature({
    name: usersFeatureKey,
    reducer: usersReducer
});
