export class BoulderQuality {
  constructor(
    readonly numThumbsUp: number,
    readonly numThumbsDown: number,
    readonly numNeutral: number
  ) {
  }

  get quality(): number {
    return this.numThumbsUp - this.numThumbsDown;
  }

  get numVotes(): number {
    return this.numThumbsUp + this.numThumbsDown + this.numNeutral;
  }
}
