import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { NewGymDto, NewGymV2ResponseDto } from '../../common/dtos';
import { GymListEntry } from '../../model/gym-list-entry';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { GymService } from '../../services/gym.service';
import { RestService } from '../../services/rest.service';
import { SchemaService } from '../../services/schema.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-create-gym',
  templateUrl: './create-gym.page.html',
  styleUrls: ['./create-gym.page.scss']
})
export class CreateGymPage implements OnInit {
  name: FormControl;
  city: FormControl;
  country: FormControl;
  address: FormControl;
  website: FormControl;
  gymRules: FormControl;
  gymAdmins: string[] = [];

  newGymFormGroup: FormGroup;

  isPrivate: boolean;
  gymToEdit: GymListEntry;

  constructor(
    private activeGymFacade: ActiveGymFacade,
    private modalController: ModalController,
    private schemaService: SchemaService,
    private validationService: ValidationService,
    private alertCtrl: AlertController,
    private errorNotificationService: ErrorNotificationService,
    public authService: AuthService,
    private loadingCtrl: LoadingController,
    private gymService: GymService,
    private restService: RestService,
    private translate: TranslateService) {
  }


  ngOnInit() {
    this.name = new FormControl(this.gymToEdit?.gymName ?? '', this.validatorFnForProperty('gymName'));
    this.city = new FormControl(this.gymToEdit?.city ?? '', this.validatorFnForProperty('city'));
    this.country = new FormControl(this.gymToEdit?.country ?? '', this.validatorFnForProperty('country'));
    this.address = new FormControl(this.gymToEdit?.address ?? '', this.validatorFnForProperty('address'));
    this.website = new FormControl('', this.validatorFnForProperty('website'));
    this.gymRules = new FormControl(this.gymToEdit?.gymRules, this.validatorFnForProperty('gymRules'));
    this.gymAdmins = this.gymToEdit?.gymAdmins ?? [];

    this.isPrivate = this.gymToEdit?.isPrivate ?? false;

    this.newGymFormGroup = new FormGroup({
      name: this.name,
      city: this.city,
      country: this.country,
      address: this.address,
      website: this.website,
      gymRules: this.gymRules
    });
  }

  private validatorFnForProperty(property: string): ValidatorFn[] {
    return this.validationService.getFormValidator(
      this.schemaService.newGymSchema,
      property
    );
  }

  isValid(): boolean {
    return this.newGymFormGroup.valid;
  }

  gymAdminAdded(username: string) {
    this.gymAdmins.push(username);
    this.gymAdmins = this.gymAdmins.map(a => a.toLowerCase());
    this.gymAdmins = this.gymAdmins.filter((item, index) => {
      return this.gymAdmins.indexOf(item) === index;
    });
  }

  removeGymAdmin(username: string) {
    this.gymAdmins = this.gymAdmins.filter(u => u !== username);
  }

  async checkIfThereAreSimilarGyms() {
    const similarGyms = await this.getSimilarGyms();
    if (similarGyms.length > 0) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant('create-gym-page.gym-exists'),
        message: this.translate.instant('create-gym-page.there-are-similar-gyms'),
        buttons: [{
          text: this.translate.instant('create-gym-page.add-anyway'),
          handler: () => {
            this.addNewGym();
          }
        }, {
          text: this.translate.instant('create-gym-page.show-similar-gyms'),
          handler: () => {
            this.showSimilarGyms();
          }
        }]
      });
      await alert.present();
    } else {
      await this.addNewGym();
    }
  }

  async addNewGym() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('create-gym-page.gym-is-being-added'),
      duration: 3000
    });
    loader.present();
    try {
      const newGym = await this.createNewGym();
      this.activeGymFacade.activeGymChanged(newGym);
      await loader.dismiss();
      await this.showSuccessMessage();
      await this.modalController.dismiss(newGym.gymId, 'gym-added');
    } catch (error) {
      await this.errorNotificationService.showBBError(error);
      await loader.dismiss();
      this.dismiss();
    }
  }

  async saveChanges() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('create-gym-page.saving-changes')
    });
    loader.present();
    try {
      const updatedGymInfo = await this.gymService.updateActiveGym(this.name.value, this.city.value, this.country.value, this.isPrivate, this.gymRules.value ?? '', this.gymAdmins);
      await loader.dismiss();
      await this.modalController.dismiss(updatedGymInfo, '', 'edit-gym-modal');
    } catch (error) {
      await this.errorNotificationService.showBBError(error);
      this.dismiss();
    } finally {
      await loader.dismiss();
    }
  }

  private async getSimilarGyms(): Promise<GymListEntry[]> {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('shared.please-wait')
    });
    loader.present();
    try {
      const gyms = await firstValueFrom(this.gymService.getRemoteGymsThatAreNotLocalYet$());
      const nameToken: string[] = this.name.value.toLowerCase().split(' ');
      return gyms.filter(gym => {
        return nameToken.filter(token => gym.gymName.toLowerCase().indexOf(token) > -1).length > 0;
      });
    } finally {
      loader.dismiss();
    }
  }

  private createNewGymObject(): NewGymDto {
    return {
      gymName: this.name.value,
      city: this.city.value,
      country: this.country.value,
      address: this.address.value,
      website: this.website.value,
      isPrivate: this.isPrivate,
      gymRules: this.gymRules.value ?? '',
      gymAdmins: this.gymAdmins ?? []
    };
  }

  dismiss() {
    this.modalController.dismiss(false);
  }

  private async createNewGym(): Promise<GymListEntry> {
    const response: NewGymV2ResponseDto = await this.restService.createNewGym(
      this.createNewGymObject()
    );
    return { ...response, gymId: response._id, isNewGym: true, timeCreated: new Date() };
  }

  private async showSuccessMessage() {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    const message = await this.alertCtrl.create({
      header: this.translate.instant('create-gym-page.successfully-added-gym'),
      message: this.translate.instant('create-gym-page.now-add-walls'),
      cssClass: 'bb-alert',
      buttons: [{
        text: this.translate.instant('create-gym-page.next'),
        id: 'next-button',
        handler: () => resolveFunction(true)
      }],
    });
    message.present();
    return promise;
  }

  filledButInvalid() {
    return this.name.value.length > 2 && this.city.value.length > 2 && this.newGymFormGroup.invalid;
  }


  isPrivateChanged($event: any) {
    this.isPrivate = $event?.detail?.checked;
  }

  private showSimilarGyms() {
    this.modalController.dismiss(this.name.value, 'search-string');
  }
}
