import {Injectable} from '@angular/core';
import {BBStorage} from '../utils/bb-storage';

@Injectable()
export class SchemaService {

  constructor(private bbStorage: BBStorage) {
  }

  get typeDefinitions() {
    return this.bbStorage.typeDefinitions;
  }

  get errorSchema() {
    return this.bbStorage.restSchema['error'];
  }

  get loginCredentialsSchema() {
    return this.bbStorage.restSchema['login_credentials'];
  }

  get boulderCommentSchema() {
    return this.bbStorage.couchSchema['comment'];
  }

  get boulderListNameSchema() {
    return this.bbStorage.couchSchema['boulder_list'];
  }

  get feedbackSchema() {
    return this.bbStorage.restSchema['feedback'];
  }

  get loginResponseSchema() {
    return this.bbStorage.restSchema['login_response'];
  }

  get signupCredentialsSchema() {
    return this.bbStorage.restSchema['new_user'];
  }

  get resetPasswordEmailSchema() {
    return this.bbStorage.restSchema['reset_password_email'];
  }

  get newGymSchema() {
    return this.bbStorage.restSchema['new_gym'];
  }

  get newGymResponseSchema() {
    return this.bbStorage.restSchema['new_gym_response_v2'];
  }

  get shortTechnicalNameSchema() {
    return this.bbStorage.typeDefinitions['short_technical_name'];
  }

  get shortHumanReadableNameSchema() {
    return this.bbStorage.typeDefinitions['short_human_readable_name'];
  }

  docSchema(docType: string) {
    return this.bbStorage.couchSchema[docType];
  }

}
