<div #wallimagecontainer class="container" id="wallimagecontainer">
  <loader *ngIf="!imageInitialized"></loader>
</div>
<ion-fab *ngIf="editMode && activeHold" [hidden]="!imageInitialized" horizontal="end" slot="fixed" vertical="center">
  <ion-fab-button (click)="setActiveHoldType('double-start')"
    [color]="currentHoldType=='double-start'? 'primary':'secondary'" size="small">
    <ion-icon src="assets/icons/start-holds.svg"></ion-icon>
  </ion-fab-button>
  <ion-fab-button (click)="setActiveHoldType('single-start')"
    [color]="currentHoldType=='single-start'? 'primary':'secondary'" size="small">
    <ion-icon src="assets/icons/start-hold.svg"></ion-icon>
  </ion-fab-button>
  <ion-fab-button (click)="setActiveHoldType('hold')" [color]="currentHoldType=='hold'? 'primary':'secondary'"
    size="small">
    <ion-icon src="assets/icons/hold.svg"></ion-icon>
  </ion-fab-button>
  <ion-fab-button (click)="setActiveHoldType('foot')" [color]="currentHoldType=='foot'? 'primary':'secondary'"
    size="small">
    <ion-icon src="assets/icons/foothold.svg"></ion-icon>
  </ion-fab-button>
  <ion-fab-button (click)="setActiveHoldType('top')" [color]="currentHoldType=='top'? 'primary':'secondary'"
    data-cy="top-hold" size="small">
    <ion-icon name="flag"></ion-icon>
  </ion-fab-button>
  <ion-fab-button (click)="toggleEnumeration()" [ngClass]="enumerateHolds ? 'enumeration-button-active':'enumeration-button-inactive'"
    data-cy="hold-enumeration-button" size="small">
    1,2...
  </ion-fab-button>
  <ion-fab-button (click)="removeActiveHold()" [disabled]="!activeHold" color="secondary" size="small"
    style="margin-top:30px">
    <ion-icon name="trash"></ion-icon>
  </ion-fab-button>
</ion-fab>