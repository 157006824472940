<ion-item [class]="isNew?'new ion-text-wrap': (item.highlight ? 'highlight ion-text-wrap' : 'ion-text-wrap')">
  <ion-icon slot="start" class="icon" [name]="icon"></ion-icon>
  <ion-label class="ion-text-wrap" (click)="openDetails()">
    <ion-text class="head">
      <h3>{{title}} {{"feed-item-component.by" | translate}} <b><username [username]="item.author ?? item.doc.author"></username></b><span
        *ngIf="item.type==Type.COMMENT"> {{"feed-item-component.about" | translate}} <b> {{item.boulderName}}</b>
      <ion-chip class="grade-chip">{{item.grade|boulderGrade}}</ion-chip></span><span
        class="date">{{"feed-item-component.on" | translate}} {{item.date|dateFormat}}</span>
      </h3>
    </ion-text>
    <p *ngIf="item.type===Type.NEW_BOULDER || item.type===Type.NEW_BOULDER_WITH_ASCENT" class="boulder">
      <b>{{item.boulderName}}</b>
      <ion-chip class="grade-chip">{{item.grade|boulderGrade}}</ion-chip>
    </p>
    <p *ngIf="item.type===Type.COMMENT">"<b class="comment">{{item.doc.comment}}</b>"
    </p>
    <p *ngIf="(item.type===Type.DOWN_VOTE || item.type===Type.UP_VOTE) && item.grade!=item.doc.difficulty">
      <b>{{item.boulderName}}</b>
      <span *ngIf="item.type===Type.DOWN_VOTE"> {{"feed-item-component.downgraded-from" | translate}}</span>
      <span *ngIf="item.type===Type.UP_VOTE"> {{"feed-item-component.upgraded-from" | translate}}</span>
      <ion-chip class="grade-chip">{{item.grade|boulderGrade}}</ion-chip>
      {{"feed-item-component.up-to" | translate}}
      <ion-chip class="grade-chip">{{item.doc.difficulty|boulderGrade}}</ion-chip>
    </p>
    <p *ngIf="item.type===Type.ASCENT"><b>{{item.boulderName}}</b>
      <ion-chip class="grade-chip">{{item.grade|boulderGrade}}</ion-chip>
    </p>
  </ion-label>
  <ion-button class="open-reaction-modal-button" slot="end" (click)="showEmojiPicker()"
              *ngIf="emojisActivated && emojis.size===0">
    <ion-icon name="happy-outline" slot="icon-only"></ion-icon>
  </ion-button>
  <p slot="end" class="emoji-container" (click)="showEmojiPicker()" *ngIf="emojisActivated && emojis.size>0">
    <span slot="icon-only" *ngFor="let emoji of emojis.keys()" class="mini-chip emoji-chip">
      <span emoji="{{emoji}}">{{emojis.get(emoji)}}</span>
      <span *ngIf="emojis.get(emoji)>1">{{emojis.get(emoji)}}</span>
    </span>
  </p>
</ion-item>
<ion-modal [isOpen]="emojiPickerModalOpen" [showBackdrop]="true" [backdropDismiss]="true">
  <ng-template>
    <ion-header>
      <ion-toolbar class="emoji-modal-toolbar">
        <ion-title>Your reaction</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" (click)="dismissEmojiPicker()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <emoji-mart (emojiSelect)="addEmojiAsReaction($event);"></emoji-mart>
    </ion-content>
  </ng-template>
</ion-modal>
