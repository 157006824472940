import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { WallImage } from 'src/model/wall-image';
import { ImageService } from 'src/services/image.service';

@Directive({
  selector: '[wallImage]'
})
export class WallImageDirective implements OnInit {

  @Input('wallImage') wallImage: WallImage;

  constructor(
    private imageService: ImageService,
    private renderer: Renderer2,
    private el: ElementRef) {
  }

  ngOnInit() {
    this.loadWallImageUrl();
  }

  private async loadWallImageUrl() {
    let src: any = 'assets/imgs/nowallimage.png';
    if (this.wallImage) {
      src = await this.imageService.wallImagePath(this.wallImage);
    }
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);

  }

}
