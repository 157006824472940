import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageService} from '../../services/image.service';
import {Wall} from '../../model/wall';

@Component({
  selector: 'app-wall-image-thumbnail',
  templateUrl: './wall-image-thumbnail.component.html',
  styleUrls: ['./wall-image-thumbnail.component.scss'],
})
export class WallImageThumbnailComponent implements OnInit {

  imageUrl: any;
  wallName: string = 'keine';
  noImage: boolean = false;

  @Input('wall')
  set wall(wall: Wall) {
    this.wallName = wall.wallName;
    const wallImages = wall.wallImages.filter(image => image.currentness === 'current');
    if (wallImages.length > 0) {
      this.imageService.wallImagePath(wall.wallImages[0]).then(url => {
        this.imageUrl = url;
      });
    } else {
      this.noImage = true;
    }
  }

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(private imageService: ImageService) {
  }

  ngOnInit() {
  }

  wallClicked() {
    this.onClick.emit();
  }
}
