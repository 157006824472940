import {Injectable} from '@angular/core';
import {Media, MediaObject} from '@awesome-cordova-plugins/media/ngx';
import {NGXLogger} from 'ngx-logger';
import {Platform} from '@ionic/angular';
import {Howl} from 'howler';
import {Storage} from '@ionic/storage';
import {File} from '@awesome-cordova-plugins/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class Mp3Service {

  // number from 0 to 100
  private _volume: number;

  private mediaObject: MediaObject;
  private audio: Howl;
  private audioPlaying: boolean = false;

  constructor(
    private file: File,
    private media: Media,
    private platform: Platform,
    private storage: Storage,
    private logger: NGXLogger) {
  }

  set volume(value: number) {
    this._volume = value;
  }

  public play(audioFilename: string, onlyIfNoAudioCurrentlyPlaying: boolean = true) {
    if (this.platform.is('capacitor')) {
      const filePrefix = this.platform.is('android') ?
        'file:///android_asset/public/assets/audio/' :
        this.file.applicationDirectory + 'public/assets/audio/';
      const src = (filePrefix + audioFilename).replace(/^file:\/\//, '');
      this.mediaObject = this.media.create(src);
      this.mediaObject.setVolume(this._volume / 100);
      this.mediaObject.onError.subscribe(error => {
        if (error > 0) {
          this.logger.error('Error playing mp3!', error)
        }
      });
      this.mediaObject.play();
    } else {
      if (!onlyIfNoAudioCurrentlyPlaying || !this.audioPlaying) {
        this.audioPlaying = true;
        this.audio = new Howl({
          volume: this._volume / 100,
          html5: true,
          src: ['assets/audio/' + audioFilename],
          onend: () => {
            this.audioPlaying = false;
          }
        });
        this.audio.play();
      }
    }
  }

  public stop() {
    if (this.mediaObject) {
      this.mediaObject.stop();
      this.mediaObject.release();
      this.mediaObject = null;
    }
    if (this.audio) {
      this.audio.stop();
      this.audio = null;
    }
  }

}
