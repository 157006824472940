import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {DifficultyRatingService} from '../../services/difficulty-rating.service';
import {TranslateService} from '@ngx-translate/core';
import {AscentService} from '../../services/ascent.service';
import {AuthService} from '../../services/auth.service';
import {BoulderGradePipe} from '../../pipes/boulder-grade.pipe';
import {AscentListEntry} from '../../model/ascent-list-entry';
import {firstValueFrom} from 'rxjs';
import {GradeService} from 'src/services/grade.service';

@Component({
  selector: 'app-grade-list',
  templateUrl: './grade-list.page.html',
  styleUrls: ['./grade-list.page.scss'],
})
export class GradeListPage implements OnInit {

  private readonly possibleGrades: number[];
  private changed = false;

  // injected by parent view
  boulderId: string;
  ascents: AscentListEntry[];

  showGradeModalOnInit = false;
  ascentOfUser: AscentListEntry;
  averageGrade: number;
  ratingsPerGrade: Map<number, number> = new Map();
  totalNumGradings = 0;
  noAscents = false;

  constructor(
    private gradeService: GradeService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private ascentService: AscentService,
    private authService: AuthService,
    private difficultyRatingService: DifficultyRatingService,
    private modalCtrl: ModalController) {
    this.possibleGrades = this.gradeService.getCurrentGradeSystem().possibleGrades();
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    await this.loadGrades();
    if (this.showGradeModalOnInit) {
      await this.changeGrade();
    }
  }

  async close() {
    await this.modalCtrl.dismiss(this.changed);
  }

  private async loadGrades() {
    this.averageGrade = null;
    if (!this.ascents) {
      this.ascents = await firstValueFrom(this.ascentService.getAscentsForBoulder$(this.boulderId));
    }
    this.noAscents = this.ascents.length === 0;
    this.totalNumGradings = this.ascents.filter(a => a.personalGrade).length;
    this.ascentOfUser = this.ascents.filter(r => r.userName == this.authService.getCurrentUsername())[0];
    if (!this.noAscents) {
      this.averageGrade = DifficultyRatingService.createBoulderDifficulty(this.ascents.filter(a => a.personalGrade).map(a => a.personalGrade)).grade;
      this.ratingsPerGrade = new Map();
      for (const grade of this.ascents.filter(a => a.personalGrade).map(a => a.personalGrade)) {
        if (this.ratingsPerGrade.has(grade)) {
          this.ratingsPerGrade.set(grade, this.ratingsPerGrade.get(grade) + 1);
        } else {
          this.ratingsPerGrade.set(grade, 1);
        }
      }
    } else {
      // this will correspond to the suggestion provided by the creator of the boulder
      this.averageGrade = (await firstValueFrom(this.difficultyRatingService.getAverageGradeForBoulder$(this.boulderId))).grade;
      this.ratingsPerGrade.set(this.averageGrade, 1);
    }
  }

  async changeGrade() {
    const radioInputs = [];
    const gradePipe = new BoulderGradePipe(this.gradeService);
    for (const grade of this.possibleGrades) {
      radioInputs.push({
        name: grade,
        type: 'radio',
        label: gradePipe.transform(grade),
        value: grade
      });
    }
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('grade-list-page.how-hard-is-boulder'),
      inputs: radioInputs,
      buttons: [
        {
          text: this.translateService.instant('grade-list-page.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.instant('grade-list-page.ok'),
          handler: (grade) => {
            if (grade) {
              this.saveGrade(grade);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  private async saveGrade(grade) {
    this.averageGrade = null;
    await this.difficultyRatingService.setPersonalRating(this.boulderId, grade);
    this.changed = true;
    this.ascents = null;
    this.averageGrade = null;
    this.close();
  }
}
