import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { Wall } from 'src/model/wall';
import { WallService } from 'src/services/wall.service';
import { UploadWallImageModalPage } from '../upload-wall-image-modal/upload-wall-image-modal.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wall-reset',
  templateUrl: './wall-reset.page.html',
  styleUrls: ['./wall-reset.page.scss'],
})
export class WallResetPage implements OnInit {

  public step: number = 1;
  public walls: Wall[] = [];
  public onlyNewPhotos = false;
  public completeReset = false;
  public wallsToUpdate: Wall[] = [];

  constructor(
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    private modalController: ModalController,
    private wallService: WallService) {

  }

  ngOnInit() {
    this.loadWalls();
  }


  private async loadWalls() {
    this.walls = await firstValueFrom(this.wallService.loadWalls$());
  }

  wallSelected($event, wall: Wall) {
    const selected = $event.detail.checked;
    if (selected) {
      this.wallsToUpdate.push(wall);
    } else {
      this.wallsToUpdate.splice(this.wallsToUpdate.indexOf(wall), 1);
    }
  }

  public async resetWalls() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('shared.please-wait')
    });
    loader.present();
    try {
      this.wallsToUpdate.forEach(async (wall) => {
        wall.wallImages.forEach(async (wallImage) => {
          await this.wallService.updateCurrentness(wallImage.wallImageId, 'expired');
        });
      });
    } finally {
      loader.dismiss();
    }
    this.step = 5;
  }

  public async close() {
    await this.modalController.dismiss();
  }

  public async uploadPhoto(wall: Wall) {
    const modal = await this.modalController.create({
      component: UploadWallImageModalPage,
      componentProps: { 'wall': wall },
      id: 'UploadWallImageModalPageModal'
    }
    );
    modal.onDidDismiss().then((data) => {
      if (data.data?.wallImageId) {
        this.wallsToUpdate.splice(this.wallsToUpdate.indexOf(wall), 1);
      }
    });
    await modal.present();
  }

}
