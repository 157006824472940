import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import {
  AscentDoc,
  BoulderDoc,
  BoulderListDoc,
  CommentDoc,
  DifficultyRatingDoc,
  GymChatMessageDoc,
  GymDBDoc,
  GymInfoDoc,
  QualityRatingDoc,
  WallDoc,
  WallImageDoc
} from '../common/docs';
import { DocsCreator } from '../common/docs-creator';
import { PouchDBError } from '../errors/bb-error';
import { GymDBDocType } from '../common/doc-types';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ValidationService } from './validation.service';
import { PouchDBService } from './pouch-db.service';
import { Injectable } from '@angular/core';
import { PouchUtils } from '../utils/pouch-utils';
import { DBQueryResult } from '../common/db-query-result';
import { NGXLogger } from 'ngx-logger';
import * as Sentry from '@sentry/angular';
import Database = PouchDB.Database;

/**
 * This service is used to read and write the different documents from/to a gym pouch db and should be all that
 * is needed by the app unless a gym db needs to be created, deleted or synced. This is done in {@link PouchDBService}.
 * In addition to the pouch CRUD operations we apply our custom validation on reads and writes and our custom error
 * handling here.
 * Except a few rare cases this service should only be used by {@link ActiveGymService}, because in almost
 * any case the app only needs to act as if there is only one gym (the 'active' one).
 */
@Injectable()
export class PouchDocService {
  constructor(
    private logger: NGXLogger,
    private validationService: ValidationService,
    private pouchDBService: PouchDBService) {
  }

  public async addDocument(gymId: string, doc: GymDBDoc): Promise<string> {
    this.validationService.validateDoc(doc, doc.type);
    try {
      const pouch = await this.getPouch(gymId);
      if (pouch) {
        return (await pouch.put(doc)).id;
      } else {
        return null;
      }
    } catch (err) {
      throw new PouchDBError(err, 'Error while adding a document: ' + JSON.stringify(err) + ' -' + JSON.stringify(doc));
    }
  }

  public async addDocuments(gymId: string, docs: GymDBDoc[]) {
    docs.forEach(doc => this.validationService.validateDoc(doc, doc.type));
    const pouch = await this.getPouch(gymId);
    try {
      await pouch.bulkDocs(docs);
    } catch (err) {
      throw new PouchDBError(err, 'Error while adding multiple documents');
    }
  }

  public async updateDocument(gymId: string, doc: GymDBDoc) {
    await this.addDocument(gymId, doc);
  }

  public async removeDocument(gymId: string, doc: GymDBDoc) {
    this.validationService.validateDoc(doc, doc.type);
    const pouch = await this.getPouch(gymId);
    try {
      await pouch.remove(doc);
    } catch (err) {
      throw new PouchDBError(err, 'Error while removing a document');
    }
  }

  /**
   * Executes the given query in the specified gym database and expects to receive a single document. If more than one
   * document matches the query there will be an error.
   * @see queryDocsOfType$
   */
  public querySingleDocOfType$<T extends GymDBDoc>(gymId: string, docType: GymDBDocType, query: any): Observable<T> {
    return this.queryDocsOfType$<T>(gymId, docType, query)
      .pipe(
        map(docs => {
          if (docs.length == 0) {
            return undefined;
          } else if (docs.length > 1) {
            throwError(new PouchDBError('Querying for single document yielded ' + docs.length + ' documents'));
            return null;
          } else {
            return docs[0];
          }
        }
        )
      );
  }

  /**
   * Returns all documents from the specified gym database that match the given (mango) query. The docType will
   * automatically be used to filter the gym documents by type (you can specify the type field in the query yourself,
   * but there will be an error if it does not match the docType, so better just do not specify it).
   * @see https://pouchdb.com/guides/mango-queries.html
   */
  public queryDocsOfType$<T extends GymDBDoc>(gymId: string, docType: GymDBDocType, query: any): Observable<T[]> {
    const start = performance.now();
    if (!query.selector || !query.selector.type) {
      query.selector.type = docType;
    } else if (query.selector.type != docType) {
      throw new Error('docType: ' + docType + ' did not match type field in query: ' + query.selector.type);
    }
    // todo: would be nice to use type for query (PouchDB.Database.Find.FindRequest<Content>)
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return from(pouch.find(query))
            .pipe(
              map(o => o.docs.map(d => d as T)),
              tap(docs => {
                const validated = docs.map(d => this.validationService.validateDoc(d, docType));
                this.logger.debug(`Querying ${docs.length} docs with type ${docType} and query ${JSON.stringify(query)} took ${performance.now() - start}`);
                return validated;
              }),
              catchError(error => this.handleError(error, gymId, docType))
            )
        }
        ),
        catchError(error => this.handleError(error, gymId, docType))
      );
  }

  private handleError(error, gymId: string, docType: GymDBDocType = null, query: any = null) {
    this.logger.error('Error while querying docs: ', error);
    const scope = new Sentry.Scope();
    scope.setTag('issue', 'get_docs_of_type_2');
    scope.setTag('docType', docType);
    scope.setTag('gym_id', gymId);
    scope.setTag('query', query);
    Sentry.captureException(error, scope);
    return of([]);
  }

  public queryDocs$<T extends GymDBDoc>(gymId: string, query: any): Observable<T[]> {
    if (!query.selector) {
      throw new Error('You must provide a selector for the query!');
    }
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return from(pouch.find(query))
            .pipe(
              map(o => o.docs.map(d => d as T)),
              tap(docs => docs.map(d => this.validationService.validateDoc(d, d.type))),
              catchError(err => this.handleError(err, gymId, null, query))
            )
        }
        ),
        catchError(err => this.handleError(err, gymId, null, query))
      );
  }

  public getDocsOfType$<T extends GymDBDoc>(gymId: string, docType: GymDBDocType): Observable<T[]> {
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return PouchUtils.getAllDocsWithPrefix<T>(pouch, DocsCreator.getCouchIdPrefix(docType))
            .pipe(
              tap(docs =>
                docs.map(d => this.validationService.validateDoc(d, docType))
              ),
              catchError(err => this.handleError(err, gymId, docType, null))
            )
        }
        ),
        catchError(err => this.handleError(err, gymId, docType, null))
      );
  }

  public getAllGymDbDocs(gymId: string): Observable<GymDBDoc[]> {
    const start = performance.now();

    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return from(pouch.allDocs({
            include_docs: true,
          })).pipe(
            map(o => o.rows.map(r => {
              const doc = (r.doc as any) as GymDBDoc;
              if (doc?.type) {
                this.validationService.validateDoc(doc, doc.type);
              }
              return doc;
            }))
          );
        }
        ),
        catchError(err => this.handleError(err, gymId, null))
      );
  }

  public getAllDocs(gymId: string): Observable<DBQueryResult> {
    const start = performance.now();
    const result: DBQueryResult = {
      ascentDocsByBoulderId: new Map<string, AscentDoc[]>(),
      boulderDocById: new Map<string, BoulderDoc>(),
      commentDocsByBoulderId: new Map<string, CommentDoc[]>(),
      difficultyRatingDocsByBoulderId: new Map<string, DifficultyRatingDoc[]>(),
      qualityRatingDocsByBoulderId: new Map<string, QualityRatingDoc[]>(),
      wallDocs: [],
      wallImageDocs: [],
      boulderListDocs: [],
      chatMessageDocs: []
    };
    return this.getAllGymDbDocs(gymId).pipe(map((gymDbDocs) => {
      this.logger.debug('get ' + gymDbDocs.length + ' gym db docs took ', performance.now() - start);
      gymDbDocs.forEach(doc => {
        switch (doc.type) {
          case GymDBDocType.ASCENT: {
            const ascentDoc = doc as AscentDoc;
            if (!result.ascentDocsByBoulderId.has(ascentDoc.boulderId)) {
              result.ascentDocsByBoulderId.set(ascentDoc.boulderId, []);
            }
            result.ascentDocsByBoulderId.get(ascentDoc.boulderId).push(ascentDoc);
            break;
          }
          case GymDBDocType.BOULDER: {
            result.boulderDocById.set(doc._id, doc as BoulderDoc);
            break;
          }
          case GymDBDocType.COMMENT: {
            const commentDoc = doc as CommentDoc;
            if (!result.commentDocsByBoulderId.has(commentDoc.boulderId)) {
              result.commentDocsByBoulderId.set(commentDoc.boulderId, []);
            }
            result.commentDocsByBoulderId.get(commentDoc.boulderId).push(commentDoc);
            break;
          }
          case GymDBDocType.DIFFICULTY_RATING: {
            const difficultyRatingDoc = doc as DifficultyRatingDoc;
            if (!result.difficultyRatingDocsByBoulderId.has(difficultyRatingDoc.boulderId)) {
              result.difficultyRatingDocsByBoulderId.set(difficultyRatingDoc.boulderId, []);
            }
            result.difficultyRatingDocsByBoulderId.get(difficultyRatingDoc.boulderId).push(difficultyRatingDoc);
            break;
          }
          case GymDBDocType.QUALITY_RATING: {
            const qualityRatingDoc = doc as QualityRatingDoc;
            if (!result.qualityRatingDocsByBoulderId.has(qualityRatingDoc.boulderId)) {
              result.qualityRatingDocsByBoulderId.set(qualityRatingDoc.boulderId, []);
            }
            result.qualityRatingDocsByBoulderId.get(qualityRatingDoc.boulderId).push(qualityRatingDoc);
            break;
          }
          case GymDBDocType.WALL:
            result.wallDocs.push(doc as WallDoc);
            break;
          case GymDBDocType.WALL_IMAGE:
            result.wallImageDocs.push(doc as WallImageDoc);
            break;
          case GymDBDocType.BOULDER_LIST:
            result.boulderListDocs.push(doc as BoulderListDoc);
            break;
          case GymDBDocType.GYM_CHAT_MESSAGE:
            result.chatMessageDocs.push(doc as GymChatMessageDoc);
            break;
          case GymDBDocType.REACTION:
          case GymDBDocType.GYM_INFO:
            break;
          default:
            if (doc.type) {
              this.logger.warn('Document with unknown type: ', doc);
            }
        }
      });
      return result;
    }));
  }

  public getNewOrChangedDocs$(gymId: string, sequence: number): Observable<GymDBDoc[]> {
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return from(pouch.changes({
            include_docs: true,
            since: sequence
          })).pipe(
            map(changes => changes.results
              .filter(result => !result.deleted && (result?.doc as any)?.type)
              .map(result => {
                const doc = result.doc as GymDBDoc;
                this.validationService.validateDoc(doc, doc.type);
                return doc;
              })
            )
          )
        }
        )
      );
  }

  public getDeletedDocIds$(gymId: string, sequence: number): Observable<string[]> {
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of([]);
          }
          return from(pouch.changes({
            since: sequence
          })).pipe(
            map(changes => {
              return changes.results.filter(result => (result?.deleted)).map(result => {
                return result.id;
              })
            }
            )
          )
        })
      );
  }

  public getDocOfTypeWithId$<T extends GymDBDoc>(gymId: string, docType: GymDBDocType, docId: string): Observable<T> {
    return from(this.getPouch(gymId))
      .pipe(
        switchMap(pouch => {
          if (!pouch) {
            return of(null);
          }
          return from(pouch.get(docId)).pipe(
            map(doc => doc as T),
            tap(doc => this.validationService.validateDoc(doc, docType)))
        }
        )
      );
  }

  /** Special method to get the gym info document, because there should be always exactly one */
  public getGymInfoDoc$(gymId: string): Observable<GymInfoDoc> {
    return this.getDocsOfType$<GymInfoDoc>(gymId, GymDBDocType.GYM_INFO)
      .pipe(
        switchMap(gymInfos => {
          if (gymInfos.length === 0) {
            return of(null);
          } else if (gymInfos.length > 1) {
            return throwError(
              new PouchDBError('found multiple gym info documents for gym ' + gymId)
            );
          } else {
            const gymInfoDoc = gymInfos[0];
            return of(gymInfoDoc);
          }
        })
      );
  }

  public getLocalGymInfoDocs$(): Observable<GymInfoDoc[]> {
    return this.getLocalGymIds$().pipe(
      switchMap(gymIds => {
        if (gymIds.length === 0) {
          return of([]);
        }
        // todo: error handling
        return combineLatest(gymIds.map(gymid => this.getGymInfoDoc$(gymid)));
      })
    );
  }

  public getLocalGymIds$(): Observable<string[]> {
    return this.pouchDBService.getLocalGymIds();
  }

  private async getPouch(gymId: string): Promise<Database> {
    if (await this.pouchDBService.gymReferenceExists(gymId)) {
      return await this.pouchDBService.getExistingPouch(gymId);
    }
    return null;
  }

}
