import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {AscentListEntry} from '../model/ascent-list-entry';
import {GymDBDocType} from '../common/doc-types';
import {DocsCreator} from '../common/docs-creator';
import {AscentDoc, AscentType, BoulderDoc, DifficultyRatingDoc, WallImageDoc} from '../common/docs';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MyAscentListEntry} from '../model/my-ascent-list-entry';
import {DifficultyRatingService} from './difficulty-rating.service';
import {getScore} from './ranking.service';
import {GymService} from './gym.service';
import {DocWriteService} from './doc.write.service';
import {DocQueryService} from './doc.query.service';

@Injectable()
export class AscentService {

  constructor(
    private gymService: GymService,
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private difficultyRatingService: DifficultyRatingService,
    private authService: AuthService) {
  }

  async setFlashed(boulderId: string) {
    await this.createOrUpdateAscentType(boulderId, 'flash');
  }

  async setRedpointed(boulderId: string) {
    await this.createOrUpdateAscentType(boulderId, 'redpoint');
  }

  async setNoAscent(boulderId: string) {
    const ascent = await firstValueFrom(this.getAscentDoc$(boulderId));
    if (ascent) {
      await this.docWriteService.removeDocument(ascent);
    }
  }

  public getMyAscentsForUser$(allTime: boolean, username: string): Observable<MyAscentListEntry[]> {
    return combineLatest([
      this.docQueryService.getDocsOfType$<DifficultyRatingDoc>(GymDBDocType.DIFFICULTY_RATING),
      this.docQueryService.getDocsOfType$<BoulderDoc>(GymDBDocType.BOULDER),
      this.docQueryService.getDocsOfType$<WallImageDoc>(GymDBDocType.WALL_IMAGE),
      this.docQueryService.getDocsOfType$<AscentDoc>(GymDBDocType.ASCENT)])
      .pipe(
        map(([difficulties, boulderDocs, wallImages, ascents]) => {
            const ascentEntries = [];
            for (const doc of ascents.filter(a => a.author === username)) {
              const boulder = boulderDocs.filter(b => b._id === doc.boulderId)[0];
              if (boulder) {
                const wallImage = wallImages.filter(w => w._id === boulder.wallImageId)[0];
                if (wallImage?.currentness == 'current' || allTime) {
                  const difficulty = DifficultyRatingService.createFinalBoulderDifficulty(boulder, ascents, difficulties);
                  const isFlash = doc.ascentType === 'flash';
                  ascentEntries.push(
                    new MyAscentListEntry(doc.boulderId, boulder.boulderName, difficulty.grade, new Date(doc.timeCreated), isFlash,
                      getScore(difficulty.grade, isFlash)));
                }
              }
            }
            return ascentEntries;
          }
        ));
  }

  public getAscentsForBoulder$(boulderId: string): Observable<AscentListEntry[]> {
    return combineLatest([
      this.docQueryService.queryDocsOfType$<DifficultyRatingDoc>({
        selector: {
          boulderId: boulderId
        }
      }, GymDBDocType.DIFFICULTY_RATING),
      this.docQueryService.queryDocsOfType$<AscentDoc>({
        selector: {
          boulderId: boulderId
        }
      }, GymDBDocType.ASCENT)]
    ).pipe(
      map(([difficulties, ascents]) => {
          const ascentListEntries = [];
          for (const ascentDoc of ascents) {
            const personalGrade = difficulties.filter(d => d.author === ascentDoc.author)[0]?.difficulty;
            ascentListEntries.push(
              new AscentListEntry(new Date(ascentDoc.timeCreated), ascentDoc.ascentType === 'flash', ascentDoc.author, personalGrade, ascentDoc._id));
          }
          return ascentListEntries;
        }
      ));
  }

  public getNumberOfAscentsOfOtherUsersForBoulder$(boulderId: string): Observable<number> {
    return this.docQueryService.queryDocsOfType$<AscentDoc>({
      selector: {
        boulderId: boulderId,
        author: {$ne: this.authService.getCurrentUsername()}
      }
    }, GymDBDocType.ASCENT).pipe(
      map(docs => {
        return docs.length;
      })
    );
  }

  public async createOrUpdateAscentType(
    boulderId: string,
    ascentType: AscentType,
    username: string = this.authService.getCurrentUsername()): Promise<AscentDoc> {
    let ascentDoc = await firstValueFrom(this.getAscentDoc$(boulderId, username));
    if (!ascentDoc) {
      ascentDoc = DocsCreator.createAscent(
        boulderId, username, ascentType
      );
      await this.docWriteService.addDocument(ascentDoc);
    } else if (ascentDoc.ascentType !== ascentType) {
      (ascentDoc as any).ascentType = ascentType;
      await this.docWriteService.updateDocument(ascentDoc);
    }
    return ascentDoc;
  }

  private getAscentDoc$(
    boulderId: string,
    author: string = this.authService.getCurrentUsername()): Observable<AscentDoc> {
    return this.docQueryService.getDocsOfType$<AscentDoc>(GymDBDocType.ASCENT).pipe(
      map(docs => {
        return docs.filter(d => d.boulderId === boulderId && d.author === author)[0];
      })
    );
  }
}
