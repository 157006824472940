import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[emoji]'
})
export class EmojiDirective implements OnInit {

  @Input('emoji') emoji: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    const utf8 = this.hexToUnicode(this.emoji)
    this.el.nativeElement.textContent += utf8;
  }

  private hexToUnicode(hex: string): string {
    // Remove the "0x" prefix if it exists
    if (hex.startsWith('0x')) {
      hex = hex.slice(2);
    }

    // Convert the hexadecimal string to a decimal number
    const decimal = parseInt(hex, 16);

    // Use String.fromCodePoint to get the Unicode character
    return String.fromCodePoint(decimal);
  }

}
