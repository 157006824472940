import {Injectable, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';

const BB_SHARE_URL = 'https://app.boulder-book.com';
const DELIMITER = '/';

// link to a gym
const GYM_PATH = DELIMITER + 'gym' + DELIMITER;
export const shareGymLink = (gymId) => BB_SHARE_URL + GYM_PATH + gymId;

// link to a boulder
const BOULDER_PATH = DELIMITER + 'boulder' + DELIMITER;
export const shareBoulderLink = (gymId, boulderId) => BB_SHARE_URL + BOULDER_PATH + gymId + DELIMITER + boulderId;

@Injectable()
export class DeepLinksService {

  constructor(
    private router: Router,
    private zone: NgZone,
    private platform: Platform) {
  }

  async initialize() {
    if (this.platform.is('capacitor')) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) =>
        this.zone.run(() => this.handleUrl(event.url)));
    }
  }

  private async handleUrl(url) {
    const path = url.split('.com').pop();
    // path is something like /gym/gym_info+abc or /boulder/gym_info+abc/boulder+abc
    if (path) {
      this.handleDeepLink(path);
    }
  }

  private async handleDeepLink(path) {
    if (path.startsWith(BOULDER_PATH)) {
      const params = path.replace(BOULDER_PATH, '');
      // remaining path should contain one slash dividing gym id and boulder id
      if (params.split(DELIMITER).length === 2) {
        const url = '/tabs/boulder-list/' + params;
        await this.router.navigateByUrl(url);
      }
    } else if (path.startsWith(GYM_PATH)) {
      // remaining path should contain gym id
      const gymId = path.replace(GYM_PATH, '');
      const url = '/gyms/' + gymId;
      await this.router.navigateByUrl(url);
    }
  }
}
