import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {PipesModule} from '../pipes/pipes.module';
import {ComponentsModule} from '../components/components.module';
import {CommonModule} from '@angular/common';
import {CreateGymPageModule} from '../pages/create-gym/create-gym.module';
import {GradeListPageModule} from '../pages/grade-list/grade-list.module';
import {FeedPageModule} from '../pages/feed/feed.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UploadWallImageModalPageModule} from '../pages/upload-wall-image-modal/upload-wall-image-modal.module';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {QRCodeModule} from 'angularx-qrcode';
import {environment} from '../environments/environment';
import {FourByFourPageModule} from '../pages/four-by-four/four-by-four.module';
import {PickerComponent} from '@ctrl/ngx-emoji-mart';
import {DirectivesModule} from '../directives/directives.module';
import {MatomoModule, MatomoRouterModule} from 'ngx-matomo-client';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { activeGymFeatureKey, activeGymReducer } from 'src/store/active-gym/active-gym.reducer';
import { usersFeatureKey, usersReducer } from 'src/store/users/users.reducer';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: [activeGymFeatureKey, usersFeatureKey], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


export const modules = [
  AppRoutingModule,
  BrowserModule,
  HammerModule,
  CommonModule,
  PipesModule,
  DirectivesModule,
  ComponentsModule,
  CreateGymPageModule,
  GradeListPageModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  FeedPageModule,
  QRCodeModule,
  UploadWallImageModalPageModule,
  FourByFourPageModule,
  MatomoModule.forRoot({
    trackerUrl: 'https://www.boulder-book.com/wp-content/plugins/matomo/app/matomo.php',
    siteId: '1',
    scriptUrl: 'https://www.boulder-book.com/wp-content/uploads/matomo/matomo.js'
  }),
  MatomoRouterModule,

  // it seems that adding the IonicStorageModule after the IonicModule leads to a longer startup times
  IonicStorageModule.forRoot(),
  StoreModule.forFeature(activeGymFeatureKey, activeGymReducer),
  StoreModule.forFeature(usersFeatureKey, usersReducer),
  LoggerModule.forRoot({level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG}),
  IonicModule.forRoot({
    swipeBackEnabled: false,
    sanitizerEnabled: false
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient],
    },
    defaultLanguage: 'en'
  }),
  PickerComponent,
  StoreModule.forRoot([], {metaReducers} )
];
