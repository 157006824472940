import { Task } from './Scheduler';
import { Exercise } from './Exercise';
import { Batch } from './Batch';
import { Hang } from './Hang';
import { TickEvent, TaskEvent, TaskType, TaskEventType } from './events';

function createTasksForHang(
  exerciseIndex: number,
  batchIndex: number,
  hangIndex: number,
  hang: Hang,
  onTick: (tickEvent: TickEvent) => void,
  onTask: (taskEvent: TaskEvent) => void
): Task[] {
  const result = [];
  result.push(
    new Task(
      hang.prep,
      () => {
        onTask(new TaskEvent(TaskType.HANG, TaskEventType.START, exerciseIndex, batchIndex, hangIndex));
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.START, exerciseIndex, batchIndex, hangIndex));
      },
      () => {
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.FINISH, exerciseIndex, batchIndex, hangIndex));
      },
      (ms) => onTick(new TickEvent(ms, hang.prep - ms, TaskType.HANG, TaskType.PREP))
    )
  );
  result.push(
    new Task(
      hang.work,
      () => {
        onTask(new TaskEvent(TaskType.WORK, TaskEventType.START, exerciseIndex, batchIndex, hangIndex));
      },
      () => {
        onTask(new TaskEvent(TaskType.WORK, TaskEventType.FINISH, exerciseIndex, batchIndex, hangIndex));
      },
      (ms) => onTick(new TickEvent(ms, hang.work - ms, TaskType.HANG, TaskType.WORK))
    )
  );
  result.push(
    new Task(
      hang.rest,
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.START, exerciseIndex, batchIndex, hangIndex));
      },
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.FINISH, exerciseIndex, batchIndex, hangIndex));
        onTask(new TaskEvent(TaskType.HANG, TaskEventType.FINISH, exerciseIndex, batchIndex, hangIndex));
      },
      (ms) => onTick(new TickEvent(ms, hang.rest - ms, TaskType.HANG, TaskType.REST))
    )
  );
  return result;
}

function createTasksForBatch(
  exerciseIndex: number,
  batchIndex: number,
  batch: Batch,
  onTick: (tickEvent: TickEvent) => void,
  onTask: (taskEvent: TaskEvent) => void
): Task[] {
  const result = [];
  result.push(
    new Task(
      batch.prep,
      () => {
        onTask(new TaskEvent(TaskType.BATCH, TaskEventType.START, exerciseIndex, batchIndex, -1));
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.START, exerciseIndex, batchIndex, -1));
      },
      () => {
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.FINISH, exerciseIndex, batchIndex, -1));
      },
      (ms) => onTick(new TickEvent(ms, batch.prep - ms, TaskType.BATCH, TaskType.PREP))
    )
  );
  batch.hangs.forEach((hang, hangIndex) => {
    createTasksForHang(exerciseIndex, batchIndex, hangIndex, hang, onTick, onTask).forEach((task) => result.push(task));
  });
  result.push(
    new Task(
      batch.rest,
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.START, exerciseIndex, batchIndex, -1));
      },
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.FINISH, exerciseIndex, batchIndex, -1));
        onTask(new TaskEvent(TaskType.BATCH, TaskEventType.FINISH, exerciseIndex, batchIndex, -1));
      },
      (ms) => onTick(new TickEvent(ms, batch.rest - ms, TaskType.BATCH, TaskType.REST))
    )
  );
  return result;
}

function createTasksForExercise(
  exerciseIndex: number,
  exercise: Exercise,
  onTick: (tickEvent: TickEvent) => void,
  onTask: (taskEvent: TaskEvent) => void
) {
  const result: Task[] = [];
  result.push(
    new Task(
      exercise.prep,
      () => {
        onTask(new TaskEvent(TaskType.EXERCISE, TaskEventType.START, exerciseIndex, -1, -1));
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.START, exerciseIndex, -1, -1));
      },
      () => {
        onTask(new TaskEvent(TaskType.PREP, TaskEventType.FINISH, exerciseIndex, -1, -1));
      },
      (ms) => onTick(new TickEvent(ms, exercise.prep - ms, TaskType.EXERCISE, TaskType.PREP))
    )
  );
  exercise.batches.forEach((batch, batchIndex) => {
    createTasksForBatch(exerciseIndex, batchIndex, batch, onTick, onTask).forEach((task) => result.push(task));
  });
  result.push(
    new Task(
      exercise.rest,
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.START, exerciseIndex, -1, -1));
      },
      () => {
        onTask(new TaskEvent(TaskType.REST, TaskEventType.FINISH, exerciseIndex, -1, -1));
        onTask(new TaskEvent(TaskType.EXERCISE, TaskEventType.FINISH, exerciseIndex, -1, -1));
      },
      (ms) => onTick(new TickEvent(ms, exercise.rest - ms, TaskType.EXERCISE, TaskType.REST))
    )
  );
  return result;
}

/**
 * Creates Tasks as they are consumed by Scheduler for the given Exercises. The tasks' events are automaticalyl used to emit TickEvents and TaskEvents.
 *
 * @param exercises - the exercises for which tasks are created
 * @param onTick - callback called on every tick, consumes a TickEvent
 * @param onTask - callback called whenever the current task is updated, consumes a TaskEvent
 */
export function createTasks(
  exercises: Exercise[],
  onTick: (tickEvent: TickEvent) => void,
  onTask: (taskEvent: TaskEvent) => void
): Task[] {
  const result: Task[] = [];
  exercises.forEach((exercise, exerciseIndex) => {
    createTasksForExercise(exerciseIndex, exercise, onTick, onTask).forEach((task) => result.push(task));
  });
  return result;
}

/**
 * Creates an exercise where all batches and all hangs use the same given parameters. All times are given in ms.
 */
export function createExercise(
  exerciseParams: {
    prepare: number;
    numBatches: number;
    rest: number;
  },
  batchParams: {
    prepare: number;
    numHangs: number;
    rest: number;
  },
  hangParams: {
    prepare: number;
    work: number;
    rest: number;
  }
): Exercise {
  return new Exercise(
    exerciseParams.prepare,
    Array(exerciseParams.numBatches).fill(
      new Batch(
        batchParams.prepare,
        Array(batchParams.numHangs).fill(new Hang(hangParams.prepare, hangParams.work, hangParams.rest)),
        batchParams.rest
      )
    ),
    exerciseParams.rest
  );
}
