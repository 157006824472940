import {Injectable} from '@angular/core';
import Konva from 'konva';


@Injectable()
export class WallImageComponentService {

  imageLayer: Konva.Layer = new Konva.Layer();
  holdsLayer: Konva.Layer = new Konva.Layer();
  maskAndHoldsGroup: Konva.Group = new Konva.Group();
  maskRect: Konva.Rect;

  constructor() {
  }

  clear() {
    this.maskRect = new Konva.Rect({
      // width&height will be adjusted as soon as image is loaded
      width: 0,
      height: 0,
      fill: 'black',
      opacity: 0.3,
    });
    this.imageLayer.removeChildren();
    this.holdsLayer.removeChildren();
    this.maskAndHoldsGroup.removeChildren();
    this.maskAndHoldsGroup.add(this.maskRect);
    this.holdsLayer.add(this.maskAndHoldsGroup);
  }
}
