<ion-header>
  <ion-toolbar>
    <ion-title>{{"upload-wall-image-model-page.upload-photo" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(null)" fill="clear">
        <ion-icon name="close" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <app-upload-wall-image (onImageUploaded)="dismiss($event)" [wall]="wall"></app-upload-wall-image>
</ion-content>
