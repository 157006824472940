import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {DocsCreator} from '../common/docs-creator';
import {firstValueFrom, Observable} from 'rxjs';
import {AscentDoc, BoulderDoc, DifficultyRatingDoc} from '../common/docs';
import {GymDBDocType} from '../common/doc-types';
import {map} from 'rxjs/operators';
import {BoulderDifficulty} from '../model/boulder-difficulty';
import {DocWriteService} from './doc.write.service';
import {DocQueryService} from './doc.query.service';
import {GymService} from './gym.service';

@Injectable()
export class DifficultyRatingService {

  constructor(
    private gymService: GymService,
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private authService: AuthService) {
  }

  public static createBoulderDifficulty(grades: number[]): BoulderDifficulty {
    const counts = Array(22).fill(0);
    grades.forEach(r => {
      counts[r]++;
    });
    return new BoulderDifficulty(counts);
  }

  public static createFinalBoulderDifficulty(
    boulderDoc: BoulderDoc,
    allAscentDocs: AscentDoc[],
    allDifficultyRatingDocs: DifficultyRatingDoc[]): BoulderDifficulty {
    const difficultiesForBoulder = allDifficultyRatingDocs.filter(d => d.boulderId === boulderDoc._id);
    const ascentsOfBoulder = allAscentDocs.filter(a => a.boulderId === boulderDoc._id);
    const personalGradesOfAscencionists = difficultiesForBoulder.filter(d => ascentsOfBoulder.filter(a => a.author === d.author).length > 0).map(d => d.difficulty);
    return this.computeGrade(personalGradesOfAscencionists, difficultiesForBoulder.filter(d => d.author === boulderDoc.author)[0]?.difficulty);
  }

  /**
   * Computes grades primarily based on personalGradesOfAscensionists.
   * If however, there are no ascents, we use the suggested grade by the author.
   *
   * @param personalGradesOfAscensionists
   * @param suggestedGradeByAuthor
   */
  public static computeGrade(personalGradesOfAscensionists: number[], suggestedGradeByAuthor: number): BoulderDifficulty {
    if (personalGradesOfAscensionists.length === 0) {
      return this.createBoulderDifficulty(suggestedGradeByAuthor || suggestedGradeByAuthor === 0 ? [suggestedGradeByAuthor] : []);
    } else {
      return this.createBoulderDifficulty(personalGradesOfAscensionists);
    }
  }

  async setPersonalRating(boulderId: string, difficulty: number) {
    await this.setPersonalRatingForUser(boulderId, difficulty, this.authService.getCurrentUsername());
  }

  async setPersonalRatingForUser(boulderId: string, difficulty: number, author: string) {
    const ratings = await firstValueFrom(this.getRatingDocsForUser$(boulderId, author));
    if (ratings.length === 0) {
      await this.docWriteService.addDocument(DocsCreator.createDifficultyRating(
        boulderId, difficulty, author
      ));
    } else if (ratings.length === 1 && ratings[0].difficulty !== difficulty) {
      (ratings[0] as any).difficulty = difficulty;
      (ratings[0] as any).timeCreated = new Date().toISOString();
      await this.docWriteService.updateDocument(ratings[0]);
    } else {
      await this.removeRating(boulderId);
      await this.docWriteService.addDocument(DocsCreator.createDifficultyRating(
        boulderId, difficulty, author
      ));
    }
  }

  getAverageGradeForBoulder$(boulderId: string): Observable<BoulderDifficulty> {
    return this.docQueryService.querySingleDocOfType$<DifficultyRatingDoc>({
      selector: {
        boulderId: boulderId
      }
    }, GymDBDocType.DIFFICULTY_RATING).pipe(
      map(doc => {
        return DifficultyRatingService.createBoulderDifficulty([doc ? doc.difficulty : -1]);
      })
    );
  }

  getPersonalGradeForBoulder$(boulderId: string): Observable<BoulderDifficulty> {
    return this.getRatingDocForCurrentUser$(boulderId).pipe(
      map(doc => {
        return DifficultyRatingService.createBoulderDifficulty([doc ? doc.difficulty : -1]);
      })
    );
  }

  public getRatingDocForCurrentUser$(boulderId: string): Observable<DifficultyRatingDoc> {
    return this.getRatingDocForUser$(boulderId, this.authService.getCurrentUsername());
  }

  public getRatingDocsForCurrentUser$(boulderId: string): Observable<DifficultyRatingDoc[]> {
    return this.getRatingDocsForUser$(boulderId, this.authService.getCurrentUsername());
  }

  public getRatingDocForUser$(boulderId: string, userName: string): Observable<DifficultyRatingDoc> {
    return this.docQueryService.querySingleDocOfType$<DifficultyRatingDoc>({
      selector: {
        boulderId: boulderId,
        author: userName
      }
    }, GymDBDocType.DIFFICULTY_RATING);
  }

  public getRatingDocsForUser$(boulderId: string, userName: string): Observable<DifficultyRatingDoc[]> {
    return this.docQueryService.queryDocsOfType$<DifficultyRatingDoc>({
      selector: {
        boulderId: boulderId,
        author: userName
      }
    }, GymDBDocType.DIFFICULTY_RATING);
  }

  async removeRating(boulderId: string) {
    const docs = await firstValueFrom(this.getRatingDocsForCurrentUser$(boulderId));
    if (docs.length > 0) {
      for (const doc of docs) {
        await this.docWriteService.removeDocument(doc);
      }
    }
    await this.gymService.syncActiveGym();
  }
}
