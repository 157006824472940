import {Component, Input, OnInit} from '@angular/core';
import {Wall} from '../../model/wall';
import {WallService} from '../../services/wall.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-edit-wall-currentness',
  templateUrl: './edit-wall-currentness.component.html',
  styleUrls: ['./edit-wall-currentness.component.scss'],
})
export class EditWallCurrentnessComponent implements OnInit {

  currentStep = 1;
  wallName = 'keine';
  _wall: Wall;

  constructor(
    private modalController: ModalController,
    private wallService: WallService) {
  }

  ngOnInit() {
  }

  @Input('wall')
  set wall(wall: Wall) {
    this._wall = wall;
    this.wallName = wall.wallName;
    if (this._wall.wallImages.length == 0) {
      this.currentStep = 3;
    }
  }

  async wallHasBeenReset() {
    for (const wallImage of this._wall.wallImages) {
      await this.wallService.updateCurrentness(wallImage.wallImageId, 'expired');
    }
    this.currentStep = 3;
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async wallImageUploaded(newWallImage) {
    await this.modalController.dismiss(newWallImage);
  }
}
