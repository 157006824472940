<ion-content>
  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <p *ngIf="currentStep==1">
    {{"edit-wall-currentness-component.was-the-wall" | translate}}
    <ion-chip (click)="currentStep=2">
      <ion-label>{{"edit-wall-currentness-component.completely-reset" | translate}}</ion-label>
    </ion-chip>
    {{"edit-wall-currentness-component.or-do-you-want-to" | translate}}
    <ion-chip (click)="currentStep=3">
      <ion-label>{{"edit-wall-currentness-component.upload-new-photo" | translate}}</ion-label>
    </ion-chip>
    ?
  </p>
  <p class="note" *ngIf="currentStep==1">
    {{"edit-wall-currentness-component.info-new-holds" | translate}}
  </p>
  <p *ngIf="currentStep==2">
    {{"edit-wall-currentness-component.if-you" | translate}}
    <ion-chip (click)="wallHasBeenReset()">
      <ion-label>{{"edit-wall-currentness-component.next" | translate}}</ion-label>
    </ion-chip>
    <span translate>edit-wall-currentness-component.press-info</span>
  </p>
  <p *ngIf="currentStep==3">
    <app-upload-wall-image (onImageUploaded)="wallImageUploaded($event)" [wall]="_wall"></app-upload-wall-image>
  </p>

</ion-content>
