import {Injectable} from '@angular/core';
import {BBStorage} from 'src/utils/bb-storage';
import {FontGradingSystem, GradingSystem, VGradingSystem} from 'src/model/grading-system';

@Injectable()
export class GradeService {

  private vSystem = new VGradingSystem();
  private font = new FontGradingSystem();

  constructor(
    private bbStorage: BBStorage) {
  }

  public getCurrentGradeSystem(): GradingSystem {
    if (this.bbStorage.gradeSystemId === 'v') {
      return this.vSystem;
    } else {
      return this.font;
    }
  }

  public setCurrentGradingSystem(systemId: string) {
    if (systemId === 'v' || systemId === 'font') {
      this.bbStorage.gradeSystemId = systemId;
    } else {
      throw Error('Grade system not supported: ' + systemId);
    }
  }

}
