export abstract class BBError extends Error {
  protected constructor(
    /**
     * defines the type of the error and is used to determine an appropriate action to be taken when this error occurs
     */
    readonly bbErrorType: BBErrorType,
    message: string,
    /**
     * data error-type specific data that might be useful in some cases
     */
    readonly data: any) {
    super(message);
  }
}

/**
 * Used in case we expect that something might go wrong (for example when we talk to a remote
 * service), but we could not find out what went wrong.
 */
export class UnrecognizedError extends BBError {
  constructor(originalError: any) {
    super(BBErrorType.UNRECOGNIZED_ERROR, 'unrecognized error', originalError);
  }
}

export class InternalServerError extends BBError {
  constructor(message: string = 'internal server error: ') {
    super(BBErrorType.INTERNAL_SERVER_ERROR, message, {});
  }
}

export class SchemaUpdateError extends BBError {
  constructor(data) {
    super(BBErrorType.SCHEMA_ERROR, 'could not load schema from server', data);
  }
}

export class NoInternetError extends BBError {
  constructor() {
    super(BBErrorType.NO_INTERNET_CONNECTION, 'no internet connection', {});
  }
}

export class NotAuthorizedError extends BBError {
  constructor() {
    super(BBErrorType.NOT_AUTHORIZED, 'not authorized', {});
  }
}

export class BoulderCreationError extends BBError {
  constructor(readonly data: any) {
    super(BBErrorType.BOULDER_CREATION_FAILED, 'could not create boulder', data);
  }
}

export class GymSyncError extends BBError {
  constructor(readonly data: any, message: string = 'could not sync gym') {
    super(BBErrorType.GYM_SYNC_FAILED, message, data);
  }
}

export class GymSyncDeniedError extends BBError {
  constructor(readonly data: any) {
    super(BBErrorType.GYM_SYNC_DENIED, 'syncing gym was denied', data);
  }
}

export class InvalidDataError extends BBError {
  constructor(readonly data: any) {
    super(BBErrorType.INVALID_DATA, 'invalid data', data);
  }
}

export class UserAlreadyExistsError extends BBError {
  constructor() {
    super(BBErrorType.USER_ALREADY_EXISTS, 'user already exists', {});
  }
}

export class InvalidFileUploadError extends BBError {
  constructor() {
    super(BBErrorType.INVALID_FILE_UPLOAD, 'uploaded file is invalid', {});
  }
}

export class CannotSendFeedbackError extends BBError {
  constructor() {
    super(BBErrorType.INVALID_FILE_UPLOAD, 'uploaded file is invalid', {});
  }
}

export class PouchDBError extends BBError {
  constructor(err: any, message: string = 'pouchdb error') {
    super(BBErrorType.POUCH_DB_ERROR, message, err);
  }
}

export enum BBErrorType {
  NO_INTERNET_CONNECTION = 'NO_INTERNET_CONNECTION',
  INVALID_DATA = 'INVALID_DATA',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  BOULDER_CREATION_FAILED = 'BOULDER_CREATION_FAILED',
  GYM_SYNC_FAILED = 'GYM_SYNC_FAILED',
  GYM_SYNC_DENIED = 'GYM_SYNC_DENIED',
  INTERNAL_SERVER_ERROR = 'SERVER_ERROR',
  SCHEMA_ERROR = 'SCHEMA_ERROR',
  UNRECOGNIZED_ERROR = 'UNRECOGNIZED_ERROR',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  INVALID_FILE_UPLOAD = 'INVALID_FILE_UPLOAD',
  POUCH_DB_ERROR = 'POUCH_DB_ERROR'
}
