import {Component, Input, OnInit} from '@angular/core';
import {WallImage} from '../../model/wall-image';
import {ModalController} from '@ionic/angular';
import {Wall} from '../../model/wall';

@Component({
  selector: 'app-upload-wall-image-modal',
  templateUrl: './upload-wall-image-modal.page.html',
  styleUrls: ['./upload-wall-image-modal.page.scss'],
})
export class UploadWallImageModalPage implements OnInit {

  @Input()
  wall: Wall;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  async dismiss(wallImage: WallImage) {
    await this.modalController.dismiss(wallImage);
  }
}
