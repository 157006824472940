import {Pipe, PipeTransform} from '@angular/core';
import {SortByDatePipe} from './sort-by-date.pipe';
import {FeedItem} from '../model/feed-item';

@Pipe({
  name: 'sortFeed',
  pure: false
})
export class SortFeedPipe implements PipeTransform {

  constructor() {
  }

  transform(feedItems: FeedItem<any>[]): any {
    return SortFeedPipe.sortFeedItems(feedItems, new Date());
  }

  public static sortFeedItems(feedItems: FeedItem<any>[], isNewThreshold: Date): any {
    if (!feedItems || feedItems.length === 0) {
      return feedItems;
    }
    feedItems.sort((a: FeedItem<any>, b: FeedItem<any>) => {
      if (a.date > isNewThreshold && a.highlight) {
        return -1;
      } else if (b.date > isNewThreshold && b.highlight) {
        return 1;
      }
      return SortByDatePipe.compareDates(a.date, b.date);
    });
    return feedItems;
  }
}
