import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, from, Observable } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { GymDBDocType } from '../common/doc-types';
import {
  AscentDoc,
  BoulderDoc,
  BoulderListDoc,
  CommentDoc,
  DifficultyRatingDoc,
  GymDBDoc,
  QualityRatingDoc,
  WallDoc,
  WallImageDoc
} from '../common/docs';
import { DocsCreator } from '../common/docs-creator';
import { Boulder } from '../model/boulder';
import { EditableBoulder } from '../model/editable-boulder';
import { BBStorage } from '../utils/bb-storage';
import CollectionUtils from '../utils/collection-utils';
import { AuthService } from './auth.service';
import { BoulderService } from './boulder.service';
import { DbChangesService } from './db.changes.service';
import { DocQueryService } from './doc.query.service';
import { PouchBoulderService } from './pouch.boulder.service';

@Injectable()
export class CachedBoulderService implements BoulderService {

  constructor(
    private authService: AuthService,
    private logger: NGXLogger,
    private dbChangesService: DbChangesService,
    private pouchBoulderService: PouchBoulderService,
    private docQueryService: DocQueryService,
    private bbStorage: BBStorage,
    private activeGymFacade: ActiveGymFacade
  ) {

    this.activeGymFacade.activeGymId$.subscribe(async gymId => {
      if (gymId !== this.bbStorage.activeCacheGymId) {
        await this.bbStorage.resetActiveGymCache();
        await this.bbStorage.setActiveCacheGymId(gymId);
      }
    });
  }

  public async setBoulderToPublic(boulderId: string): Promise<void> {
    await this.pouchBoulderService.setBoulderToPublic(boulderId);
  }

  addOrUpdateBoulder(boulder: EditableBoulder): Promise<string> {
    return this.pouchBoulderService.addOrUpdateBoulder(boulder);
  }

  deleteBoulder(boulderId: string): Promise<void> {
    return this.pouchBoulderService.deleteBoulder(boulderId);
  }

  getBoulderDetails$(boulderId: string): Observable<Boulder> {
    return from(new Promise<Boulder>(async (resolve, reject) => {
      try {
        await this.updateCacheIfRequired();
        resolve(this.bbStorage.activeGymBouldersById.get(boulderId));
      } catch (e) {
        reject(e);
      }
    }));
  }

  private async cacheIsUpToDate(): Promise<boolean> {
    return this.bbStorage.activeGymBoulderCacheLastSync && this.bbStorage.activeGymBouldersById && !(await this.dbChangesService.pouchHasChangedComparedTo(this.bbStorage.activeGymBoulderCacheLastSync));
  }

  public getBouldersListEntries$(): Observable<Boulder[]> {
    return this.getBoulders$();
  }

  private async updateCacheIfRequired() {
    if (!(await this.cacheIsUpToDate())) {
      if (this.bbStorage.alwaysRebuildEntireBoulderCache || !this.bbStorage.activeGymBoulderCacheLastSync) {
        await this.rebuildEntireCache();
      } else {
        await this.updateCache();
      }
    }
  }

  public getBoulders$(): Observable<Boulder[]> {
    return from(new Promise<Boulder[]>(async (resolve, reject) => {
      try {
        await this.updateCacheIfRequired();
        resolve(Array.from(this.bbStorage.activeGymBouldersById.values()));
      } catch (e) {
        reject(e);
      }
    }));
  }

  public getAllTimeBoulders$(): Observable<Boulder[]> {
    return this.pouchBoulderService.getAllTimeBoulders$();
  }

  private async getNewAndDeletedDocs(): Promise<[GymDBDoc[], string[]]> {
    const start = performance.now();
    const lastCacheSeq = this.bbStorage.activeGymBoulderCacheLastSync ? parseInt(this.bbStorage.activeGymBoulderCacheLastSync) : 0;
    const newDocs = await firstValueFrom(this.docQueryService.getNewOrChangedDocs$(lastCacheSeq));
    const deletedDocIds = await firstValueFrom(this.docQueryService.getDeletedDocIds$(lastCacheSeq));
    this.logger.debug('getNewAndDeletedDocs took ', performance.now() - start);
    return [newDocs, deletedDocIds];
  }

  private getBoulderIdsForDeletedDocs(
    deletedDocIds: string[],
    bouldersByIdInCurrentCache: Map<string, Boulder>): [string[], string[]] {
    const start = performance.now();
    const boulderIdsToDelete: string[] = Array.from(bouldersByIdInCurrentCache.values()).filter(b => deletedDocIds.indexOf(b.boulderId) > -1).map(b => b.boulderId);
    const boulderIdsForDeletedDocs: string[] = Array.from(bouldersByIdInCurrentCache.values())
      .filter(b =>
        b.comments.filter(c => deletedDocIds.indexOf(c.id) > -1).length > 0
        || b.ascents.filter(a => deletedDocIds.indexOf(a.id) > -1).length > 0)
      .map(b => b.boulderId);
    const ids: [string[], string[]] = [boulderIdsToDelete, boulderIdsForDeletedDocs];
    this.logger.debug('getBoulderIdsForDeletedDocs took ', performance.now() - start);
    return ids;
  }

  private getBoulderIdsToReload(newDocs: GymDBDoc[]) {
    return newDocs.filter(d => CachedBoulderService.isBoulderRelatedDoc(d))
      .map(d => (d as any).boulderId)
      .concat(newDocs.filter(d => d.type === GymDBDocType.BOULDER).map(d => d._id));
  }

  private async rebuildEntireCache() {
    this.logger.debug('Rebuild entire boulder cache...');
    const start = performance.now();
    const boulders = await firstValueFrom(this.pouchBoulderService.getBoulders$());
    const bouldersById = new Map<string, Boulder>(boulders.map(b => [b.boulderId, b]));
    try {
      await this.bbStorage.setActiveGymBoulders(bouldersById);
      await this.bbStorage.setActiveGymBoulderCacheLastSync(await this.dbChangesService.getPouchUpdateSeq());
    } catch (e) {
      await this.bbStorage.setActiveGymBoulders(null);
      await this.bbStorage.setActiveGymBoulderCacheLastSync(null);
      throw e;
    }
    this.logger.debug('Update boulder cache took ', performance.now() - start);
  }

  private async updateCache() {
    const start = performance.now();
    try {
      const [newDocs, deletedDocIds] = await this.getNewAndDeletedDocs();
      if (this.cacheNeedsToBeRebuildEntirely(deletedDocIds, newDocs)) {
        await this.rebuildEntireCache();
        return;
      }
      const bouldersByIdInCurrentCache = this.bbStorage.activeGymBouldersById ? this.bbStorage.activeGymBouldersById : new Map<string, Boulder>();
      const [boulderIdsToDelete, boulderIdsForDeletedDocs] = this.getBoulderIdsForDeletedDocs(deletedDocIds, bouldersByIdInCurrentCache);
      const boulderIdsToReload = this.getBoulderIdsToReload(newDocs).concat(boulderIdsForDeletedDocs).filter(CollectionUtils.onlyUnique);
      for (const boulderId of boulderIdsToDelete.filter(b => bouldersByIdInCurrentCache.has(b))) {
        bouldersByIdInCurrentCache.delete(boulderId);
      }
      const boulders = await this.reloadBoulders(boulderIdsToReload);
      for (const boulder of boulders) {
        bouldersByIdInCurrentCache.set(boulder.boulderId, boulder);
      }
      await this.bbStorage.setActiveGymBoulders(bouldersByIdInCurrentCache);
      await this.bbStorage.setActiveGymBoulderCacheLastSync(await this.dbChangesService.getPouchUpdateSeq());
    } catch (e) {
      await this.bbStorage.setActiveGymBoulders(null);
      await this.bbStorage.setActiveGymBoulderCacheLastSync(null);
      throw e;
    }
    this.logger.debug(`update boulder cache took ${performance.now() - start}`);
  }

  private cacheNeedsToBeRebuildEntirely(deletedDocIds, newDocs: GymDBDoc[]) {
    // cache should be rebuild entirely if any wall or wall_image doc has changed
    return deletedDocIds.filter(d => d.startsWith('wall')).length > 0
      || newDocs.filter(newDoc => newDoc._id.startsWith('wall')).length > 0;
  }


  private async reloadBoulders(boulderIds: string[]): Promise<Boulder[]> {
    const start = performance.now();
    const boulders = [];
    try {
      const boulderDocs: BoulderDoc[] = await firstValueFrom(this.docQueryService.queryDocsOfType$<BoulderDoc>({ selector: { _id: { $in: boulderIds } } }, GymDBDocType.BOULDER));
      const wallImageIds = boulderDocs.map(b => b.wallImageId).filter(CollectionUtils.onlyUnique);
      const wallImageDocs: WallImageDoc[] = await firstValueFrom(this.docQueryService.queryDocsOfType$<WallImageDoc>({ selector: { _id: { $in: wallImageIds } } }, GymDBDocType.WALL_IMAGE));
      const wallIds = boulderDocs.map(b => b.wallId).filter(CollectionUtils.onlyUnique);
      const wallDocs = await firstValueFrom(this.docQueryService.queryDocsOfType$<WallDoc>({ selector: { _id: { $in: wallIds } } }, GymDBDocType.WALL));
      const boulderListDocs = await firstValueFrom(this.docQueryService.queryDocsOfType$<BoulderListDoc>({ selector: { author: { $eq: this.authService.getCurrentUsername() } } }, GymDBDocType.BOULDER_LIST));
      const docsWithBoulderIds: GymDBDoc[] = await firstValueFrom(this.docQueryService.queryDocs$({ selector: { boulderId: { $in: boulderIds } } }));
      for (const boulderDoc of boulderDocs) {
        const wallImageDoc = wallImageDocs.filter(w => w._id === boulderDoc.wallImageId)[0];
        if (wallImageDoc && wallImageDoc.currentness === 'current') {
          const wallDoc = wallDocs.filter(w => w._id === boulderDoc.wallId)[0];
          const difficultyRatingDocs = docsWithBoulderIds.filter(d => (d as any).boulderId === boulderDoc._id && d.type === GymDBDocType.DIFFICULTY_RATING).map(d => d as DifficultyRatingDoc);
          const ascentsDocs = docsWithBoulderIds.filter(d => (d as any).boulderId === boulderDoc._id && d.type === GymDBDocType.ASCENT).map(d => d as AscentDoc);
          const qualityRatingDocs = docsWithBoulderIds.filter(d => (d as any).boulderId === boulderDoc._id && d.type === GymDBDocType.QUALITY_RATING).map(d => d as QualityRatingDoc);
          const commentDocs = docsWithBoulderIds.filter(d => (d as any).boulderId === boulderDoc._id && d.type === GymDBDocType.COMMENT).map(d => d as CommentDoc);
          const isProject: boolean = boulderListDocs.filter(b => b.author === this.authService.getCurrentUsername() && DocsCreator.isProjectBoulderListId(b._id, this.authService.getCurrentUsername()))[0]?.boulderIds.indexOf(boulderDoc._id) > -1
          const boulder = PouchBoulderService.createBoulder(
            boulderDoc,
            boulderDoc.author,
            wallDoc?.wallName ?? '',
            wallImageDoc,
            difficultyRatingDocs,
            qualityRatingDocs,
            ascentsDocs,
            commentDocs,
            this.authService.getCurrentUsername(),
            isProject
          );
          boulders.push(boulder);
        }
      }
    } catch (e) {
    }
    this.logger.debug(`Loading ${boulders.length} boulders took ${performance.now() - start}`);
    return boulders;
  }

  private static isBoulderRelatedDoc(doc: GymDBDoc) {
    switch (doc.type) {
      case GymDBDocType.ASCENT:
      case GymDBDocType.DIFFICULTY_RATING:
      case GymDBDocType.COMMENT:
      case GymDBDocType.QUALITY_RATING:
        return true;
    }
    return false;
  }
}
