
export class BoulderListEntry {
  constructor(
    readonly boulderId: string,
    readonly boulderName: string,
    readonly author: string,
    readonly grade: number,
    readonly creationDate: Date,
    readonly rating: number,
    readonly wallName: string,
    readonly ticked: boolean,
    readonly numAscents: number,
    readonly isCampus: boolean = false,
    readonly isPrivate: boolean = false,
    readonly isProject: boolean = false
  ) {
  }
}
