export class User {

    constructor(
        public readonly username: string,
        public readonly supporterType: SupporterType = null,
        public readonly aboutMe: string = null,
        public readonly instagramUsername: string = null) {
    }

}

export type SupporterType = 'basic' | 'medium' | 'high' | 'ultra';
