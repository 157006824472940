import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {GradeListPageRoutingModule} from './grade-list-routing.module';

import {GradeListPage} from './grade-list.page';
import {PipesModule} from '../../pipes/pipes.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        GradeListPageRoutingModule,
        PipesModule,
        TranslateModule
    ],
  declarations: [GradeListPage]
})
export class GradeListPageModule {
}
