class Hang {
  private _prep: number;
  private _work: number;
  private _rest: number;

  constructor(prep: number, work: number, rest: number) {
    this._prep = prep;
    this._work = work;
    this._rest = rest;
  }

  get prep(): number {
    return this._prep;
  }

  get work(): number {
    return this._work;
  }

  get rest(): number {
    return this._rest;
  }
}

export { Hang };
