import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ErrorNotificationService} from '../../services/error-notification.service';
import {LoadingController} from '@ionic/angular';
import {ValidationService} from '../../services/validation.service';
import {SchemaService} from '../../services/schema.service';
import {WallService} from '../../services/wall.service';
import {GymDBDocType} from '../../common/doc-types';
import {Wall} from '../../model/wall';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-wall',
  templateUrl: './create-wall.component.html',
  styleUrls: ['./create-wall.component.scss'],
})
export class CreateWallComponent implements OnInit {

  @Output() onWallAdded: EventEmitter<Wall> = new EventEmitter();
  readonly wallName: FormControl;
  readonly newWallFormGroup: FormGroup;

  constructor(
    private errorNotificationService: ErrorNotificationService,
    private loadingCtrl: LoadingController,
    private validationService: ValidationService,
    private schemaService: SchemaService,
    private wallService: WallService,
    private translate: TranslateService) {
    this.wallName = new FormControl('',
      this.validationService.getFormValidator(this.schemaService.docSchema(GymDBDocType.WALL), 'wallName'));
    this.newWallFormGroup = new FormGroup({
      wallName: this.wallName
    });
  }

  ngOnInit() {
  }

  async createNewWall() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('create-wall-component.wall-is-being-added')
    });
    await loader.present();
    try {
      const wall: Wall = await this.wallService.addNewWall(this.wallName.value);
      this.wallName.setValue('');
      this.onWallAdded.emit(wall);
    } catch (error) {
      await this.errorNotificationService.showBBError(error);
    } finally {
      await loader.dismiss();
    }
  }

  isValid(): boolean {
    return this.newWallFormGroup.valid;
  }

}
