export class MyAscentListEntry {
  constructor(
    readonly boulderId: string,
    readonly boulderName: string,
    readonly grade: number,
    readonly date: Date,
    readonly flash: boolean,
    readonly score: number
  ) {
  }
}
