import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
  selector: 'contact-gym-admin-component',
  template: `
  <ion-header>
        <ion-toolbar>
          <ion-title>{{"contact-gym-admin-component.title" | translate}}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="dismiss()">{{"shared.ok" | translate}}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
    <ion-content class="ion-padding">
        <h2 style="padding: 40px">{{"contact-gym-admin-component.message" | translate}} <b>{{gymAdminString}}</b>.</h2>
    </ion-content>
  `,
  styleUrls: [],
})
export class ContactGymAdminComponent implements OnInit {

  gymAdminString = '';

  constructor(
    private actigymFacade: ActiveGymFacade,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.actigymFacade.activeGym$.subscribe(activeGym => {
      this.gymAdminString = activeGym?.gymAdmin + ((activeGym?.gymAdmins.length > 0) ? ', ' + activeGym?.gymAdmins.join(', ') : '');
    });
  }
  async dismiss() {
    await this.modalController.dismiss();
  }
}
