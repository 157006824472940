import {Injectable} from '@angular/core';
import {AuthService, GUEST_USERNAME} from './auth.service';
import {Router} from '@angular/router';

@Injectable()
export class GuestService {

  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  public async redirectToGuestInfoPage() {
    this.router.navigate(['/guest-info']);
  }

  isGuest() {
    return this.authService.getCurrentUsername() === GUEST_USERNAME;
  }
}
