import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

@Injectable({
  providedIn: 'root'
})
export class NoGymGuard implements CanActivate, CanActivateChild {
  constructor(private activeGymFacade: ActiveGymFacade, private router: Router) {
  }

  async canActivate(): Promise<boolean> {
    if (await firstValueFrom(this.activeGymFacade.activeGymIsDownloaded$)) {
      return true;
    } else {
      this.router.navigate(['/gyms']);
      return false;
    }
  }

  async canActivateChild(): Promise<boolean> {
    return await this.canActivate();
  }
}
