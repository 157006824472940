<div>
  <form [formGroup]="newWallFormGroup">
    <ion-item class="input-field">
      <ion-input [formControl]="wallName" data-cy="wall-name"
                 [label]="'create-wall-component.name-placeholder' | translate" labelPlacement="floating"
                 [clearInput]="true"
                 type="text" aria-label="wall name"></ion-input>
    </ion-item>
    <ion-item>
      <ion-button (click)="createNewWall()" [disabled]="!isValid()" fill="clear" id="addWallButton" size="medium"
                  data-cy="create-wall">
        {{"create-wall-component.next" | translate}}
        <ion-icon slot="end" name="arrow-forward-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </form>
</div>
