import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { WallImage } from '../model/wall-image';
import { DocsCreator } from '../common/docs-creator';
import { Wall } from '../model/wall';
import { WallDoc, WallImageDoc } from '../common/docs';
import { AuthService } from './auth.service';
import { GymDBDocType } from '../common/doc-types';
import { map } from 'rxjs/operators';
import { GymService } from './gym.service';
import { DocWriteService } from './doc.write.service';
import { DocQueryService } from './doc.query.service';
import { ImageService } from './image.service';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

@Injectable()
export class WallService {

  constructor(
    private imageService: ImageService,
    private activeGymFacade: ActiveGymFacade,
    private gymService: GymService,
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private authService: AuthService) {

    this.activeGymFacade.onActiveGymFinishedDownloading$.subscribe(() => {
      this.cacheWallImages();
    });
  }


  private static sortByTimeUploaded(a: WallImage, b: WallImage): number {
    return a.timeUploaded > b.timeUploaded ? -1 : 1;
  }

  public async addNewWall(wallName: string): Promise<Wall> {
    const wallDoc = DocsCreator.createWall(wallName, this.authService.getCurrentUsername());
    await this.docWriteService.addDocument(wallDoc);
    await this.gymService.syncActiveGym();
    return new Wall(wallName, wallDoc._id, wallDoc.author);
  }

  public async addWallImage(wallId: string, imageId: string): Promise<WallImage> {
    const wallImageDoc = DocsCreator.createWallImage(
      wallId, this.authService.getCurrentUsername(), 'current', Date.now().toString(), imageId);
    await this.docWriteService.addDocument(wallImageDoc);
    await this.gymService.syncActiveGym();
    return new WallImage(wallImageDoc._id, wallImageDoc.imageId, wallImageDoc.currentness, wallImageDoc.timeUploaded);
  }

  public loadWalls$(): Observable<Wall[]> {
    return combineLatest([
      this.docQueryService.getDocsOfType$<WallDoc>(GymDBDocType.WALL),
      this.docQueryService.getDocsOfType$<WallImageDoc>(GymDBDocType.WALL_IMAGE)]
    ).pipe(
      map(([wallDocs, wallImageDocs]) =>
        wallDocs.map(wallDoc => {
          const wallId = wallDoc._id;
          const images = wallImageDocs
            .filter(wallImageDoc => wallImageDoc.wallId == wallId)
            .map(wallImageDoc =>
              new WallImage(wallImageDoc._id, wallImageDoc.imageId, wallImageDoc.currentness, wallImageDoc.timeUploaded));
          const wall = new Wall(wallDoc.wallName, wallId, wallDoc.author);
          wall.wallImages = images.sort(WallService.sortByTimeUploaded);
          return wall;
        }
        )));
  }

  public async updateCurrentness(wallImageId: string, currentness: string) {
    const doc = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$(GymDBDocType.WALL_IMAGE, wallImageId));
    if (doc) {
      const updatedDoc = { ...doc, currentness: currentness };
      await this.docWriteService.updateDocument(updatedDoc);
    }
  }

  public async updateWallName(wallId: string, newWallName: string) {
    this.docQueryService.getDocOfTypeWithId$(GymDBDocType.WALL, wallId).subscribe(async doc => {
      const updatedDoc = { ...doc, wallName: newWallName };
      await this.docWriteService.updateDocument(updatedDoc);
    });
  }

  public async deleteWall(wallId: string) {
    const wallImages = await firstValueFrom(this.docQueryService.queryDocsOfType$<WallImageDoc>({
      selector: {
        wallId: wallId,
        currentness: 'current'
      }
    }, GymDBDocType.WALL_IMAGE));
    if (wallImages.length > 0) {
      for (const wallImageDoc of wallImages) {
        const updatedDoc = { ...wallImageDoc, currentness: 'expired' };
        await this.docWriteService.updateDocument(updatedDoc);
      }
    }
    await this.docWriteService.removeDocOfTypeWithId(GymDBDocType.WALL, wallId);
  }

  public async cacheWallImages() {
    const wallImages = await firstValueFrom(this.docQueryService.getDocsOfType$<WallImageDoc>(GymDBDocType.WALL_IMAGE));
    wallImages.filter(w => w.currentness === 'current').forEach(wallImage => { this.imageService.cacheImage(wallImage.imageId); });
  }

}
