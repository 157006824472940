import {Injectable} from '@angular/core';
import {BBError, BBErrorType} from '../errors/bb-error';
import {AlertController} from '@ionic/angular';
import {AuthService} from './auth.service';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {BBErrorHandler} from 'src/errors/bb-error-handler';

@Injectable()
export class ErrorNotificationService {
  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private alert: AlertController,
    private authService: AuthService) {
  }

  private static extractErrorDetails(error: BBError) {
    const details = BBErrorHandler.errorToString(error);
    if (details?.length === 0 || details === '{}') {
      return null;
    }
    return details;
  }

  private getErrorMessageForBBError(err: BBError): string {
    if (err.bbErrorType === BBErrorType.NO_INTERNET_CONNECTION) {
      return this.translateKey('no-internet-connection');
    } else if (err.bbErrorType == BBErrorType.NOT_AUTHORIZED) {
      return this.translateKey('not-authorized');
    } else if (err.bbErrorType === BBErrorType.INVALID_DATA) {
      // todo: use the data object to create a nice error message ? but lets see how we will errors handle in the future
      return err.data;
    } else if (err.bbErrorType === BBErrorType.INTERNAL_SERVER_ERROR) {
      if (err.message === 'File too large') {
        return this.translateKey('file-too-large');
      } else {
        return this.translateKey('error-occurred') + err.message;
      }
    } else if (err.bbErrorType === BBErrorType.UNRECOGNIZED_ERROR) {
      return this.translateKey('unrecognized-error');
    } else if (err.bbErrorType === BBErrorType.POUCH_DB_ERROR) {
      return this.translateKey('database-error');
    } else if (err.bbErrorType === BBErrorType.INVALID_FILE_UPLOAD) {
      return this.translateKey('invalid-file');
    } else if (err.bbErrorType === BBErrorType.USER_ALREADY_EXISTS) {
      return this.translateKey('username-already-exists');
    } else if (err.bbErrorType === BBErrorType.BOULDER_CREATION_FAILED) {
      return this.translateKey('boulder-creation-failed');
    } else if (err.bbErrorType === BBErrorType.GYM_SYNC_FAILED || err.bbErrorType === BBErrorType.GYM_SYNC_DENIED) {
      return this.translateKey('gym-sync-failed');
    } else if (err.bbErrorType === BBErrorType.SCHEMA_ERROR) {
      return this.translateKey('schema-error');
    } else if (err?.message?.length > 0) {
      return err.message;
    } else {
      return this.translateKey('unrecognized-error');
    }
  }

  public async showDeprecatedAppVersion() {
    const alert = await this.alert.create({
      header: this.translateKey('oops'),
      message: this.translateKey('version-expired'),
      cssClass: 'bb-alert',
      buttons: [
        {
          text: this.translateKey('ok'),
          handler: () => {
            this.authService.logout();
          }
        }
      ]
    });
    return await alert.present();
  }

  public async handleUnauthorized(error) {
    if (error?.status === 401) {
      const alert = await this.alert.create({
        header: this.translateKey('oops'),
        message: this.translateKey('unauthorized-error'),
        cssClass: 'bb-alert',
        buttons: [
          {
            text: this.translateKey('ok'),
            handler: () => {
              this.authService.logout();
            }
          }
        ]
      });
      return await alert.present();
    }
  }

  public async showBBError(err: BBError) {
    const message = this.getErrorMessageForBBError(err);
    const errorDetails = ErrorNotificationService.extractErrorDetails(err);
    await this.showErrorMessage(message, errorDetails);
  }

  private async showErrorMessage(errorMessage: string, errorDetails: string) {
    this.logger.error('Show error message: ' + errorMessage, errorDetails);
    const inputs = [];
    const buttons = [];
    buttons.push({
      text: this.translateKey('ok'), role: 'ok'
    });
    const alert = await this.alert.create({
      header: this.translateKey('oops'),
      message: errorMessage,
      inputs: inputs,
      cssClass: 'bb-alert',
      buttons: buttons
    });
    return await alert.present();
  }

  private translateKey(key: string) {
    return this.translate.instant('error-notification-service.' + key);
  }
}
