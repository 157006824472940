export class BoulderDifficulty {

  private readonly _grade;

  constructor(
    readonly ratingsByGrade: number[]
  ) {
    let count = 0;
    let sum = 0;
    this.ratingsByGrade.forEach((val, idx) => {
      count += val;
      sum += idx * val;
    });
    if (count == 0) {
      this._grade = -1;
    } else {
      this._grade = Math.round(sum / count);
    }
  }

  get grade() {
    return this._grade;
  }
}
