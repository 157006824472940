import {Component, Input} from '@angular/core';
import ColorUtils from '../../utils/color-utils';
import {FootholdConfig, Hold} from '../../common/docs';
import {Color} from '../../model/color';
import {Boulder} from '../../model/boulder';

@Component({
  selector: 'foothold-details',
  styleUrls: ['foothold-details.scss'],
  templateUrl: 'foothold-details.component.html'
})
export class FootholdDetailsComponent {

  public allowedColors: Color[];
  public isCampus = false;
  public hasMarkedFootholds = false;

  constructor() {
  }

  _footholdConfig: FootholdConfig;

  @Input('boulder')
  set footholdConfig(boulder: Boulder) {
    this._footholdConfig = boulder.footholdConfig;
    const supportedFootholdColors: Color[] = ColorUtils.getSupportedFootholdColors();
    this.allowedColors = [];
    for (const colorId of this._footholdConfig.allowedColors) {
      this.allowedColors.push(supportedFootholdColors.filter(c => c.id == colorId)[0]);
    }
    this.isCampus = FootholdDetailsComponent.isCampusBoulder(this._footholdConfig, boulder.holds);
    this.hasMarkedFootholds = boulder.holds.filter(h => h.holdType === 'foot').length > 0
  }

  public static isCampusBoulder(footholdConfig: FootholdConfig, holds: Hold[]) {
    return !footholdConfig.withHandholds &&
      !footholdConfig.allHoldsAllowed &&
      !footholdConfig.withSpax &&
      !footholdConfig.withSmallFootholds &&
      footholdConfig.allowedColors.length === 0 &&
      holds.filter(h => h.holdType === 'foot').length === 0;
  }
}
