import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { DBQueryResult } from '../common/db-query-result';
import { GymDBDocType } from '../common/doc-types';
import { GymDBDoc } from '../common/docs';
import { DocQueryService } from './doc.query.service';
import { DocWriteService } from './doc.write.service';
import { PouchDocService } from './pouch-doc.service';

/**
 * Simple wrapper service for {@link PouchDocService} that always connects with the
 * currently active gym. For documentation of the single methods see {@link PouchDocService}
 */
@Injectable()
export class ActiveGymService implements DocWriteService, DocQueryService {

  constructor(
    private pouchDocService: PouchDocService,
    private activeGymFacade: ActiveGymFacade) {
  }

  public async addDocument(doc: GymDBDoc) {
    await this.checkActiveGymIdExists();
    await this.pouchDocService.addDocument(await this.activeGymId(), doc);
  }

  public async updateDocument(doc: GymDBDoc) {
    await this.checkActiveGymIdExists();
    await this.pouchDocService.updateDocument(await this.activeGymId(), doc);
  }

  public async removeDocument(doc: GymDBDoc) {
    await this.checkActiveGymIdExists();
    await this.pouchDocService.removeDocument(await this.activeGymId(), doc);
  }

  public getNewOrChangedDocs$(sequence: number): Observable<GymDBDoc[]> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.getNewOrChangedDocs$(gymId, sequence)));
  }

  public getDeletedDocIds$(sequence: number): Observable<string[]> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.getDeletedDocIds$(gymId, sequence)));
  }

  public queryDocs$<T extends GymDBDoc>(query: any): Observable<T[]> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.queryDocs$<T>(gymId, query)));
  }

  public querySingleDocOfType$<T extends GymDBDoc>(query: any, docType: GymDBDocType): Observable<T> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.querySingleDocOfType$<T>(gymId, docType, query)));
  }

  public queryDocsOfType$<T extends GymDBDoc>(query: any, docType: GymDBDocType): Observable<T[]> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.queryDocsOfType$<T>(gymId, docType, query)));
  }

  public getDocsOfType$<T extends GymDBDoc>(docType: GymDBDocType): Observable<T[]> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.getDocsOfType$<T>(gymId, docType)));
  }

  public getAllDocs(): Observable<DBQueryResult> {
    return  this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.getAllDocs(gymId)));
  }

  public getDocOfTypeWithId$<T extends GymDBDoc>(docType: GymDBDocType, docId: string): Observable<T> {
    return this.activeGymId$().pipe(switchMap(gymId => this.pouchDocService.getDocOfTypeWithId$<T>(gymId, docType, docId)));
  }

  public async removeDocOfTypeWithId(docType: GymDBDocType, docId: string) {
    await this.checkActiveGymIdExists();
    const doc = await firstValueFrom(this.getDocOfTypeWithId$(docType, docId));
    return this.removeDocument(doc);
  }

  public async removeDocsOfTypeWithQuery<T extends GymDBDoc>(docType: GymDBDocType, query) {
    await this.checkActiveGymIdExists();
    const docs = await firstValueFrom(this.queryDocsOfType$<T>(query, docType));
    for (let i = 0; i < docs.length; i++) {
      await this.removeDocument(docs[i]);
    }
  }

  /**
   * Active gym not needed here, but added for convenience
   */
  public getLocalGymIds$(): Observable<string[]> {
    return this.pouchDocService.getLocalGymIds$();
  }

  private async checkActiveGymIdExists() {
    const activeGymId = await this.activeGymId();
    if (activeGymId == null) {
      throw new Error('No gym is set active at the moment');
    }
  }

  private async activeGymId() {
    return await firstValueFrom(this.activeGymId$());
  }

  private activeGymId$() {
    return this.activeGymFacade.activeGymId$;
  }
}

