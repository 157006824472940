export enum CreateBoulderStep {
  CHOOSE_WALL = 0, DEFINE_HOLDS = 1, DEFINE_FOOTHOLDS = 2, GRADE = 3, NAME = 4
}

export namespace CreateBoulderStep {
  export function hasNext(step: CreateBoulderStep): boolean {
    return step < CreateBoulderStep.NAME;
  }

  export function next(step: CreateBoulderStep): CreateBoulderStep {
    if (!hasNext(step)) {
      throw new Error('no next step for ' + step);
    }
    // workaround for weird compiler bug. without .toString() it will be concatenated as string instead of integer addition
    return step + 1;
  }
}

