import { NgModule } from '@angular/core';
import { EmojiDirective } from './emoji-directive';
import { WallImageDirective } from './wall-image.directive';
import { WallDirective } from './wall.directive';

@NgModule({
  declarations: [
    EmojiDirective,
    WallImageDirective,
    WallDirective
  ],
  imports: [],
  exports: [
    EmojiDirective,
    WallImageDirective,
    WallDirective
  ]
})
export class DirectivesModule {
}
