<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="currentStep==Step.CHOOSE_WALL">{{"create-boulder-page.choose-wall" | translate}}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="goToPreviousSlide()" *ngIf="showBackButton()" fill="clear">
        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
      </ion-button>
      <ion-button (click)="close()" *ngIf="!boulderToEdit && currentStep==Step.CHOOSE_WALL" fill="clear">
        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
    <p *ngIf="currentStep==Step.DEFINE_HOLDS && startHoldsMissing()" slot="end">
      {{"create-boulder-page.please-starting-hold" | translate}}
      <ion-icon src="assets/icons/start-holds.svg"></ion-icon>
      {{"create-boulder-page.choose" | translate}}
    </p>
    <p *ngIf="currentStep==Step.DEFINE_HOLDS && (!startHoldsMissing() &&topHoldMissing())" slot="end">
      {{"create-boulder-page.please-holds" | translate}}
      <ion-icon src="assets/icons/hold.svg"></ion-icon>
      {{"create-boulder-page.and-top" | translate}}
      <ion-icon name="flag"></ion-icon>
      {{"create-boulder-page.choose" | translate}}
    </p>
    <ion-buttons *ngIf="currentStep!=Step.DEFINE_HOLDS||showNextButton()" slot="end">
      <ion-button (click)="goToNextSlide()" *ngIf="currentStep!=Step.CHOOSE_WALL && currentStep!=Step.NAME"
        [disabled]="!showNextButton()" fill="clear" data-cy="next-button">
        <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
        {{"create-boulder-page.next" | translate}}
      </ion-button>
      <ion-button (click)="dismiss()" *ngIf="boulderToEdit && (currentStep==Step.NAME||currentStep==Step.CHOOSE_WALL)"
        slot="end" fill="clear">
        <ion-icon name="close" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="currentStep==Step.CHOOSE_WALL" class="wizardStep ion-padding">
    <ion-list>
      <p *ngIf="walls?.length==0">
        <span>{{"create-boulder-page.no-photo-uploaded" | translate}}</span>
        <ion-button (click)="manageWalls()" data-cy="manage-walls-button" size="medium" slot="end">
          <ion-icon name="camera-outline" slot="start"></ion-icon>
          {{"create-boulder-page.upload-photo" | translate}}
        </ion-button>
      </p>
      <div *ngFor="let wall of walls">
        <app-wall-image-thumbnail [wall]="wall" (onClick)="wallClicked(wall)"></app-wall-image-thumbnail>
      </div>
      <ion-item *ngIf="walls?.length>0">
        <ion-button (click)="reset()" fill="clear" size="medium" slot="end">
          {{"create-boulder-page.reset" | translate}}
        </ion-button>
      </ion-item>
      <ion-item *ngIf="walls?.length>0">
        <ion-button (click)="manageWalls()" fill="clear" size="medium" slot="end">
          <ion-icon name="camera-outline" slot="start"></ion-icon>
          {{"create-boulder-page.manage-walls" | translate}}
        </ion-button>
      </ion-item>
    </ion-list>
  </div>
  <div *ngIf="currentStep==Step.DEFINE_HOLDS" class="wizardStep">
    <ion-fab horizontal="end" slot="fixed" vertical="top">
      <ion-fab-button (click)="showHelp()" color="primary" size="small">
        <ion-icon name="help"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <app-wall-image class="wallImageWrapper" (onHoldsChanged)="onHoldsChanged($event)" [holds]="newBoulder.holds"
      [enumerateHolds]="newBoulder.enumerateHolds" [wallImage]="newBoulder.wallImage" (onEnumerateHoldsChanged)="onEnumerateHoldsChanged($event)"
      [editMode]="true"></app-wall-image>
  </div>

  <div *ngIf="currentStep==Step.DEFINE_FOOTHOLDS" class="wizardStep ion-padding">
    <ion-item>
      <ion-label><strong>{{"create-boulder-page.allowed-footholds" | translate}}</strong></ion-label>
    </ion-item>
    <ion-item class="campus-toggle">
      <ion-toggle label-placement="end" slot="start" [(ngModel)]="isCampusBoulder" data-cy="campus-toggle"
        (ionChange)="onCampusToggleChanged()">{{"foothold-details-component.campus" | translate}}</ion-toggle>
    </ion-item>
    <div *ngIf="!isCampusBoulder">
      <ion-item>
        <ion-toggle label-placement="end" slot="start" (ionChange)="updateCampusBoulder()"
          [(ngModel)]="newBoulder.footholdConfig.allHoldsAllowed">{{"create-boulder-page.all-footholds" |
          translate}}</ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle label-placement="end" slot="start" (ionChange)="updateCampusBoulder()"
          [(ngModel)]="newBoulder.footholdConfig.withSpax">{{"create-boulder-page.spax" | translate}}</ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle label-placement="end" slot="start" (ionChange)="updateCampusBoulder()"
          [(ngModel)]="newBoulder.footholdConfig.withSmallFootholds">{{"create-boulder-page.small-footholds" |
          translate}}</ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle label-placement="end" slot="start" (ionChange)="updateCampusBoulder()"
          [(ngModel)]="newBoulder.footholdConfig.withHandholds">{{"create-boulder-page.with-holds" |
          translate}}</ion-toggle>
      </ion-item>
      <br><br>
      <ion-item>
        <ion-label><strong>{{"create-boulder-page.footholds-with-colors" | translate}}</strong></ion-label>
      </ion-item>
      <ion-item *ngFor="let color of supportedFootholdColors">
        <ion-checkbox (ionChange)="footholdColorChanged(color)" slot="start"
          [style.--checkbox-background-checked]=color.hex [style.--border-color-checked]=color.hex
          [style.--border-color]=color.hex label-placement="end" aria-label="color"
          checked="{{newBoulder.footholdConfig.allowedColors.indexOf(color.id)>-1}}">{{"colors-singular." + color.id |
          translate}}</ion-checkbox>
      </ion-item>
    </div>
  </div>

  <div *ngIf="currentStep==Step.GRADE" class="wizardStep ion-padding">
    <h1>{{"create-boulder-page.how-hard-is-the-boulder" | translate}}</h1>
    <ion-list>
      <ion-item (click)="gradeSelected(grade)" *ngFor="let grade of possibleGrades">
        <ion-icon *ngIf="newBoulder.proposedGrade==grade" slot="start" name="checkbox-outline"
          color="primary"></ion-icon>
        <ion-label [color]="newBoulder.proposedGrade==grade?'primary':'secondary'">{{grade | boulderGrade }}</ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div *ngIf="currentStep==Step.NAME" class="wizardStep ion-padding">
    <form [formGroup]="boulderNameFormGroup">
      <ion-item>
        <p>{{"create-boulder-page.choose-a-name" | translate}} <i>({{"create-boulder-page.name-info" | translate}})</i>
        </p>
      </ion-item>
      <ion-item class="boulder-name input-field">
        <ion-input [formControl]="boulderName" placeholder='{{"create-boulder-page.name-placeholder" | translate}}'
          data-cy="boulder-name" aria-label="boulder name" type="text"></ion-input>
      </ion-item>
    </form>
    <ion-item class="private-toggle">
      <ion-toggle label-placement="end" slot="start" [(ngModel)]="isPrivateBoulder"
        (ionChange)="onPrivateToggleChanged()">{{"create-boulder-page.private-boulder" | translate}}</ion-toggle>
    </ion-item>
    <ion-item>
      <ion-button slot="end" [disabled]="!isValid()" (click)="persistBoulder()" size="medium" data-cy="save-button">
        <ion-icon name="cloud-upload" slot="start"></ion-icon>
        {{"create-boulder-page.save" | translate}}
      </ion-button>
    </ion-item>
  </div>
</ion-content>
<ion-footer *ngIf="boulderToEdit">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button slot="start" [disabled]="!isValid()  && currentStep !== Step.NAME" (click)="persistBoulder()"
        color="primary" size="small" data-cy="save-button">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        {{"create-boulder-page.save" | translate}}
      </ion-button>
      <ion-button color="danger" slot="start" fill="clear" (click)="deleteBoulderButtonClicked()" size="small">
        <ion-icon name="trash" slot="start"></ion-icon>
        {{"create-boulder-page.delete" | translate}}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" slot="end" fill="clear">
        <ion-icon name="close" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>