import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { FootholdDetailsComponent } from './foot-hold-details/foothold-details.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WallCardComponent } from './wall-card/wall-card.component';
import { WallImageComponent } from './wall-image/wall-image.component';
import { UploadWallImageComponent } from './upload-wall-image/upload-wall-image.component';
import { WallImageCurrentnessComponent } from './wall-image-currentness/wall-image-currentness.component';
import { CreateWallComponent } from './create-wall/create-wall.component';
import { LogoComponent } from './logo/logo.component';
import { MedalComponent } from './medal/medal.component';
import { WallImageThumbnailComponent } from './wall-image-thumbnail/wall-image-thumbnail.component';
import { EditWallCardComponent } from './edit-wall-card/edit-wall-card.component';
import { EditWallCurrentnessComponent } from './edit-wall-currentness/edit-wall-currentness.component';
import { BoulderFilterComponent } from './boulder-filter/boulder-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { GuestInfoComponent } from './guest-info/guest-info.component';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { DirectivesModule } from '../directives/directives.module';
import { WallImagesComponent } from './wall-images/wall-images.component';

@NgModule({
  declarations: [
    WallImageComponent,
    WallCardComponent,
    LoaderComponent,
    FootholdDetailsComponent,
    UploadWallImageComponent,
    WallImageCurrentnessComponent,
    CreateWallComponent,
    WallImageThumbnailComponent,
    LogoComponent,
    MedalComponent,
    EditWallCardComponent,
    EditWallCurrentnessComponent,
    BoulderFilterComponent,
    LanguageSwitcherComponent,
    FeedbackComponent,
    GuestInfoComponent,
    WallImagesComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    QRCodeModule,
    PipesModule,
    PickerComponent,
    DirectivesModule
  ],
  exports: [
    WallCardComponent,
    WallImageComponent,
    LoaderComponent,
    FootholdDetailsComponent,
    UploadWallImageComponent,
    WallImageThumbnailComponent,
    CreateWallComponent,
    WallImageCurrentnessComponent,
    LogoComponent,
    MedalComponent,
    EditWallCardComponent,
    EditWallCurrentnessComponent,
    LanguageSwitcherComponent,
    FeedbackComponent,
    GuestInfoComponent,
    WallImagesComponent
  ]
})
export class ComponentsModule {
}
