import {Component, OnDestroy, OnInit} from '@angular/core';
import {Motivator, TimerConfig, TimerService, Workout} from '../../services/timer.service';
import {Mp3Service} from '../../services/mp3.service';
import {firstValueFrom} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BoulderService} from '../../services/boulder.service';
import {BoulderListService} from '../../services/boulder-list.service';
import {Boulder} from '../../model/boulder';
import {BoulderList} from '../../model/boulder-list';
import {Location} from '@angular/common';
import {MatomoTracker} from 'ngx-matomo-client';
import {BBStorage} from '../../utils/bb-storage';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { KeepAwake } from '@capacitor-community/keep-awake';

const keepAwake = async () => {
  await KeepAwake.keepAwake();
};

const allowSleep = async () => {
  await KeepAwake.allowSleep();
};

@Component({
  selector: 'four-by-four-page',
  templateUrl: './four-by-four.page.html',
  styleUrls: ['./four-by-four.page.scss']
})
export class FourByFourPage implements OnInit, OnDestroy {

  boulders : Boulder[];
  boulderList: BoulderList;
  audioOn: boolean;
  workout: Workout = new Workout(
    new Motivator('1', 'Daniel', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsA1-Vbf58p7AhVzres2ON83PnplGgNYF1_A&usqp=CAU'),
    '4x4', '4x4 workout', new TimerConfig(10, 60, 0, 240, 4, 4));

  constructor(
    private insomnia: Insomnia,
    private matomoTracker: MatomoTracker,
    private location: Location,
    public mp3Service: Mp3Service,
    private bbStorage:BBStorage,
    public timerService: TimerService,
    private boulderService: BoulderService,
    private boulderListService: BoulderListService,
    private activatedRoute: ActivatedRoute
  ) {
    this.audioOn = this.bbStorage.audioOn;
    this.activatedRoute.params.subscribe(async params => {
      if (params['listId']) {
        this.boulderList = await firstValueFrom(this.boulderListService.getBoulderList$(params['listId']));
        this.boulders = (await firstValueFrom(this.boulderService.getBoulders$())).filter(
          b => this.boulderList.boulderIds.indexOf(b.boulderId) > -1);
        this.boulders.sort((b1, b2) =>
          this.boulderList.boulderIds.indexOf(b1.boulderId) - this.boulderList.boulderIds.indexOf(b2.boulderId));
        this.matomoTracker.trackEvent('four-by-four', 'start', this.boulderList.name);
      }
    });
  }

  ngOnDestroy(): void {
    this.insomnia.allowSleepAgain();
  }

  ngOnInit() {
    this.insomnia.keepAwake();
    this.reset();
  }

  reset() {
    this.timerService.reset();
    this.timerService.setWorkout(this.workout);
    this.volumeChanged();
    // show initial remaining time for preparation
    this.timerService.tickEvent.remainingTime = this.workout.timerConfig.durationPrepare * 1000;
    this.audioOn = this.bbStorage.audioOn;
  }

  toggleStartPause() {
    if (this.timerService.isRunning()) {
      this.timerService.pause();
      allowSleep();
    } else {
      this.timerService.start();
      keepAwake();
    }
  }

  async dismiss() {
    this.timerService.reset();
    await this.location.back();
  }

  volumeChanged() {
    this.mp3Service.volume = this.audioOn ? 100 : 0;
    this.bbStorage.audioOn = this.audioOn;
  }

  getCurrentBoulder():Boulder {
    return this.boulders[this.currentBoulderIndex()];
  }

  currentBoulderIndex() {
    return this.timerService.isRunning() ?
      (this.timerService.taskEvent.hangIndex > -1 ? this.timerService.taskEvent.hangIndex : 0) :
      0;
  }
}
