import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BBEnv } from 'src/environments/bb-env';
import { CommonUtils } from '../common';
import { GymDBDocType } from '../common/doc-types';
import { GymInfoDoc } from '../common/docs';
import { GymSyncDeniedError, GymSyncError, NoInternetError, NotAuthorizedError } from '../errors/bb-error';
import { BBStorage } from '../utils/bb-storage';
import { PouchUtils } from '../utils/pouch-utils';
import { ErrorNotificationService } from './error-notification.service';
import { ValidationService } from './validation.service';

import Database = PouchDB.Database;
import DatabaseConfiguration = PouchDB.Configuration.DatabaseConfiguration;

const PouchDB = require('pouchdb').default;
const SYNC_BATCH_SIZE = 1000;
const SYNC_BATCH_LIMIT = 100;

/**
 * This service handles all remote couch accesses (via the server's /db endpoint).
 * Local and remote gym dbs follow the same naming pattern.
 */
@Injectable()
export class CouchService {

  constructor(
    private bbStorage: BBStorage,
    private errorHandler: ErrorNotificationService,
    private validationService: ValidationService) {
  }


  public async removeRemoteGymInfoDoc(gymId: string) {
    // we only remove the entry within the all_gyms db
    const remoteAllGymsDB = this.getCouchAccess('all_gyms');
    const docs = await PouchUtils.getAllDocsWithPrefix<GymInfoDoc>(remoteAllGymsDB, GymDBDocType.GYM_INFO);
    docs.subscribe(gmyInfoDocs => {
      remoteAllGymsDB.remove(gmyInfoDocs.filter(doc => doc._id == gymId)[0]);
    });
  }

  public async syncLocalGymDBWithRemote(gymId: string, localDb: Database, syncDirection: SyncDirection = 'both'): Promise<any> {
    const dbName = CommonUtils.getDBNameForGymId(gymId);
    return new Promise<void>((resolve, reject) => {
      try {
        const remoteDB = this.getCouchAccess(dbName);
        switch (syncDirection) {
          case 'to-server':
            localDb.replicate.to(remoteDB, {
              live: false,
              retry: true,
              timeout: false,
              batch_size: SYNC_BATCH_SIZE,
              batches_limit: SYNC_BATCH_LIMIT
            })
              .on('denied', err => reject(new GymSyncDeniedError(err)))
              .on('complete', () => resolve())
              .on('error', err => reject(new GymSyncError(err)));
            break;
          case 'from-server':
            remoteDB.replicate.to(localDb, {
              live: false,
              retry: true,
              timeout: false,
              batch_size: SYNC_BATCH_SIZE,
              batches_limit: SYNC_BATCH_LIMIT
            })
              .on('denied', err => reject(new GymSyncDeniedError(err)))
              .on('complete', () => resolve())
              .on('error', err => reject(new GymSyncError(err, err?.toString())));
            break;
          case 'both':
            localDb.sync(remoteDB, {
              live: false,
              retry: true,
              timeout: false,
              batch_size: SYNC_BATCH_SIZE,
              batches_limit: SYNC_BATCH_LIMIT
            })
              .on('denied', err => reject(new GymSyncDeniedError(err)))
              .on('complete', () => resolve())
              .on('error', err => reject(new GymSyncError(err)));
            break;
        }

      } catch (err) {
        reject(err);
      }
    });
  }

  private throwIfNotLoggedIn() {
    if (!this.bbStorage.loggedIn) {
      throw new NotAuthorizedError();
    }
  }

  public getRemoteGymInfoDocs$(): Observable<GymInfoDoc[]> {
    this.throwIfNotLoggedIn();
    // todo: error handling for couch endpoint, see also related todos in server/db.ts
    const gymsDB = this.getCouchAccess('all_gyms');
    return PouchUtils.getAllDocsWithPrefix<GymInfoDoc>(gymsDB, GymDBDocType.GYM_INFO)
      .pipe(
        catchError(err => this.handleError(err)),
        tap(gymInfoDocs => gymInfoDocs.map((gymDoc: GymInfoDoc) => {
          this.validationService.validateDoc(gymDoc, GymDBDocType.GYM_INFO);
        }))
      );
  }

  private handleError(err) {
    if (err.status == 0) {
      return throwError(new NoInternetError());
    } else if (err.status == 401) {
      this.errorHandler.handleUnauthorized(err);
      return throwError(new NotAuthorizedError());
    } else {
      return throwError(err);
    }
  }

  public getRemoteGymInfoDoc$(gymId: string): Observable<GymInfoDoc> {
    this.throwIfNotLoggedIn();
    const gymsDB = this.getCouchAccess('all_gyms');
    return PouchUtils.getDocById<GymInfoDoc>(gymsDB, gymId)
      .pipe(
        catchError(err => this.handleError(err)),
        tap(gymInfoDoc => this.validationService.validateDoc(gymInfoDoc, GymDBDocType.GYM_INFO))
      );
  }

  public getCouchAccess(databaseName: string) {
    const session = this.bbStorage.userSession;
    return new PouchDB(`${BBEnv.getAppServerUrl()}db/${databaseName}`, {
      fetch: (url, opts) => {
        (opts.headers as any).set('Authorization', 'Bearer ' + session.token);
        return (PouchDB as any).fetch(url, opts);
      }
    } as DatabaseConfiguration);
  }

}

export type SyncDirection = 'both' | 'to-server' | 'from-server';
