import { Timer } from './Timer';

const DEFAULT_INTERVAL = 100;

export class Task {
  /**
   * @param duration - duration of this task in ms
   * @param onStart - callback to be executed when the task starts
   * @param onFinish - callback to be executed when the task is finished
   * @param onTick - callback to be executed on each tick, consumes the time of the tick since the task was started
   */
  constructor(
    public duration: number,
    public onStart: () => void,
    public onFinish: () => void,
    public onTick: (ms: number) => void
  ) {}
}

/**
 * A Scheduler takes a list of Tasks and executes one after the other.
 * The default tick interval is 0.1s
 * todo: implement pause/resume/reset
 */
export class Scheduler {
  private _interval;
  private _tasks: Task[];
  private _currTaskIndex = -1;
  private _timer: Timer | undefined;
  private _running: boolean = false;

  constructor(tasks: Task[], interval: number = DEFAULT_INTERVAL) {
    this._tasks = tasks;
    this._interval = interval;
  }

  start(): void {
    if (this._running) return;
    if (this._timer) this._timer.start();
    else this._run();
    this._running = true;
  }

  stop(): void {
    this._timer?.stop();
    this._running = false;
  }

  reset(): void {
    this.stop();
    this._currTaskIndex = -1;
    this._timer?.reset();
    this._timer = undefined;
  }

  private _run(): void {
    this._currTaskIndex++;
    const task = this._currTask();
    if (!task) return;
    this._timer = new Timer(this._interval, (tick) => {
      task.onTick(tick * this._interval);
      if (tick * this._interval >= task.duration) {
        this._timer!.stop();
        task.onFinish();
        this._run();
      }
    });
    task.onStart();
    this._timer.start();
  }

  private _currTask(): Task | null {
    if (this._currTaskIndex >= this._tasks.length) return null;
    return this._tasks[this._currTaskIndex];
  }


  get running(): boolean {
    return this._running;
  }
}
