import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {FeedPage} from './feed.page';
import {RouterModule, Routes} from '@angular/router';
import {ComponentsModule} from '../../components/components.module';
import {PipesModule} from '../../pipes/pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import { FeedItemComponent } from 'src/components/feed-item/feed-item.component';

const routes: Routes = [
  {
    path: '',
    component: FeedPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    PipesModule,
    TranslateModule,
    FeedItemComponent
  ],
  declarations: [FeedPage]
})
export class FeedPageModule {
}
