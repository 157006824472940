import {Injectable} from '@angular/core';
import {BBStorage} from '../utils/bb-storage';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEsExtra from '@angular/common/locales/extra/es';

export const AVAILABLE_LANGS = ['de', 'en', 'fr', 'es'];
export const DEFAULT_LANG = 'en';
export const LANGUAGE_DISPLAY_NAMES = {
  'de': 'Deutsch',
  'en': 'English',
  'fr': 'Français',
  'es': 'Español',
};

@Injectable()
export class TranslationService {

  constructor(
    private translate: TranslateService,
    private bbStorage: BBStorage) {
  }

  public initialize() {
    this.translate.setDefaultLang(DEFAULT_LANG);
    this.translate.addLangs(AVAILABLE_LANGS);
    if (this.bbStorage.language) {
      this.translate.use(this.bbStorage.language);
    } else {
      this.setCurrentLanguage(DEFAULT_LANG);
    }
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    registerLocaleData(localeEn, 'en-US', localeEnExtra);
    registerLocaleData(localeEn, 'fr-FR', localeFrExtra);
    registerLocaleData(localeEn, 'es-ES', localeEsExtra);

  }

  setCurrentLanguage(selectedLanguage: string) {
    this.translate.use(selectedLanguage);
    this.bbStorage.language = selectedLanguage;
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  public getLocale(): string {
    switch (this.translate.currentLang) {
      case 'de':
        return 'de-DE';
      case 'fr':
        return 'fr-FR';
      case 'es':
        return 'es-ES';
      case 'en':
      default:
        return 'en-US';
    }
  }

}

