import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {FourByFourPageRoutingModule} from './four-by-four-routing.module';
import {FourByFourPage} from './four-by-four.page'
import {ComponentsModule} from '../../components/components.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FourByFourPageRoutingModule,
    FormsModule,
    ComponentsModule,
    TranslateModule
  ],
  declarations: [FourByFourPage]
})
export class FourByFourPageModule {
}
