import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoadingController} from '@ionic/angular';

export const AVAILABLE_LANGS = ['de', 'en'];
export const DEFAULT_LANG = 'en';
export const LANGUAGE_DISPLAY_NAMES = {
  'de': 'Deutsch',
  'en': 'English'
};

@Injectable()
export class LoadingService {

  constructor(
    private translate: TranslateService,
    private loadingCtrl: LoadingController) {
  }

  public async createAndShowDefaultLoadingMessage() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('shared.please-wait')
    });
    loader.present();
    return loader;
  }

}

