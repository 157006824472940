import { Observable } from 'rxjs';
import { EditableBoulder } from '../model/editable-boulder';
import { BoulderListEntry } from '../model/boulder-list-entry';
import { Boulder } from '../model/boulder';

export abstract class BoulderService {

  abstract deleteBoulder(boulderId: string): Promise<void>;

  abstract addOrUpdateBoulder(boulder: EditableBoulder): Promise<string>;

  abstract getBouldersListEntries$(): Observable<BoulderListEntry[]>;

  abstract getBoulders$(): Observable<Boulder[]>;

  abstract getAllTimeBoulders$(): Observable<Boulder[]>;

  abstract getBoulderDetails$(boulderId: string): Observable<Boulder>;

  abstract setBoulderToPublic(boulderId: string): Promise<void>;

}
