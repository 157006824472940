import { Hang } from './Hang';

export class Batch {
  private _prep: number;
  private _hangs: Hang[];
  private _rest: number;

  constructor(prep: number, hangs: Hang[], rest: number) {
    this._prep = prep;
    this._hangs = hangs;
    this._rest = rest;
  }

  get prep(): number {
    return this._prep;
  }

  get hangs(): Hang[] {
    return this._hangs;
  }

  get rest(): number {
    return this._rest;
  }
}
