import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DocsCreator } from '../common/docs-creator';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { BoulderListDoc } from '../common/docs';
import { GymDBDocType } from '../common/doc-types';
import { BoulderList } from '../model/boulder-list';
import { DocWriteService } from './doc.write.service';
import { DocQueryService } from './doc.query.service';
import CollectionUtils from '../utils/collection-utils';
import { BBStorage } from '../utils/bb-storage';
import { GuestService } from './guest-service';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'cypress/types/bluebird';


@Injectable()
export class BoulderListService {

  constructor(
    private translate: TranslateService,
    private bbStorage: BBStorage,
    private guestService: GuestService,
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private authService: AuthService) {
  }


  public async createNewList(name: string, publicList: boolean): Promise<BoulderList> {
    const doc = DocsCreator.createBoulderList(
      name, this.authService.getCurrentUsername(), publicList
    );
    await this.docWriteService.addDocument(doc);
    return this.createBoulderList(doc);
  }

  public getOwnAndPublicBoulderLists$(): Observable<BoulderList[]> {
    return this.docQueryService.getDocsOfType$<BoulderListDoc>(GymDBDocType.BOULDER_LIST)
      .pipe(
        switchMap(async boulderListDocs => {
          const boulderLists: BoulderList[] = [];
          for (const boulderListDoc of boulderListDocs) {
            if (boulderListDoc.public || boulderListDoc.author === this.authService.getCurrentUsername()) {
              boulderLists.push(await this.createBoulderList(boulderListDoc));
            }
          }
          if (!this.guestService.isGuest() && boulderLists.filter(l => l.isProjectList).length === 0) {
            boulderLists.push(await this.createProjectList());
          }
          return boulderLists;
        })
      );
  }

  public getOwnLists(): Observable<BoulderList[]> {
    return this.getOwnAndPublicBoulderLists$()
      .pipe(
        switchMap(async boulderLists => boulderLists.filter(l => l.author === this.authService.getCurrentUsername()))
      );
  }

  public getBoulderList$(listId: string): Observable<BoulderList> {
    return this.docQueryService.getDocOfTypeWithId$<BoulderListDoc>(GymDBDocType.BOULDER_LIST, listId)
      .pipe(
        switchMap(boulderListDoc => boulderListDoc ? this.createBoulderList(boulderListDoc) : null)
      );
  }

  public async addBoulderIdToList(boulderId: string, listId: string) {
    const list = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$<BoulderListDoc>(GymDBDocType.BOULDER_LIST, listId));
    if (list) {
      list.boulderIds.push(boulderId);
      await this.docWriteService.updateDocument(list);
    }
  }

  public async removeBoulderIdFromList(boulderId, listId) {
    const list = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$<BoulderListDoc>(GymDBDocType.BOULDER_LIST, listId));
    BoulderListService.removeAllInstances(list.boulderIds, boulderId);
    await this.docWriteService.updateDocument(list);
  }

  public async removeBoulderList(listId) {
    const list = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$<BoulderListDoc>(GymDBDocType.BOULDER_LIST, listId));
    await this.docWriteService.removeDocument(list);
  }

  public async changeVisibility(listId: string, publicList: boolean) {
    const list = await this.getListById(listId);
    (list as any).public = publicList;
    await this.docWriteService.updateDocument(list);
  }

  public async updateBoulderIds(listId: string, boulderIds: string[]) {
    const list = await this.getListById(listId);
    if (list) {
      (list as any).boulderIds = boulderIds;
      await this.docWriteService.updateDocument(list);
    }
  }

  public async updateNameOfList(listId: string, name: string) {
    const list = await this.getListById(listId);
    if (list) {
      (list as any).name = name;
      await this.docWriteService.updateDocument(list);
    }
  }

  private async createBoulderList(boulderListDoc: BoulderListDoc) {
    const isOwnProjectList = DocsCreator.isProjectBoulderListId(boulderListDoc._id, this.authService.getCurrentUsername());
    return new BoulderList(
      new Date(boulderListDoc.timeCreated), boulderListDoc.author,
      boulderListDoc.public, boulderListDoc.name, boulderListDoc._id, boulderListDoc.boulderIds
        .filter(CollectionUtils.onlyUnique)
        .filter((boulderId) => this.bbStorage.activeGymBouldersById.has(boulderId) &&
          (!isOwnProjectList || !this.bbStorage.activeGymBouldersById.get(boulderId).ticked)), isOwnProjectList);
  }

  private async createProjectList() {
    const doc = DocsCreator.createBoulderList(
      this.translate.instant('boulder-list-service.project-list'), this.authService.getCurrentUsername(), false
      , true);
    await this.docWriteService.addDocument(doc);
    return this.createBoulderList(doc);
  }

  private static removeAllInstances(arr, item) {
    for (let i = arr.length; i--;) {
      if (arr[i] === item) {
        arr.splice(i, 1);
      }
    }
  }

  private async getListById(listId: string): Promise<BoulderListDoc> {
    const list = await firstValueFrom(this.docQueryService.getDocOfTypeWithId$<BoulderListDoc>(GymDBDocType.BOULDER_LIST, listId));
    if (!list) {
      throw new Error('Could not find list with id=' + listId);
    }
    return list;
  }

  public async addBoulderIdToProjectList(boulderId: string) {
    await this.addBoulderIdToList(boulderId, DocsCreator.getProjectListId(this.authService.getCurrentUsername()));
  }
}
