<ion-header>
  <ion-toolbar>
    <ion-title>{{'add-to-boulder-list-page.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="true" class="has-header">
  <ion-fab horizontal="end" slot="fixed" vertical="bottom">
    <ion-fab-button color="primary" (click)="createNewList()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <h2 *ngIf="lists.length==0">{{'add-to-boulder-list-page.explain' | translate}}</h2>
  <ion-list>
    <ion-item *ngFor="let list of lists | newestFirst">
      <ion-checkbox [(ngModel)]="list.isChecked" [name]="list.name">{{list.name}}</ion-checkbox>
    </ion-item>
  </ion-list>
  <ion-button *ngIf="lists.length>0" (click)="addToSelectedLists()" expand="full">
    <ion-icon name="checkbox" slot="start"></ion-icon>
    {{'add-to-boulder-list-page.save' | translate}}
  </ion-button>
</ion-content>

