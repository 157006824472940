import {Color} from '../model/color';

export default class ColorUtils {

  static getSupportedFootholdColors(): Color[] {
    const colors = [];
    colors.push(new Color('red', '#FF0000'));
    colors.push(new Color('green', '#31e200'));
    colors.push(new Color('purple', '#800080'));
    colors.push(new Color('white', '#ffffff'));
    colors.push(new Color('blue', '#151CFF'));
    colors.push(new Color('yellow', '#E8DB0C'));
    colors.push(new Color('black', '#000'));
    colors.push(new Color('pink', '#E80CA0'));
    colors.push(new Color('wood', '#522d1f'));
    return colors;
  }

}
