export interface GradingSystem {

  /**
   * @returns {string} identifier for grading system
   */
  systemId(): string

  /**
   * @returns {number} minimal possible grade as integer. E.g. 0 for fb 3
   */
  minGradeInteger(): number;

  /**
   * @returns {number} maximal possible grade as integer. E.g. 16 for V17
   */
  maxGradeInteger(): number;

  /**
   * @returns {string[]} array with display names for each grade. Length of array is equal to @method{maxGradeInteger()}-@method{minGradeInteger()}
   */
  displayNames(): string[];

  /**
   * @param {string} grade as integer for which dispay name should be returned
   * @returns {string} display name for grade
   */
  displayName(grade: number): string;

  /**
   * @returns {number[]} array with all possible grades as integers
   */
  possibleGrades(): number[];
}

const gradeTable = [
  ['Fb 3', 'VB'],  // 0
  ['Fb 4', 'V0'],  // 1
  ['Fb 5', 'V1'],  // 2
  ['Fb 6a', 'V2'], // 3
  ['Fb 6a+', 'V3'], // 4
  ['Fb 6b', 'V4'], // 5
  ['Fb 6b+', 'V4/5'], // 6
  ['Fb 6c', 'V5'], // 7
  ['Fb 6c+', 'V5/6'], // 8
  ['Fb 7a', 'V6'], // 9
  ['Fb 7a+', 'V7'], // 10
  ['Fb 7b', 'V8'], // 11
  ['Fb 7b+', 'V8/9'], // 12
  ['Fb 7c', 'V9'], // 13
  ['Fb 7c+', 'V10'], // 14
  ['Fb 8a', 'V11'], // 15
  ['Fb 8a+', 'V12'], // 16
  ['Fb 8b', 'V13'], // 17
  ['Fb 8b+', 'V14'], // 18
  ['Fb 8c', 'V15'], // 19
  ['Fb 8c+', 'V16'], // 20
  ['Fb 9a', 'V17'] // 21
];

abstract class BaseGradingSystem implements GradingSystem {

  constructor(private gradeTableIndex: number, private _systemId: string) {
  }

  public systemId(): string {
    return this._systemId;
  }

  public minGradeInteger(): number {
    return 0;
  }

  public maxGradeInteger(): number {
    return gradeTable.length - 1;
  }

  public displayNames(): string[] {
    return gradeTable.map(grade => grade[this.gradeTableIndex]);
  }

  public displayName(grade: number): string {
    if (grade === null || grade === undefined || isNaN(grade) || grade < this.minGradeInteger() || grade > this.maxGradeInteger()) {
      return '?';
    }
    return gradeTable[grade][this.gradeTableIndex];
  }

  public possibleGrades(): number[] {
    return Array.apply(null, {length: gradeTable.length}).map(Number.call, Number);
  }

}

export class FontGradingSystem extends BaseGradingSystem {
  constructor() {
    super(0, 'font');
  }

}

export class VGradingSystem extends BaseGradingSystem {
  constructor() {
    super(1, 'v');
  }

}

