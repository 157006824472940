import {NgModule} from '@angular/core';
import {GymSearchPipe} from './gym-search.pipe';
import {BoulderGradePipe} from './boulder-grade.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {SortBoulderListPipe} from './sort-boulder-list.pipe';
import {SortByDatePipe} from './sort-by-date.pipe';
import {SortByRankPipe} from './sort-by-rank.pipe';
import {TruncatePipe} from './truncate.pipe';
import {BoulderSearchPipe} from './boulder-search.pipe';
import {GymSortPipe} from './gym-sort.pipe';
import {DateTimeFormatPipe} from './date-time-format.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {SortFeedPipe} from './sort-feed.pipe';

@NgModule({
  declarations: [
    GymSearchPipe,
    GymSortPipe,
    BoulderGradePipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    SortBoulderListPipe,
    SortByDatePipe,
    SortByRankPipe,
    SortFeedPipe,
    TruncatePipe,
    BoulderSearchPipe
  ],
  imports: [TranslateModule],
  exports: [
    GymSearchPipe,
    GymSortPipe,
    BoulderGradePipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    SortBoulderListPipe,
    SortByDatePipe,
    SortByRankPipe,
    SortFeedPipe,
    TruncatePipe,
    BoulderSearchPipe
  ]
})
export class PipesModule {
}
