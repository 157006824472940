import { Hold } from '../common/docs';
import { Boulder } from './boulder';
import { EditableFootholdConfig } from './editable-foothold-config';
import { WallImage } from './wall-image';

/** Data class used to create new and edit existing boulders */
export class EditableBoulder {

  private _boulderId: string;
  private _wallId: string;
  private _wallImage: WallImage;
  private _boulderName = '';
  private _holds: Hold[] = [];
  private _footholdConfig: EditableFootholdConfig = new EditableFootholdConfig();
  private _proposedGrade = -1;
  private _createdBy: string;
  private _isPrivate: boolean = false;
  private _enumerateHolds: boolean = false;


  constructor(boulder: Boulder = null) {
    if (boulder) {
      this.boulderId = boulder.boulderId;
      this.footholdConfig = boulder.footholdConfig;
      this.boulderName = boulder.boulderName;
      this.holds = boulder.holds;
      this.wallImage = boulder.wallImage;
      this.wallId = boulder.wallId;
      this.isPrivate = boulder.isPrivate
      this.createdBy = boulder.author;
      this.enumerateHolds = boulder.enumerateHolds
    }
  }

  /**
   * A boulder must contain at least
   * 1. one double-start hold or two single-start hold
   * 2. one top hold
   */
  public isValid(): boolean {
    const holdCount = this.countHolds();
    return this.startIsDefined(holdCount) && this.topIsDefined(holdCount);
  }

  public startIsDefined(holdCount?: HoldCount): boolean {
    const count = holdCount ? holdCount : this.countHolds();
    return count.numSingleStartHolds == 2 || count.numDoubleStartHolds == 1;
  }

  public topIsDefined(holdCount?: HoldCount): boolean {
    const count = holdCount ? holdCount : this.countHolds();
    return count.numTopHolds == 1;
  }

  private countHolds(): HoldCount {
    let numSingleStartHolds = 0;
    let numDoubleStartHolds = 0;
    let numTopHolds = 0;
    for (const hold of this._holds) {
      if (hold.holdType == 'double-start') {
        numDoubleStartHolds++;
      }
      if (hold.holdType == 'single-start') {
        numSingleStartHolds++;
      }
      if (hold.holdType == 'top') {
        numTopHolds++;
      }
    }
    return {
      numSingleStartHolds: numSingleStartHolds,
      numDoubleStartHolds: numDoubleStartHolds,
      numTopHolds: numTopHolds
    } as HoldCount;
  }


  get boulderId(): string {
    return this._boulderId;
  }

  set boulderId(value: string) {
    this._boulderId = value;
  }


  get wallId(): string {
    return this._wallId;
  }

  set wallId(value: string) {
    this._wallId = value;
  }


  get wallImage(): WallImage {
    return this._wallImage;
  }

  set wallImage(value: WallImage) {
    this._wallImage = value;
  }

  get boulderName(): string {
    return this._boulderName;
  }

  set boulderName(value: string) {
    this._boulderName = value;
  }

  get holds(): Hold[] {
    return this._holds;
  }

  set holds(value: Hold[]) {
    this._holds = value;
  }

  get footholdConfig(): EditableFootholdConfig {
    return this._footholdConfig as EditableFootholdConfig;
  }

  set footholdConfig(value: EditableFootholdConfig) {
    this._footholdConfig = value;
  }

  get proposedGrade(): number {
    return this._proposedGrade;
  }

  set proposedGrade(value: any) {
    this._proposedGrade = value;
  }

  set createdBy(value: string) {
    this._createdBy = value;
  }

  get isPrivate(): boolean {
    return this._isPrivate;
  }

  set isPrivate(value: boolean) {
    this._isPrivate = value;
  }

  get enumerateHolds(): boolean {
    return this._enumerateHolds;
  }

  set enumerateHolds(value: boolean) {
    this._enumerateHolds = value;
  }
}

export interface HoldCount {
  numSingleStartHolds: number;
  numDoubleStartHolds: number;
  numTopHolds: number;
}
