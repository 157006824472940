export class GymListEntry {
  constructor(
    readonly isNewGym: boolean,
    readonly timeCreated: Date,
    readonly gymId: string,
    readonly gymName: string,
    readonly gymAdmin: string,
    readonly city: string,
    readonly country: string,
    readonly address: string,
    readonly isPrivate: boolean,
    readonly gymRules: string,
    readonly gymAdmins: string[],
  ) {
  }
}
