import {Pipe, PipeTransform} from '@angular/core';
import { GradeService } from 'src/services/grade.service';

@Pipe({
  name: 'boulderGrade'
})
export class BoulderGradePipe implements PipeTransform {

  constructor (private gradeService: GradeService) {}

  transform(grade: number): any {
    return this.gradeService.getCurrentGradeSystem().displayName(grade);
  }
}
