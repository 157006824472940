import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// TODO: set true once in production
const disableConsoleMethods = false;
if (disableConsoleMethods) {
  window.console.trace =
    window.console.info =
      window.console.log =
        window.console.warn =
          window.console.time =
            window.console.timeEnd =
              window.console.table =
                function () {
                };
}
