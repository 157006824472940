import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActiveGymState, activeGymFeatureKey } from './active-gym.reducer';

export const selectActiveGymState = createFeatureSelector<ActiveGymState>(activeGymFeatureKey);

export const selectActiveGym = createSelector( selectActiveGymState, (state: ActiveGymState) =>  state?.activeGym ?? null);

export const selectActiveGymId = createSelector( selectActiveGymState, (state: ActiveGymState) => state?.activeGym?.gymId ?? null);

export const selectActiveGymIsBeingDowloaded = createSelector( selectActiveGymState, (state: ActiveGymState) => state?.isBeingDownloaded ?? false);

export const selectActiveGymIsDowloaded = createSelector( selectActiveGymState, (state: ActiveGymState) => state?.isDownloaded ?? false);
