import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, GetUriOptions } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import write_blob from 'capacitor-blob-writer';
import { BBEnv } from 'src/environments/bb-env';
import { AuthService } from './auth.service';


const CACHE_DIRECTORY = Directory.Data;
const WALL_IMAGE_DIR = 'wall_images/';
const IMAGE_FILE_TYPE = '.jpg';
const WALL_IMAGE_ENDPOINT = BBEnv.getAppServerUrl() + 'wall-image';
@Injectable()
export class ImageCachingService {

  constructor(
    private platform: Platform,
    private authService: AuthService) {

    this.initCacheFolder();

  }

  private async initCacheFolder() {
    try {
      Filesystem.mkdir({
        path: WALL_IMAGE_DIR,
        directory: CACHE_DIRECTORY
      }).catch(e => {
        // ignore
      });
    } catch (e) {
      // ignore
    }
  }

  public async getImageSrc(wallImageId: string): Promise<any> {
    const alreadyCached = await this.imageAlreadyCached(wallImageId);
    if (alreadyCached) {
      const path = ImageCachingService.localImagePath(wallImageId);
      return await this.getCapacitorImageSrc(wallImageId);
    } else {
      return await this.cacheImage(wallImageId);
    }
  }

  public async cacheImage(wallImageId: string): Promise<any> {
    const url = ImageCachingService.getWallImageUrl(wallImageId);
    const response = await fetch(url, {
      method: 'GET',
      headers: [['Authorization', 'Bearer ' + this.authService.getCurrentUserSession().token]]
    });
    const blob = await response.blob();
    await write_blob({
      path: ImageCachingService.localImagePath(wallImageId),
      directory: CACHE_DIRECTORY,
      blob: blob
    });
    return await this.getCapacitorImageSrc(wallImageId);
  }

  private async imageAlreadyCached(wallImageId: string): Promise<boolean> {
    return this.checkFileExists({ path: ImageCachingService.localImagePath(wallImageId), directory: CACHE_DIRECTORY });
  }

  private static localImagePath(wallImageId: string): string {
    return WALL_IMAGE_DIR + wallImageId + IMAGE_FILE_TYPE;
  }

  private static getWallImageUrl(imageId: string): string {
    return WALL_IMAGE_ENDPOINT + '/' + imageId;
  }

  private async getCapacitorImageSrc(wallImageId: string) {
    if (this.isApp()) {
      const uri = await Filesystem.getUri({
        directory: CACHE_DIRECTORY,
        path: ImageCachingService.localImagePath(wallImageId)
      });
      return Capacitor.convertFileSrc(uri.uri);
    } else {
      const file = await Filesystem.readFile({
        path: ImageCachingService.localImagePath(wallImageId),
        directory: CACHE_DIRECTORY,
      });
      return `data:image/jpeg;base64,${file.data}`;
    }
  }

  private async checkFileExists(getUriOptions: GetUriOptions): Promise<boolean> {
    try {
      await Filesystem.stat(getUriOptions);
      return true;
    } catch (checkDirException) {
      return false;
    }
  }

  private isApp() {
    return this.platform.is('capacitor') && (this.platform.is('ios') || this.platform.is('android'));
  }

}
