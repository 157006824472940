import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { GymDBDocType } from '../common/doc-types';
import { AscentDoc, BoulderDoc, CommentDoc, DifficultyRatingDoc, GymDBDoc, ReactionDoc } from '../common/docs';
import { Boulder } from '../model/boulder';
import { FeedItem } from '../model/feed-item';
import { FeedItemType } from '../model/feed-item-type';
import { SortFeedPipe } from '../pipes/sort-feed.pipe';
import { BBStorage } from '../utils/bb-storage';
import { AuthService } from './auth.service';
import { BoulderService } from './boulder.service';
import { DbChangesService } from './db.changes.service';
import { DifficultyRatingService } from './difficulty-rating.service';
import { DocQueryService } from './doc.query.service';
import { GymChatService } from './gym-chat.service';
import { UserService } from './user.service';

const MAX_NUM_FEED_ITEMS = 200;

@Injectable()
export class FeedItemService {

  private _unreadFeedItems = false;

  constructor(
    private activeGymFacade: ActiveGymFacade,
    private gymChatService: GymChatService,
    private boulderService: BoulderService,
    private userService: UserService,
    private dbChangesService: DbChangesService,
    private docQueryService: DocQueryService,
    private authService: AuthService,
    private bbStorage: BBStorage) {
  }


  private async cacheIsUpToDate(): Promise<boolean> {
    return this.bbStorage.activeGymFeedItemCacheLastSync && this.bbStorage.activeGymFeedItems && !(await this.dbChangesService.pouchHasChangedComparedTo(this.bbStorage.activeGymFeedItemCacheLastSync));
  }

  public resetUnread() {
    this._unreadFeedItems = false;
  }

  get unreadFeedItems(): boolean {
    return this._unreadFeedItems;
  }

  private async updateNumUnread() {
    const activeGymId = await this.activeGymId();
    const dateOfLastFeedSeen = this.bbStorage.getLatestDocShownInFeedTimeCreatedForGym(activeGymId);
    this._unreadFeedItems = Array.from(this.bbStorage.activeGymFeedItems.values()).filter(i => i.date > dateOfLastFeedSeen).length > 0;
  }

  public async fetchNewDocsAndUpdateFeedItems(): Promise<FeedItem<GymDBDoc>[]> {
    if (!await this.cacheIsUpToDate()) {
      try {
        const lastSeq = 0//; this.bbStorage.activeGymFeedItemCacheLastSync ? parseInt(this.bbStorage.activeGymFeedItemCacheLastSync) : 0;
        const deletedDocIds = await firstValueFrom(this.docQueryService.getDeletedDocIds$(lastSeq));
        await this.removeFeedItemsForDeletedDocs(deletedDocIds);
        const newOrChangedDocs = await firstValueFrom(this.docQueryService.getNewOrChangedDocs$(lastSeq));
        await this.createFeedItemsAndAddToCache(newOrChangedDocs);
        await this.bbStorage.setActiveGymFeedItemCacheLastSync(await this.dbChangesService.getPouchUpdateSeq());
        await this.updateNumUnread();
        await firstValueFrom(this.gymChatService.loadMessagesAndUpdateNumUnread());
      } catch (e) {
        await this.bbStorage.setActiveGymFeedItems(new Map<string, FeedItem<GymDBDoc>>());
        await this.bbStorage.setActiveGymFeedItemCacheLastSync(null);
        throw e;
      }
    }
    await this.updateNumUnread();
    return await this.getSortedFeedItems();
  }

  public async getSortedFeedItems() {
    const activeGymId = await this.activeGymId();
    return SortFeedPipe.sortFeedItems(Array.from(this.bbStorage.activeGymFeedItems.values()), this.bbStorage.getLatestDocShownInFeedTimeCreatedForGym(activeGymId));
  }

  private async createFeedItemsAndAddToCache(newlyAddedOrChangedDocs: GymDBDoc[]) {
    const boulders = await firstValueFrom(this.boulderService.getBoulders$());
    const bouldersById = new Map(boulders.map(b => [b.boulderId, b]));
    const items = [];
    // only consider newlyAddedOrChangedDocs with a timeCreated property
    for (const newOrChangedDoc of newlyAddedOrChangedDocs.filter(doc => doc.timeCreated)) {
      if (this.bbStorage.activeGymFeedItems.has(newOrChangedDoc._id)) {
        this.bbStorage.activeGymFeedItems.delete(newOrChangedDoc._id);
      }
      if (this.bbStorage.activeGymFeedItems.has(newOrChangedDoc._id)) {
        this.bbStorage.activeGymFeedItems.delete(newOrChangedDoc._id);
      }
      switch (newOrChangedDoc.type) {
        case GymDBDocType.BOULDER:
          if (bouldersById.has(newOrChangedDoc._id)) {
            items.push(this.forBoulder(newOrChangedDoc as BoulderDoc, bouldersById.get(newOrChangedDoc._id)));
          }
          break;
        case GymDBDocType.ASCENT:
          if (bouldersById.has((newOrChangedDoc as AscentDoc).boulderId)) {
            items.push(this.forAscent(newOrChangedDoc as AscentDoc, bouldersById.get((newOrChangedDoc as AscentDoc).boulderId)));
          }
          break;
        case GymDBDocType.COMMENT:
          if (bouldersById.has((newOrChangedDoc as CommentDoc).boulderId)) {
            items.push(this.forComment(newOrChangedDoc as CommentDoc, bouldersById.get((newOrChangedDoc as CommentDoc).boulderId)));
          }
          break;
        case GymDBDocType.DIFFICULTY_RATING:
          if (bouldersById.has((newOrChangedDoc as DifficultyRatingDoc).boulderId)) {
            items.push(this.forDifficultyRating(newOrChangedDoc as DifficultyRatingDoc, bouldersById.get((newOrChangedDoc as DifficultyRatingDoc).boulderId)));
          }
          break;
      }
    }
    // add already cached feed items
    if (this.bbStorage.activeGymFeedItems) {
      items.push(...Array.from(this.bbStorage.activeGymFeedItems.values()));
    }
    const topItems: [string, FeedItem<GymDBDoc>][] = items
      .filter(i => i) // filter out nulls
      .sort(FeedItemService.sortByDateNewestFirst) // sort the newest first
      .splice(0, MAX_NUM_FEED_ITEMS)
      .map(i => [i.doc._id, i]); // choose top items
    const feedItemMap = new Map(topItems);
    for (const reactionDoc of newlyAddedOrChangedDocs.filter(doc => doc.type === GymDBDocType.REACTION)) {
      const doc = (reactionDoc as ReactionDoc);
      if (feedItemMap.has(doc.docId)) {
        const feedItem = feedItemMap.get(doc.docId);
        feedItem.emojisByAuthor.set(doc.author, doc.emoji);
      }
    }
    await this.bbStorage.setActiveGymFeedItems(feedItemMap);
  }

  private forBoulder(boulderDoc: BoulderDoc, boulder: Boulder): FeedItem<BoulderDoc> {
    if (boulderDoc.isPrivate) {
      return null;
    }
    const highlight = boulder.author === this.authService.getCurrentUsername();
    const type = boulder.ascents.filter(a => a.userName === boulderDoc.author).length > 0 ? FeedItemType.NEW_BOULDER_WITH_ASCENT : FeedItemType.NEW_BOULDER;
    return new FeedItem<BoulderDoc>(type, boulderDoc._id, boulderDoc.boulderName, highlight,
      boulder.grade, boulder.creationDate, boulderDoc, boulderDoc.author);
  }

  private forAscent(ascentDoc: AscentDoc, boulder: Boulder): FeedItem<AscentDoc> {
    if (boulder.author === ascentDoc.author && this.sameDay(boulder.creationDate, new Date(ascentDoc.timeCreated))) {
      return null;
    }
    const highlight = boulder.author === this.authService.getCurrentUsername() || ascentDoc.author === this.authService.getCurrentUsername();
    return new FeedItem<AscentDoc>(FeedItemType.ASCENT, boulder.boulderId, boulder.boulderName, highlight, boulder.grade, new Date(ascentDoc.timeCreated), ascentDoc, ascentDoc.author);
  }

  private forComment(commentDoc: CommentDoc, boulder: Boulder): FeedItem<CommentDoc> {
    const highlight = boulder.author === this.authService.getCurrentUsername() || commentDoc.author === this.authService.getCurrentUsername();
    return new FeedItem<CommentDoc>(FeedItemType.COMMENT, boulder.boulderId, boulder.boulderName, highlight, boulder.grade, new Date(commentDoc.timeCreated), commentDoc, commentDoc.author);
  }

  private forDifficultyRating(difficultyRatingDoc: DifficultyRatingDoc, boulder: Boulder): FeedItem<DifficultyRatingDoc> {
    const gradeBefore = DifficultyRatingService.computeGrade(boulder.ascents.filter(a =>
      !isNaN(a.personalGrade)
      // only count grades older than this difficulty
      && a.date.valueOf() < new Date(difficultyRatingDoc.timeCreated).valueOf()
      && a.userName !== difficultyRatingDoc.author).map(a => a.personalGrade), boulder.suggestedGradeByAuthor).grade;
    if (gradeBefore > -1 && gradeBefore && gradeBefore !== difficultyRatingDoc.difficulty) {
      const highlight = boulder.author === this.authService.getCurrentUsername() || difficultyRatingDoc.author === this.authService.getCurrentUsername();
      const type = gradeBefore > difficultyRatingDoc.difficulty ? FeedItemType.DOWN_VOTE : FeedItemType.UP_VOTE;
      return new FeedItem<DifficultyRatingDoc>(type, boulder.boulderId, boulder.boulderName, highlight, gradeBefore, new Date(difficultyRatingDoc.timeCreated), difficultyRatingDoc, difficultyRatingDoc.author);
    }
    return null;
  }

  public static sortByDateNewestFirst(a: FeedItem<GymDBDoc>, b: FeedItem<GymDBDoc>) {
    return b?.date?.getTime() - a?.date?.getTime();
  }

  private async removeFeedItemsForDeletedDocs(deletedDocIds: string[]) {
    for (const deletedDocId of deletedDocIds.filter(d => this.bbStorage.activeGymFeedItems.has(d))) {
      this.bbStorage.activeGymFeedItems.delete(deletedDocId);
    }
  }

  private sameDay(a: Date, b: Date) {
    return a.getDay() === b.getDay() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  private activeGymId(): Promise<string> {
    return firstValueFrom(this.activeGymId$());
  }

  private activeGymId$() {
    return this.activeGymFacade.activeGymId$;
  }
}
