<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()" fill="clear">
        <ion-icon name="close" slot="start"></ion-icon>
        {{"grade-list-page.close" | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="averageGrade else loading">
  <h1>{{"grade-list-page.title" | translate}}</h1>
  <p *ngIf="!noAscents">{{"grade-list-page.average-grade" | translate}} <b>{{averageGrade|boulderGrade}}</b></p>
  <p *ngIf="noAscents">{{"grade-list-page.no-ascents-note" | translate}}</p>
  <p *ngIf="!noAscents">{{"grade-list-page.only-ascents-note" | translate}}</p>
  <ion-list>
    <ion-item *ngFor="let grade of ratingsPerGrade | keyvalue" class="ion-no-padding">
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>{{grade.key | boulderGrade }} <span class="personal-grade" (click)="changeGrade()"
                                                  *ngIf="ascentOfUser?.personalGrade==grade.key">({{"grade-list-page.your-grading" | translate}}
            )</span></h3>
        </ion-text>
        <p>
          <ion-progress-bar color="secondary" value="{{grade.value/totalNumGradings}}"></ion-progress-bar>
        </p>
        <ion-text color="secondary">
          <p class="note" *ngIf="grade.value<=1">{{grade.value}} {{"grade-list-page.rating" | translate}}</p>
          <p class="note" *ngIf="grade.value>1">{{grade.value}} {{"grade-list-page.ratings" | translate}}</p>
        </ion-text>
      </ion-label>
    </ion-item>
  </ion-list>
  <p *ngIf="ascentOfUser && !ascentOfUser.personalGrade">{{"grade-list-page.boulder-not-graded" | translate}}</p>
  <ion-button fill="clear" size="large" *ngIf="ascentOfUser && !ascentOfUser.personalGrade"
              (click)="changeGrade()">{{"grade-list-page.grade-now" | translate}}</ion-button>
</ion-content>
<ng-template #loading>
  <h1>{{"grade-list-page.load-rating" | translate}}</h1>
</ng-template>
