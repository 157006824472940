import { Component, Input, OnInit } from '@angular/core';
import { FeedItem } from '../../model/feed-item';
import { FeedItemType } from '../../model/feed-item-type';
import { BoulderDetailsPage } from '../../pages/boulder-details/boulder-details.page';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactionService } from '../../services/reaction.service';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/pipes/pipes.module';
import { UsernameComponent } from '../username/username.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { DirectivesModule } from 'src/directives/directives.module';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, PipesModule, TranslateModule, DirectivesModule, UsernameComponent, PickerComponent],
  selector: 'feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss'],
})
export class FeedItemComponent implements OnInit {

  Type = FeedItemType;
  @Input()
  item: FeedItem<any>;

  @Input()
  isNew: boolean;

  title: String;
  icon: String;
  emojiPickerModalOpen = false;
  emojis: Map<string, number> = new Map();

  emojisActivated = true;

  constructor(
    private reactionService: ReactionService,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.initEmojis();
    switch (this.item.type) {
      case FeedItemType.ASCENT:
        this.title = this.item.doc.ascentType == 'flash' ?
          this.translate.instant('feed-item-component.flash-ascent') :
          this.translate.instant('feed-item-component.ascent');
        this.icon = this.item.doc.ascentType == 'flash' ? 'flash-outline' : 'shield-checkmark-outline';
        break;
      case FeedItemType.NEW_BOULDER:
        this.title = this.translate.instant('feed-item-component.new-boulder');
        this.icon = 'add-circle-outline';
        break;
      case FeedItemType.NEW_BOULDER_WITH_ASCENT:
        this.title = this.translate.instant('feed-item-component.new-boulder-with-ascent');
        this.icon = 'add-circle-outline';
        break;
      case FeedItemType.COMMENT:
        this.title = this.translate.instant('feed-item-component.comment');
        this.icon = 'chatbox-ellipses-outline';
        break;
      case FeedItemType.UP_VOTE:
        this.title = this.translate.instant('feed-item-component.boulder-upgraded');
        this.icon = 'trending-up-outline';
        break;
      case FeedItemType.DOWN_VOTE:
        this.title = this.translate.instant('feed-item-component.boulder-downgraded');
        this.icon = 'trending-down-outline';
        break;
    }
  }

  async openDetails() {
    const modal = await this.modalCtrl.create({
      id: 'boulder-details-modal',
      component: BoulderDetailsPage,
      componentProps: { boulderId: this.item.boulderId, boulderIds: [] }
    });
    return await modal.present();
  }

  async addEmojiAsReaction($event: any) {
    this.dismissEmojiPicker();
    if ($event?.emoji?.unified) {
      this.addEmoji($event.emoji.unified);
    }
  }

  dismissEmojiPicker() {
    this.emojiPickerModalOpen = false;
  }

  private async addEmoji(emoji: string) {
    this.addNewEmoji(emoji);
    await this.reactionService.createOrUpdateReactionDoc(this.item.doc._id, emoji);
    await this.reloadReactions();
  }

  showEmojiPicker() {
    this.emojiPickerModalOpen = true;
  }

  private async reloadReactions() {
    const emojis = await this.reactionService.getReactions(this.item.doc._id);
    this.emojis.clear();
    emojis.forEach(emoji => this.addNewEmoji(emoji));
  }

  private addNewEmoji(emoji: string) {
    if (!this.emojis.has(emoji)) {
      this.emojis.set(emoji, 0);
    }
    this.emojis.set(emoji, this.emojis.get(emoji) + 1);
  }

  private initEmojis() {
    this.emojis.clear();
    if (this.item?.emojisByAuthor) {
      this.item.emojisByAuthor.forEach(emoji => {
        this.addNewEmoji(emoji);
      });
    }
  }
}
