import { Component, OnInit } from '@angular/core';
import { FeedItemService } from '../../services/feed-item.service';
import { FeedItem } from '../../model/feed-item';
import { BBStorage } from '../../utils/bb-storage';
import { GymService } from '../../services/gym.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { FeedItemType } from '../../model/feed-item-type';
import { first } from 'cypress/types/lodash';
import { firstValueFrom } from 'rxjs';
import { ActiveGymService } from 'src/services/active-gym.service';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

const INFINITE_SCROLL_STEP = 15;

@Component({
  selector: 'app-feed',
  templateUrl: './feed.page.html',
  styleUrls: ['./feed.page.scss'],
})
export class FeedPage implements OnInit {


  constructor(
    private matomoTracker: MatomoTracker,
    public bbStorage: BBStorage,
    private activeGymFacade: ActiveGymFacade,
    private gymService: GymService,
    private feedItemService: FeedItemService) {
  }

  visibleItems: FeedItem<any>[];
  allItems: FeedItem<any>[];
  allFilteredItems: FeedItem<any>[];
  private latestFeedItemDate: Date = null;
  filter: string[] = [];

  ngOnInit() {
  }

  private async reloadData() {
    this.visibleItems = null;
    this.loadFilter();
    const activeGymId = await firstValueFrom(this.activeGymFacade.activeGymId$);
    this.latestFeedItemDate = this.bbStorage.getLatestDocShownInFeedTimeCreatedForGym(activeGymId);
    await this.gymService.syncActiveGym();
    await this.feedItemService.fetchNewDocsAndUpdateFeedItems();
    this.allItems = await this.feedItemService.getSortedFeedItems();
    this.updateFilteredItems();
    this.updateVisibleItems();
    this.bbStorage.setLatestDocShownInFeedTimeCreated(new Date(), activeGymId);
    this.feedItemService.resetUnread();
  }

  private updateVisibleItems() {
    this.visibleItems = this.allFilteredItems.slice(0, INFINITE_SCROLL_STEP);
  }

  private updateFilteredItems() {
    this.allFilteredItems = this.allItems.filter(item => this.bbStorage.feedFilter.indexOf(item.type) > -1);
  }

  loadMore($event: any) {
    setTimeout(() => {
      $event.target.complete();
      this.visibleItems = this.visibleItems?.concat(
        this.allFilteredItems.slice(this.visibleItems.length, this.visibleItems.length + INFINITE_SCROLL_STEP));
    }, 500);
  }

  async onRefresh(event: any) {
    try {
      await this.reloadData();
    } finally {
      if (event) {
        event.target.complete();
      }
    }
  }

  ionViewWillEnter() {
    this.reloadData();
    this.matomoTracker.trackPageView('feed');
  }

  isNew(dateOfFeedItem: Date) {
    return this.latestFeedItemDate < dateOfFeedItem;
  }

  async filterChanged($event: any) {
    if ($event?.detail?.value) {
      this.updateFilter($event.detail.value)
    }
  }


  private async loadFilter() {
    this.filter = [];
    if (this.bbStorage.feedFilter.length === 0) {
      await this.bbStorage.setFeedFilter([FeedItemType.NEW_BOULDER, FeedItemType.UP_VOTE, FeedItemType.ASCENT, FeedItemType.NEW_BOULDER_WITH_ASCENT, FeedItemType.DOWN_VOTE, FeedItemType.COMMENT]);
    }
    this.bbStorage.feedFilter.forEach(filterItem => {
      switch (filterItem) {
        case FeedItemType.DOWN_VOTE:
          this.filter.push('DOWN_VOTE');
          break;
        case FeedItemType.UP_VOTE:
          this.filter.push('UP_VOTE');
          break;
        case FeedItemType.COMMENT:
          this.filter.push('COMMENT');
          break;
        case FeedItemType.ASCENT:
          this.filter.push('ASCENT');
          break;
        case FeedItemType.NEW_BOULDER_WITH_ASCENT:
        case FeedItemType.NEW_BOULDER:
          this.filter.push('NEW_BOULDER');
          break;
      }
    });
    // remove duplicates
    this.filter = this.filter.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

  private async updateFilter(selectedItems) {
    let newFilter: FeedItemType[] = [];
    selectedItems.forEach(item => {
      switch (item) {
        case 'NEW_BOULDER':
          newFilter.push(FeedItemType.NEW_BOULDER_WITH_ASCENT, FeedItemType.NEW_BOULDER);
          break;
        case 'ASCENT':
          newFilter.push(FeedItemType.ASCENT);
          break;
        case 'COMMENT':
          newFilter.push(FeedItemType.COMMENT);
          break;
        case 'UP_VOTE':
          newFilter.push(FeedItemType.UP_VOTE);
          break;
        case 'DOWN_VOTE':
          newFilter.push(FeedItemType.DOWN_VOTE);
          break;
      }
    });
    newFilter = newFilter.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    await this.bbStorage.setFeedFilter(newFilter);
    this.updateFilteredItems();
    this.updateVisibleItems();

  }
}
