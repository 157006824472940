import {BBEnv} from '../environments/bb-env';

const serverUrl = BBEnv.getAppServerUrl();

export class RestEndpoints {
  public static VERSION = serverUrl + 'version';
  public static COUCH_SCHEMA = serverUrl + 'schema/couch_schema.json';
  public static REST_SCHEMA = serverUrl + 'schema/rest_schema.json';
  public static TYPE_DEFINITIONS = serverUrl + 'schema/type_definitions.json';
  public static REGISTER = serverUrl + 'register';
  public static DELETE_ACCOUNT = serverUrl + 'delete-account';
  public static LOGIN = serverUrl + 'login';
  public static USERNAME_IS_FREE = serverUrl + 'username-is-free';
  public static EMAIL_IS_USED = serverUrl + 'email-is-used';
  public static USER_PROFILE = serverUrl + 'user-profile';
  public static PRIVATE = serverUrl + 'private';
  public static WALL_IMAGE = serverUrl + 'wall-image';
  public static NEW_GYM_V2 = serverUrl + 'gymv2';
  public static RESET_PASSWORD_EMAIL = serverUrl + 'reset-password';
  public static SEND_FEEDBACK = serverUrl + 'send-feedback';
}
