import {GymDBDoc} from '../common/docs';
import {GymDBDocType} from '../common/doc-types';
import {Observable} from 'rxjs';
import {DBQueryResult} from '../common/db-query-result';

export abstract class DocQueryService {

  abstract querySingleDocOfType$<T extends GymDBDoc>(query: any, docType: GymDBDocType): Observable<T>;

  abstract queryDocsOfType$<T extends GymDBDoc>(query: any, docType: GymDBDocType): Observable<T[]> ;

  abstract getDocsOfType$<T extends GymDBDoc>(docType: GymDBDocType): Observable<T[]>;

  abstract getAllDocs(): Observable<DBQueryResult>;

  abstract getDocOfTypeWithId$<T extends GymDBDoc>(docType: GymDBDocType, docId: string): Observable<T>;

  abstract getNewOrChangedDocs$(sequence: number): Observable<GymDBDoc[]>;

  abstract getDeletedDocIds$(sequence: number): Observable<string[]>;

  abstract queryDocs$<T extends GymDBDoc>(query: any): Observable<T[]>;

}
