import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FourByFourPage } from './four-by-four.page';

const routes: Routes = [
  {
    path: '',
    component: FourByFourPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FourByFourPageRoutingModule {
}
