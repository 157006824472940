
export class RankingEntry {
  constructor(
    readonly user: string,
    private _rank: number,
    readonly score: number
  ) {

  }

  get rank(): number {
    return this._rank;
  }

  set rank(value: number) {
    this._rank = value;
  }
}
