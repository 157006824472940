export enum TaskEventType {
  START = 'start',
  FINISH = 'finish'
}

export enum TaskType {
  EXERCISE = 'exercise',
  BATCH = 'batch',
  HANG = 'hang',
  PREP = 'prep',
  WORK = 'work',
  REST = 'rest'
}

/**
 * Event that is emitted for every tick. Provides data about the current state of the task.
 */
export class TickEvent {
  /**
   *
   * @param time - total elapsed time of the current task in ms
   * @param remainingTime - total remaining time of the current task in ms
   * @param task - 'exercise', 'batch' or 'hang'
   * @param taskPhase - 'prep', 'work' or 'rest'
   */
  constructor(public time: number, public remainingTime: number, public task: TaskType, public taskPhase: TaskType) {}
}

/**
 * Event that is emitted whenever the task is updated. Provides data about the current state of the entire exercise.
 */
export class TaskEvent {
  /**
   * @param task - 'exercise', 'batch', 'hang', 'work' or 'rest'
   * @param type - 'start' or 'finished'
   * @param exerciseIndex - index of current exercise
   * @param batchIndex - index of current batch
   * @param hangIndex- index of current hang
   */
  constructor(
    public task: TaskType,
    public type: TaskEventType,
    public exerciseIndex: number,
    public batchIndex: number,
    public hangIndex: number
  ) {}
}
