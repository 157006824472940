import {Injectable} from '@angular/core';
import {createExercise, createTasks, Scheduler, TaskEvent, TaskEventType, TaskType, TickEvent} from './timer';
import {NGXLogger} from 'ngx-logger';
import {Mp3Service} from './mp3.service';
import {UuidUtils} from '../utils/uuid.utils';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private scheduler: Scheduler;
  private currentWorkout: Workout;

  private _tickEvent: TickEvent = new TickEvent(0, 0, TaskType.PREP, TaskType.PREP);
  private _taskEvent: TaskEvent = new TaskEvent(TaskType.PREP, TaskEventType.START, 0, 0, 0);
  private timerState: TimerState = TimerState.PAUSE;

  constructor(
    private logger: NGXLogger,
    private mp3Service: Mp3Service
  ) {
    this.reset();
  }

  public setWorkout(workout: Workout) {
    this.currentWorkout = workout;
    this.scheduler = this.createScheduler(this.currentWorkout.timerConfig);
  }

  public start() {
    this.scheduler?.start();
  }

  public reset() {
    this.scheduler?.stop();
    this.scheduler?.reset();
  }

  public isRunning(): boolean {
    return this.scheduler?.running;
  }


  public pause() {
    this.scheduler?.stop();
  }

  public createScheduler(timerConfig: TimerConfig): Scheduler {
    const tasks = createTasks(
      [
        createExercise(
          {
            prepare: 1000 * timerConfig.durationPrepare,
            numBatches: timerConfig.numSets,
            rest: 1000 * timerConfig.durationRest
          },
          {
            prepare: 0,
            numHangs: timerConfig.numReps,
            rest: 1000 * timerConfig.durationPause
          },
          {
            prepare: 0,
            work: 1000 * timerConfig.durationHang,
            rest: 1000 * timerConfig.durationRest
          }
        )
      ],
      (tickEvent) => {
        this.handleTickEvent(tickEvent);
      },
      (taskEvent) => {
        this.handleTaskEvent(taskEvent);
      }
    );
    return new Scheduler(tasks, 1000);
  }

  private handleTickEvent(tickEvent: TickEvent) {
    this._tickEvent = tickEvent;
    this.checkTick(TimerState.FIFTEEN_SECONDS, tickEvent, 15);
    this.checkTick(TimerState.FIVE_SECONDS, tickEvent, 5);
    this.checkTick(TimerState.THREE, tickEvent, 3);
    this.checkTick(TimerState.TWO, tickEvent, 2);
    this.checkTick(TimerState.ONE, tickEvent, 1);
  }

  private checkTick(timerState: TimerState, tickEvent: TickEvent, seconds: number) {
    if (this.timerState !== timerState && tickEvent.remainingTime === seconds * 1000) {
      this.playSound(timerState);
    }
  }

  private handleTaskEvent(taskEvent: TaskEvent) {
    this._taskEvent = taskEvent;
    if (taskEvent.type === TaskEventType.START) {
      switch (taskEvent.task) {
        case TaskType.HANG:
          this.playSound(TimerState.HANG);
          break;
        case TaskType.REST:
          if (this.currentWorkout.timerConfig.durationRest > 0) {
            this.playSound(TimerState.REST);
          }
          break;
      }
    } else if (taskEvent.type === TaskEventType.FINISH) {
      switch (taskEvent.task) {
        case TaskType.WORK:
          if (taskEvent.hangIndex === this.currentWorkout.timerConfig.numReps - 1) {
            this.playSound(TimerState.SET_COMPLETED);
          } else if (taskEvent.batchIndex === this.currentWorkout.timerConfig.numSets - 1) {
            this.playSound(TimerState.WORKOUT_COMPLETED);
          }
          break;
      }
    }
  }

  get taskEvent(): TaskEvent {
    return this._taskEvent;
  }

  get tickEvent(): TickEvent {
    return this._tickEvent;
  }

  private playSound(timerState: TimerState) {
    this.timerState = timerState;
    switch (timerState) {
      case TimerState.FIFTEEN_SECONDS:
        this.mp3Service.play('fifteen_seconds_remaining.mp3');
        break;
      case TimerState.FIVE_SECONDS:
        this.mp3Service.play( 'fiveseconds.mp3');
        break;
      case TimerState.THREE:
        this.mp3Service.play( 'three.mp3');
        break;
      case TimerState.TWO:
        this.mp3Service.play('two.mp3');
        break;
      case TimerState.ONE:
        this.mp3Service.play( 'one.mp3');
        break;
      case TimerState.HANG:
        this.mp3Service.play( 'go.mp3', false);
        break;
      case TimerState.REST:
        this.mp3Service.play('rest.mp3', false);
        break;
      case TimerState.WORKOUT_COMPLETED:
        this.mp3Service.play( 'workout_completed.mp3');
        break;
      case TimerState.SET_COMPLETED:
        this.mp3Service.play( 'set_completed.mp3');
        break;
    }
  }

  public static totalTime(timerConfig: TimerConfig): number {
    return timerConfig.durationPrepare + (((timerConfig.durationHang + timerConfig.durationRest) * timerConfig.numReps) + timerConfig.durationPause) * timerConfig.numSets - timerConfig.durationPause;
  }

}
export enum TimerState {
  FIFTEEN_SECONDS = 0, FIVE_SECONDS, THREE, TWO, ONE, HANG, REST, PAUSE, SET_COMPLETED, WORKOUT_COMPLETED
}

export class TimerConfig {
  constructor(
    readonly durationPrepare: number,
    readonly durationHang: number,
    readonly durationRest: number,
    readonly durationPause: number,
    readonly numReps: number,
    readonly numSets: number) {
  }
}
export class Workout {
  constructor(
    readonly motivator: Motivator,
    readonly name: string,
    readonly description: string,
    readonly timerConfig: TimerConfig,
    readonly uuid = UuidUtils.createUUID(10)) {
  }
}

export class Motivator {
  constructor(readonly id, readonly name, readonly pictureUrl) {
  }
}
