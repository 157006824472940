<ion-header>
  <ion-toolbar>
    <ion-title>{{"walls-page.walls" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" fill="clear" data-cy="close-button">
        {{"walls-page.done" | translate}}
        <ion-icon name="checkmark" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p>{{"walls-page.info-one" | translate}}</p>
  <ion-item (click)="reset()" *ngIf="(walls$ | async)?.length>0">
    <ion-button slot="start" size="medium" fill="clear">
      {{"settings-page.reset" | translate}}
      <ion-icon name="hammer" slot="start"></ion-icon>
    </ion-button>
  </ion-item>
  <p>{{"walls-page.info-two" | translate}}</p>
  <edit-wall-card *ngFor="let wall of walls$ | async" [wall]="wall" (onWallRemoved)="wallWasRemoved()"></edit-wall-card>
  <ion-card>
    <ion-card-content>
      <ion-card-title *ngIf="(walls$|async)?.length==0">
        {{"walls-page.add-new-wall" | translate}}
      </ion-card-title>
      <ion-card-title *ngIf="(walls$|async)?.length>0">
        {{"walls-page.wall-missing" | translate}}
      </ion-card-title>
      <br>
      <app-create-wall (onWallAdded)="wallAdded($event)"></app-create-wall>
    </ion-card-content>
  </ion-card>
</ion-content>