import { Injectable } from '@angular/core';
import { User } from 'src/model/user';
import { RestService } from './rest.service';


@Injectable()
export class UserService {

    private userByUserName: Map<string, User> = new Map<string, User>();

    constructor(
        private restService: RestService) {
    }

    public async getUserByUsername(username: string): Promise<User> {
        if (this.userByUserName.has(username)) {
            return this.userByUserName.get(username);
        }
        const userProfile = await this.restService.getUserProfile(username);
        const user = new User(username, userProfile?.supporterType, userProfile?.instagramUsername, userProfile?.aboutMe);
        this.userByUserName.set(username, user);
        return user;

    }

}
