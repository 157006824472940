import { GymDBDoc } from '../common/docs';
import { FeedItemType } from './feed-item-type';

export class FeedItem<T extends GymDBDoc> {
  readonly type: FeedItemType;
  readonly boulderId: string;
  readonly boulderName: string;
  readonly highlight: boolean;
  readonly grade: number;
  readonly date: Date;
  readonly doc: T;
  readonly emojisByAuthor: Map<string, string>;
  readonly author: string;

  constructor(type: FeedItemType, boulderId: string, boulderName: string, highlight: boolean, grade: number, date: Date, doc: T, author: string) {
    this.type = type;
    this.boulderId = boulderId;
    this.boulderName = boulderName;
    this.highlight = highlight;
    this.grade = grade;
    this.date = date;
    this.doc = doc;
    this.emojisByAuthor = new Map();
    this.author = author;
  }

}
