import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Wall } from '../../model/wall';
import { ImageService } from '../../services/image.service';
import { WallImage } from '../../model/wall-image';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { EditWallCurrentnessComponent } from '../edit-wall-currentness/edit-wall-currentness.component';
import { WallService } from '../../services/wall.service';
import { ValidationService } from '../../services/validation.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { WallImagesComponent } from '../wall-images/wall-images.component';


@Component({
  selector: 'edit-wall-card',
  templateUrl: './edit-wall-card.component.html',
  styleUrls: ['./edit-wall-card.component.scss'],
})
export class EditWallCardComponent implements OnInit {

  @Output() onWallRemoved: EventEmitter<any> = new EventEmitter();
  imageUrl: any;
  wallName: string = 'keine';
  activeWallImage: WallImage;
  _wall: Wall;
  activeWallImageIsCurrent: boolean = true;

  isActionSheetOpen = false;
  public actionSheetButtons = [
    {
      text: this.translate.instant('edit-wall-card-component.delete'),
      handler: () => {
        this.isActionSheetOpen = false;
        this.removeWall();
      }
    },
    {
      text: this.translate.instant('edit-wall-card-component.change-name'),
      handler: async () => {
        this.isActionSheetOpen = false;
        const alert = await this.alertController.create({
          header: this.translate.instant('edit-wall-card-component.change-name'),
          buttons: [{
            text: this.translate.instant('shared.ok'),
            handler: async (data) => {
              const newName = data[0];
              if (!this.validationService.validateShortHumanReadableName(newName)) {
                const toast = await this.toastController.create({
                  message: this.translate.instant('edit-wall-card-component.invalid-name'),
                  duration: 1500,
                  color: 'danger',
                  position: 'middle',
                });
                await toast.present();
              } else {
                this.saveWallName(newName);
              }
            }
          },
          { text: this.translate.instant('shared.cancel'), role: 'cancel' }],
          inputs: [
            {
              placeholder: this.translate.instant('edit-wall-card-component.choose-new-name'),
              attributes: {
                minLength: 3,
                maxlength: 30,
              },
            }
          ]
        });

        await alert.present();
      }
    },
    {
      text: this.translate.instant('edit-wall-card-component.show-all-photos'),
      handler: () => {
        this.isActionSheetOpen = false;
        this.showWallImages();
      }
    },
    {
      text: this.translate.instant('edit-wall-card-component.upload-new-photo'),
      handler: () => {
        this.isActionSheetOpen = false;
        this.uploadPhoto();
      }
    },
    {
      text: this.translate.instant('shared.cancel'),
      role: 'cancel',
      handler: () => {
        this.isActionSheetOpen = false;
      }
    },
  ];

  constructor(
    private errorNotificationService: ErrorNotificationService,
    private toastController: ToastController,
    private alertController: AlertController,
    private validationService: ValidationService,
    private modalController: ModalController,
    private wallService: WallService,
    private imageService: ImageService,
    private translate: TranslateService) {
  }

  ngOnInit() {

  }

  @Input('wall')
  set wall(wall: Wall) {
    this._wall = wall;
    this.wallName = wall.wallName;
    if (wall.wallImages.length > 0) {
      this.showWallImage(wall.wallImages[0]);
    }
  }

  private async showWallImages() {
    const modal = await this.modalController.create({
      component: WallImagesComponent,
      componentProps: { 'wall': this._wall }
    }
    );
    modal.onDidDismiss().then(data => {

    });
    await modal.present();
  }

  private showWallImage(wallImage: WallImage) {
    this.activeWallImage = wallImage;
    this.activeWallImageIsCurrent = this.activeWallImage.currentness == 'current';
    this.imageService.wallImagePath(this.activeWallImage).then(url => {
      this.imageUrl = url;
    });
  }

  wallImageAdded(wallImage: WallImage) {
    this._wall.wallImages[0] = wallImage;
    this.showWallImage(wallImage);
  }

  private async uploadPhoto() {
    const modal = await this.modalController.create({
      component: EditWallCurrentnessComponent,
      componentProps: { 'wall': this._wall }
    }
    );
    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.wallImageAdded(data.data);
      }
    });
    await modal.present();
  }

  async removeWall() {
    const alert = await this.alertController.create({
      header: this.translate.instant('edit-wall-card-component.remove-wall-header'),
      buttons: [
        {
          text: this.translate.instant('edit-wall-card-component.do-not'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('edit-wall-card-component.please-remove'),
          handler: async () => {
            await this.wallService.deleteWall(this._wall.wallId);
            this.onWallRemoved.emit();
          }
        }
      ]
    });
    await alert.present();
  }

  async saveWallName(newWallName: string) {
    try {
      await this.wallService.updateWallName(this._wall.wallId, newWallName);
    } catch (error) {
      await this.errorNotificationService.showBBError(error);
    }
    this.wallName = newWallName;
  }
}
