import {BoulderListEntry} from './boulder-list-entry';

export class BoulderFilter {
  constructor(
    readonly ascentFilterType: AscentFilterType,
    readonly gradeMin: number,
    readonly gradeMax: number,
    readonly wallNames: string[],
    readonly onlyCampusBoulders: boolean = false,
    readonly noCampusBoulders: boolean = false,
  ) {
  }

  static isEmpty(filter: BoulderFilter): boolean {
    return !filter.onlyCampusBoulders
      && !filter.noCampusBoulders
      && filter.ascentFilterType == 'all'
      && filter.gradeMax === null
      && filter.gradeMin === null
      && filter.wallNames?.length === 0;
  }

  static empty(): BoulderFilter {
    return new BoulderFilter('all', null, null, []);
  }

  static passesFilter(boulder: BoulderListEntry, filter: BoulderFilter) {
    if (!filter) {
      return true;
    }
    if (filter.onlyCampusBoulders && !boulder.isCampus) {
      return false;
    }
    if (filter.noCampusBoulders && boulder.isCampus) {
      return false;
    }
    if (filter.ascentFilterType === 'only-sent-by-user' && !boulder.ticked) {
      return false;
    }
    if (filter.ascentFilterType === 'only-not-sent-by-user' && boulder.ticked) {
      return false;
    }
    if (filter.ascentFilterType === 'only-projects' && !(boulder.numAscents === 0)) {
      return false;
    }
    if ((filter.gradeMin && filter.gradeMin > boulder.grade) || (filter.gradeMax && filter.gradeMax < boulder.grade)) {
      return false;
    }
    return !(filter?.wallNames?.length > 0 && filter.wallNames.indexOf(boulder.wallName) < 0);

  }
}

export type AscentFilterType = 'all' | 'only-projects' | 'only-sent-by-user' | 'only-not-sent-by-user';

export type SortType =
  'date'
  | 'num-ascents-ascending'
  | 'num-ascents-descending'
  | 'grade-descending'
  | 'grade-ascending'
  | 'rating-descending';
