<ion-chip *ngIf="_footholdConfig.allHoldsAllowed">{{"foothold-details-component.all-holds-allowed" | translate}}</ion-chip>
<span *ngIf="!_footholdConfig.allHoldsAllowed">
  <ion-chip *ngIf="isCampus" class="campus-chip">
   <ion-avatar>
    <img src="assets/imgs/biceps.png">
  </ion-avatar>
    <ion-label>{{"foothold-details-component.campus" | translate}}</ion-label>
  </ion-chip>
  <ion-chip
    *ngIf="_footholdConfig.withHandholds">{{"foothold-details-component.handholds-allowed" | translate}}</ion-chip>
  <ion-chip *ngIf="_footholdConfig.withSpax">{{"foothold-details-component.spax-allowed" | translate}}</ion-chip>
  <ion-chip *ngIf="_footholdConfig.withSmallFootholds">{{"foothold-details-component.small-footholds-allowed" | translate}}</ion-chip>
  <ion-chip *ngIf="hasMarkedFootholds"><ion-icon color="secondary" src="assets/icons/foothold-circle.svg"></ion-icon>{{"foothold-details-component.marked-footholds" | translate}}</ion-chip>
  <ion-chip *ngFor="let color of allowedColors" [style.color]="color.id">
      {{"colors-plural." + color.id | translate}} {{"foothold-details-component.footholds" | translate}}
  </ion-chip>
</span>
