import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PipesModule } from 'src/pipes/pipes.module';
import { RestService } from 'src/services/rest.service';

@Component({
  standalone: true,
  selector: 'add-gym-admin-chip',
  imports: [IonicModule, FormsModule, CommonModule, PipesModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
  <ion-chip *ngIf="!isEditing" (click)="isEditing=true" data-cy="add-gym-admin-button">
    <ion-icon name="add-outline"></ion-icon>
    <ion-label>{{"add-gym-admin-chip.add-gym-admin" | translate}}</ion-label>
  </ion-chip>
  <ion-chip *ngIf="isEditing">
    <ion-input data-cy="new-gym-admin-input" [value]="username" (ionInput)="checkUsername($event)" class="username" [helperText]="helperText" [placeholder]="('add-gym-admin-chip.enter-username' | translate)"></ion-input>
    <ion-button data-cy="add-button" shape="round" *ngIf="isValidUserNname" (click)="add()">
      <ion-icon name="add-outline"></ion-icon>
      {{"add-gym-admin-chip.add" | translate}}
    </ion-button>
  </ion-chip>
  `,
  styleUrls: ['./add-gym-admin-chip.component.scss'],
})
export class AddGymAdminChipComponent implements OnInit {

  @Output() onGymAdminAdded: EventEmitter<string> = new EventEmitter();

  public isEditing = false;
  public username = '';
  public helperText = null;
  public isValidUserNname = false;

  constructor(
    private translate: TranslateService,
    private restService: RestService) {
  }

  ngOnInit() {
  }

  public add() {
    this.onGymAdminAdded.emit(this.username);
    this.isEditing = false;
    this.username = ''
  }

  public async checkUsername($event) {
    this.username = $event.detail.value;
    if (await this.restService.usernameIsFree(this.username)) {
      this.helperText = this.translate.instant('add-gym-admin-chip.helper-text');
      this.isValidUserNname = false;
    } else {
      this.helperText = null;
      if (this.username.length > 0) {
        this.isValidUserNname = true;
      } else {
        this.isValidUserNname = false;
      }
    }
  }


}
