import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {BoulderComment} from '../model/boulder-comment';
import {DocsCreator} from '../common/docs-creator';
import {Observable} from 'rxjs';
import {CommentDoc} from '../common/docs';
import {GymDBDocType} from '../common/doc-types';
import {map} from 'rxjs/operators';
import {GymService} from './gym.service';
import {DocWriteService} from './doc.write.service';
import {DocQueryService} from './doc.query.service';

@Injectable()
export class CommentService {

  constructor(
    private docWriteService : DocWriteService,
    private docQueryService : DocQueryService,
    private gymService: GymService,
    private authService: AuthService) {
  }

  public async addComment(boulderId: string, comment: string) {
    await this.docWriteService.addDocument(DocsCreator.createComment(
      boulderId, comment, this.authService.getCurrentUsername()
    ));
    await this.gymService.syncActiveGym();
  }

  public async deleteComment(commentId: string) {
    await this.docWriteService.removeDocOfTypeWithId(GymDBDocType.COMMENT, commentId);
    await this.gymService.syncActiveGym();
  }

  public getComments$(boulderId: string): Observable<BoulderComment[]> {
    return this.docQueryService.getDocsOfType$<CommentDoc>(GymDBDocType.COMMENT)
      .pipe(
        map(commendDocs => {
          const comments = [];
          for (const commendDoc of commendDocs) {
            if (commendDoc.boulderId === boulderId) {
              const date = new Date(commendDoc.timeCreated);
              comments.push(new BoulderComment(date, commendDoc.comment, commendDoc.author, commendDoc._id));
            }
          }
          return comments;
        })
      );
  }

}
