<div *ngIf="wall" class="wrapper">
  <p *ngIf="!imageChosen()" [innerHTML]='"upload-wall-image-component.instruction"|translate
  :{wallName:wall.wallName}'></p>
  <ion-button (click)="chooseImageFileWithCamera()" *ngIf="capacitor" expand="full">
    <ion-icon slot="start" name="images"></ion-icon>
    {{"upload-wall-image-component.choose-photo" | translate}}
  </ion-button>
  <ion-item *ngIf="!capacitor">
    <input data-cy="choose-image" (change)="chooseLocalImageFile($event)" type="file">
  </ion-item>
  <ion-button (click)="uploadWallImage()" *ngIf="imageChosen()" class="primary-button" expand="full"
              data-cy="upload-image">
    <ion-icon slot="start" name="cloud-upload"></ion-icon>
    {{"upload-wall-image-component.upload" | translate}}
  </ion-button>
  <img *ngIf="chosenFileSrc" [src]="chosenFileSrc"/>
</div>
