import { Batch } from './Batch';

class Exercise {
  private _prep: number;
  private _batches: Batch[];
  private _rest: number;

  constructor(prep: number, batches: Batch[], rest: number) {
    this._prep = prep;
    this._batches = batches;
    this._rest = rest;
  }

  get prep(): number {
    return this._prep;
  }

  get batches(): Batch[] {
    return this._batches;
  }

  get rest(): number {
    return this._rest;
  }
}

export { Exercise };
