/**
 * A timer utility to execute some task repetitively after a fixed time interval. The timer
 * is more reliable than the browser's standard setInterval method because for each interval
 * it corrects the time drift of the previous one. setInterval and setTimeout actually show
 * such a drift even when running for only a few seconds.
 *
 * I looked into some existing timers, but none of them seemed like the perfect fit:
 * - https://github.com/TimothyGu/nanotimer (might be worth a second look if need be)
 * - https://github.com/Krb686/nanotimer (not sure which one is the real nanotimer)
 * - https://www.youtube.com/watch?v=x8PBWobv6NY (nice explanation of the matter)
 * - https://github.com/albert-gonzalez/easytimer.js
 * - https://onury.io/tasktimer/ (seemed very nice at first but quickly ran into #12 and #15)
 */
export class Timer {
  private _onTick;
  private _interval: number;
  private _ticks = 0;
  private _expectedTime: number | undefined = undefined;
  private _remainingTime: number | undefined = undefined;
  private _timeOut: any | undefined = undefined;
  private _stopped = false;
  private _running = false;

  /**
   * @param - Fixed time interval of this timer in ms
   * @param - Callback that is executed each time an interval is complete
   */
  constructor(interval: number, onTick: (tick: number) => void) {
    this._onTick = onTick;
    this._interval = interval;
  }

  start(): void {
    if (this._running) return;
    this._stopped = false;
    this._running = true;
    this._run();
  }

  stop(): void {
    if (this._timeOut !== undefined) {
      clearTimeout(this._timeOut);
      this._timeOut = undefined;
      this._remainingTime = this._expectedTime === undefined ? 0 : (this._expectedTime - Date.now());
    }
    this._expectedTime = undefined;
    this._stopped = true;
    this._running = false;
  }

  reset(): void {
    this.stop();
    this._remainingTime = undefined;
    this._ticks = 0;
    this._stopped = false;
    this._running = false;
  }

  private _run(): void {
    // did not understand this entirely, but in some cases the timer starts again without this check even though it was stopped
    if (this._stopped) {
      return;
    }
    // in case there was some remaining time in the interval that ran when we stopped we need to finish that interval first
    if (this._remainingTime !== undefined) {
      this._expectedTime = Date.now() + this._remainingTime;
      this._timeOut = setTimeout(() => {
        this._run();
      }, this._remainingTime);
      this._remainingTime = undefined;
      return;
    }
    this._onTick(this._ticks);
    this._ticks++;
    const now = Date.now();
    const drift = this._expectedTime === undefined ? 0 : (now - this._expectedTime);
    const adjustedInterval = this._interval - drift;
    this._expectedTime = now + adjustedInterval;
    this._timeOut = setTimeout(() => {
      this._run();
    }, adjustedInterval);
  }
}
