import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {DocsCreator} from '../common/docs-creator';
import {firstValueFrom, Observable} from 'rxjs';
import {QualityRatingDoc} from '../common/docs';
import {GymDBDocType} from '../common/doc-types';
import {BoulderQuality} from '../model/boulder-quality';
import {map} from 'rxjs/operators';
import {DocWriteService} from './doc.write.service';
import {DocQueryService} from './doc.query.service';

@Injectable()
export class QualityRatingService {

  constructor(
    private docWriteService : DocWriteService,
    private docQueryService : DocQueryService,
              private authService: AuthService) {
  }

  public static createBoulderQuality(docs: QualityRatingDoc[]): BoulderQuality {
    const counts = [0, 0, 0];
    docs.forEach(r => {
      // down = -1, up = +1, neutral = 0, shift by one to get valid array index
      counts[r.quality + 1]++;
    });
    return new BoulderQuality(counts[2], counts[0], counts[1]);
  }

  async setThumbUp(boulderId: string) {
    await this.createOrUpdateQualityRating(boulderId, +1);
  }

  async setThumbDown(boulderId: string) {
    await this.createOrUpdateQualityRating(boulderId, -1);
  }

  async removeRating(boulderId: string) {
    const rating = await firstValueFrom(this.getRatingDoc$(boulderId));
    if (rating) {
      await this.docWriteService.removeDocument(rating);
    }
  }

  getRating$(boulderId: string): Observable<QualityRatingDoc> {
    return this.getRatingDoc$(boulderId);
  }

  private async createOrUpdateQualityRating(boulderId: string, quality: number) {
    const rating = await firstValueFrom(this.getRatingDoc$(boulderId));
    if (!rating) {
      await this.docWriteService.addDocument(DocsCreator.createQualityRating(
        boulderId, quality, this.authService.getCurrentUsername()
      ));
    } else if (rating.quality !== quality) {
      (rating as any).quality = quality;
      await this.docWriteService.updateDocument(rating);
    }
  }

  private getRatingDoc$(boulderId: string): Observable<QualityRatingDoc> {
    return this.docQueryService.getDocsOfType$<QualityRatingDoc>(GymDBDocType.QUALITY_RATING).pipe(
      map(docs => {
        return docs.filter(d => d.boulderId == boulderId && d.author == this.authService.getCurrentUsername())[0];
      })
    );
  }
}
