import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as PouchDB from 'pouchdb';
import Database = PouchDB.Database;
import { PouchDBError } from '../errors/bb-error';

export class PouchUtils {
  static prettyPrintString(obj: any): string {
    return JSON.stringify(obj, null, 4);
  }

  static getAllDocsWithPrefix<T>(db: Database, docPrefix: string): Observable<T[]> {
    if (!db) {
      return of([]);
    }
    return from(db.allDocs({
      include_docs: true,
      startkey: docPrefix,
      endkey: docPrefix + '\ufff0'
    }))
      .pipe(
        map(o => o.rows.map(r => {
          return ((r.doc as any) as T);
        }))
      );
  }

  static getDocById<T>(db: Database, docId: string): Observable<T> {
    return from(db.get(docId)).pipe(
      map(doc => (doc as any) as T),
      catchError(err => throwError(new PouchDBError(err)))
    );
  }

}
