export enum GymDBDocType {
  ASCENT = 'ascent',
  BOULDER = 'boulder',
  COMMENT = 'comment',
  REACTION = 'reaction',
  DIFFICULTY_RATING = 'difficulty_rating',
  GYM_INFO = 'gym_info',
  QUALITY_RATING = 'quality_rating',
  WALL = 'wall',
  WALL_IMAGE = 'wall_image',
  BOULDER_LIST = 'boulder_list',
  GYM_CHAT_MESSAGE = 'gym_chat_message'
}
