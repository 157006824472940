import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {IonicModule, ModalController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'help',
  imports: [
    TranslateModule,
    IonicModule
  ],
  template:
    `
      <ion-content class="ion-padding">
        <ion-fab slot="fixed" horizontal="end" vertical="top" class="close-button">
          <ion-fab-button translucent="true" data-cy="close-help" (click)="dismiss()">
            <ion-icon name="close"></ion-icon>
          </ion-fab-button>
        </ion-fab>
        <h1>{{"create-boulder-page.mark-holds" | translate}}</h1>
        <p>{{"create-boulder-page.press-hold" | translate}}</p>
        <p>{{"create-boulder-page.hold-type" | translate}}</p>
        <p>
          <ion-icon src="assets/icons/start-holds.svg"></ion-icon>
          {{"create-boulder-page.match-starthold" | translate}}<br>
          <ion-icon src="assets/icons/start-hold.svg"></ion-icon>
          {{"create-boulder-page.one-hand-starthold" | translate}}<br>
          <ion-icon src="assets/icons/foothold.svg"></ion-icon>
          {{"create-boulder-page.foothold" | translate}}
          <ion-icon src="assets/icons/hold.svg"></ion-icon>
          {{"create-boulder-page.hold" | translate}}
          <ion-icon name="flag"></ion-icon>
          {{"create-boulder-page.tophold" | translate}}
        </p>
        <p>{{"create-boulder-page.press-to-freeze" | translate}}</p>
        <i>{{"create-boulder-page.one-starthold" | translate}}</i>
        <ion-icon src="assets/icons/start-holds.svg"></ion-icon>
        {{"create-boulder-page.or-two-startholds" | translate}}
        <ion-icon src="assets/icons/start-hold.svg"></ion-icon>
        {{"create-boulder-page.must-be-marked" | translate}}
        <ion-icon name="flag"></ion-icon>
        <i>{{"create-boulder-page.chosen" | translate}}</i>
      </ion-content>`,
  styles: [`
      ion-content {
          --padding-top: 70px;
          --padding-start: 40px;
          --padding-end: 40px;
      }

      ion-fab-button {
          --background: var(--pink);
          --background-activated: var(--pink);
          --background-hover: var(--pink);
          --color: var(--yellow);
      }
  `],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateBoulderHelpComponent {


  constructor(private modalCtrl: ModalController) {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
