import { EventEmitter, Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GymListEntry } from 'src/model/gym-list-entry';
import { selectActiveGym, selectActiveGymId, selectActiveGymIsBeingDowloaded, selectActiveGymIsDowloaded } from './active-gym.selector';
import { finishedActiveGymDownload, markActiveGymAsDownloaded, setActiveGym, startedActiveGymDownload } from './active-gym.actions';

@Injectable({ providedIn: 'root' })
export class ActiveGymFacade {

    #store = inject(Store);

    activeGymDownloaded : EventEmitter<void> = new EventEmitter();

    get activeGym$(): Observable<GymListEntry> {
        return this.#store.pipe(select(selectActiveGym));
    }

    get activeGymId$(): Observable<string> {
        return this.#store.pipe(select(selectActiveGymId));
    }

    get activeGymIsBeingDownloaded$(): Observable<boolean> {
        return this.#store.pipe(select(selectActiveGymIsBeingDowloaded));
    }

    get activeGymIsDownloaded$(): Observable<boolean> {
        return this.#store.pipe(select(selectActiveGymIsDowloaded));
    }

    get onActiveGymFinishedDownloading$(): Observable<void> {
        return this.activeGymDownloaded.asObservable();
    }

    activeGymChanged(activeGym: GymListEntry) {
        this.#store.dispatch(setActiveGym({ activeGym: activeGym }));
    }

    startedActiveGymDownload() {
        this.#store.dispatch(startedActiveGymDownload());
    }

    finishedActiveGymDownload() {
        this.#store.dispatch(finishedActiveGymDownload());
        this.activeGymDownloaded.emit();
    }

    markActiveGymAsDownloaded() {
        this.#store.dispatch(markActiveGymAsDownloaded());
    }
}
