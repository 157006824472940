import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {


  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: here we should only set auth header for routes that require it
    if (this.authService.isLoggedIn()) {
      const token = this.authService.getCurrentUserSession().token;
      const auth = `Bearer ${token}`;
      const headers = {
        'Authorization': auth
      };
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request);
  }

}
