import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { GuestGuard } from '../auth/guest.guard';
import { NoGymGuard } from '../auth/no-gym.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/:username/:password',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('../pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'reset-password/:email',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'gyms',
    loadChildren: () => import('../pages/gyms/gyms.module').then(m => m.GymsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gyms/:gymId',
    loadChildren: () => import('../pages/gym-details/gym-details.module').then(m => m.GymDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'download-gym',
    loadChildren: () => import('../pages/download-gym/download-gym.module').then(m => m.DownloadGymPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'walls',
    loadChildren: () => import('../pages/walls/walls.module').then(m => m.WallsPageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'create-boulder',
    loadChildren: () => import('../pages/create-boulder/create-boulder.module').then(m => m.CreateBoulderPageModule),
    canActivate: [GuestGuard, AuthGuard, NoGymGuard]
  },
  {
    path: 'ascent-list/:username',
    loadChildren: () => import('../pages/ascent-list/ascent-list.module').then(m => m.AscentListPageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'grade-list',
    loadChildren: () => import('../pages/grade-list/grade-list.module').then(m => m.GradeListPageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'definition-list/:username',
    loadChildren: () => import('../pages/definition-list/definition-list.module').then(m => m.DefinitionListPageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'add-to-boulder-list',
    loadChildren: () => import('../pages/add-to-boulder-list/add-to-boulder-list.module').then(m => m.AddToBoulderListPageModule),
    canActivate: [AuthGuard, NoGymGuard, GuestGuard]
  },
  {
    path: '4x4/:listId',
    loadChildren: () => import('../pages/four-by-four/four-by-four.module').then(m => m.FourByFourPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'boulder-list-details/:listId',
    loadChildren: () => import('../pages/boulder-list-details/boulder-list-details.module').then(m => m.BoulderListDetailsPageModule)
  },
  {
    path: 'gym-chat',
    loadChildren: () => import('../pages/gym-chat/gym-chat.module').then(m => m.GymChatPageModule)
  },
  {
    path: 'guest-info',
    loadChildren: () => import('../pages/guest-info/guest-info.module').then(m => m.GuestInfoPageModule)
  },
  {
    path: 'profile/:username',
    loadChildren: () => import('../pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard, NoGymGuard]
  },
  {
    path: 'wall-reset',
    loadChildren: () => import('../pages/wall-reset/wall-reset.module').then( m => m.WallResetPageModule)
  }
]
  ;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
