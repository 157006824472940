import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BBEnv} from '../environments/bb-env';
import {Observable} from 'rxjs';
import {RestService} from './rest.service';
import {DocsCreator} from '../common/docs-creator';

@Injectable()
export class ImageUploadService {

  private wallImageUrl;

  constructor(private httpClient: HttpClient,
              private restService: RestService) {
    const appServerUrl = BBEnv.getAppServerUrl();
    this.wallImageUrl = appServerUrl + 'wall-image';
  }

  // todo: remove this method! images are downloaded via image-caching service
  downloadWallImage(imageId: String): Observable<Blob> {
    return this.httpClient.get(this.wallImageUrl + '/' + imageId, {
      responseType: 'blob'
    });
  }

  // todo: remove this method, use restService directly
  async uploadWallImage(blob: Blob): Promise<string> {
    return await this.restService.uploadWallImage(blob, DocsCreator.createUUID());
  }
}
