export class BoulderList {
  constructor(
    readonly date: Date,
    readonly author: string,
    readonly publicList: boolean,
    readonly name: string,
    readonly listId: string,
    readonly boulderIds: string[],
    readonly isProjectList: boolean
  ) {
  }
}
