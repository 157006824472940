import { CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, NgModule } from '@angular/core';
import { AppInitializer } from './app-initializer';
import { AppComponent } from './app.component';
import { modules } from './modules';
import { providers } from './providers';

const PouchDB = require('pouchdb').default;
const PouchFind = require('pouchdb-find').default;


/**
 * Here we can initialize services that need to do async work when the app starts
 */
export function initApp(appInitializer: AppInitializer) {
  return async () => {
    await appInitializer.run();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ...modules
  ],
  providers: [
    ...providers,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AppInitializer]
    }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    PouchDB.plugin(PouchFind);
  }
}
