export class UuidUtils {

  public static createUUID(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; ++i) {
      result += charset[Math.floor(Math.random() * charset.length)];
    }
    return result;
  }
}
