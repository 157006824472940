import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import pako from 'pako';
import { firstValueFrom } from 'rxjs';
import { BBEnv } from '../environments/bb-env';

@Injectable()
export class HoldDetectionService {

  private maskCache = new Map<string, Uint16Array>();
  private holdPositionsCache = new Map<string, any>();
  private imageIdsNotFoundCache = new Set<string>();

  constructor(
    private logger: NGXLogger,
    private http: HttpClient) {
  }

  public async detectHold(x: number, y: number, imageId: string, imageWidth: number): Promise<HoldCoordinate> {
    if (this.imageIdsNotFoundCache.has(imageId)) {
      return null;
    }
    const holds = await this.getHolds(imageId);
    if (holds) {
      const holdPositions = await this.getHoldPositions(imageId);
      if (holdPositions) {
        const index = Math.floor(imageWidth) * Math.floor(y) + Math.floor(x);
        const holdIndex = holds[index];
        const hold = holdPositions[holdIndex - 1];
        if (hold) {
          return { centerX: hold[0], centerY: hold[1], radius: hold[2] };
        }
      }
    }
    this.imageIdsNotFoundCache.add(imageId);
    return null;
  }

  private async getHolds(imageId: string) {
    if (this.maskCache.has(imageId)) {
      return this.maskCache.get(imageId);
    }
    try {
      const arrayBuffer = await this.getHoldsArrayBuffer(imageId);
      if (arrayBuffer) {
        const holds = this.getHoldsArray(arrayBuffer);
        this.maskCache.set(imageId, holds);
        return holds;
      }
    } catch (e) {
      this.logger.info('Holds not available', e);
    }
    return null;
  }

  private getHoldsArray(holdsArrayBuffer: ArrayBuffer): Uint16Array {
    const array = pako.inflate(new Uint8Array(holdsArrayBuffer));
    return new Uint16Array(array.buffer);
  }

  getHoldPositions(imageId: string): Promise<any> {
    if (this.holdPositionsCache.has(imageId)) {
      return Promise.resolve(this.holdPositionsCache.get(imageId));
    }
    try {
      const url = BBEnv.getAppServerUrl() + 'wall-image/holds-positions/' + imageId;
      return firstValueFrom(this.http.get(url, { responseType: 'arraybuffer' }))
        .then(data => {
          const decompressedData = pako.ungzip(new Uint8Array(data), { to: 'string' });
          const positions = JSON.parse(decompressedData);
          this.holdPositionsCache.set(imageId, positions);
          return positions;
        }).catch(e => {
          return null;
        });
    } catch (e) {
      this.logger.error('Error getting hold positions', e);
      return null;
    }
  }


  private async getHoldsArrayBuffer(imageId: string) {
    const url = BBEnv.getAppServerUrl() + 'wall-image/mask/' + imageId;
    const holdsFile = await firstValueFrom(this.http.get(url, { responseType: 'blob' })).catch(e => {
      return null;
    });
    if (!holdsFile) {
      return null;
    }
    return await (holdsFile as Blob).arrayBuffer();
    /*
    if (!this.platform.is('capacitor')) {
      //const holdsFile = await this.fileDownloadService.downLoadFileToLibrary(url, 'filename');
      return await (holdsFile as Blob).arrayBuffer();
    }
    const holdsFilename = imageId;
    let holdsFile = await this.fileDownloadService.getFile(holdsFilename);
    if (!holdsFile) {
      holdsFile = await this.fileDownloadService.downLoadFileToLibrary(url, holdsFilename);
    }
    return (holdsFile as Blob)?.arrayBuffer();
    */
  }
}

export interface HoldCoordinate {
  centerX: number;
  centerY: number;
  radius: number;
}
