import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { AuthService } from '../../services/auth.service';
import { BBStorage } from '../../utils/bb-storage';

@Component({
  selector: 'guest-info-component',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent implements OnInit {

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private location: Location,
    public bbStorage: BBStorage,
    private authService: AuthService,
    private activeGymFacade: ActiveGymFacade) {
  }

  qrCodeUrl = '';
  activeGymName = '';
  isModal = false;

  public static async showAsModal(modalCtrl: ModalController) {
    const modal = await modalCtrl.create({
      component: GuestInfoComponent,
      componentProps: {isModal: true}
    });
    return await modal.present();
  }

  ngOnInit() {
    this.activeGymFacade.activeGym$.subscribe(activeGym => {
      if (activeGym) {
        this.qrCodeUrl = 'https://app.boulder-book.com/gym/gym_info+' + activeGym.gymId;
        this.activeGymName = activeGym.gymName;
      }
    });
  }

  close() {
    if (this.isModal) {
      this.modalCtrl.dismiss();
    } else {
      this.location.back();
    }
  }

  async logoutAndCreateAccount() {
    await this.authService.logout();
    await this.bbStorage.initialize();
    await this.router.navigate(['signup']);
    this.close();
  }
}
