import {Injectable} from '@angular/core';
import {UserSession} from '../model/user-session';
import {BBErrorType} from '../errors/bb-error';
import {BBStorage} from '../utils/bb-storage';
import {RestService} from './rest.service';
import {LoginDto, NewUserDto} from '../common/dtos';

@Injectable()
export class BBLoginService {

  constructor(
    private storage: BBStorage,
    private restService: RestService) {
  }

  get currentSession(): UserSession {
    return this.storage.userSession;
  }

  async validateSession(): Promise<boolean> {
    return await this.restService.validateSession();
  }

  async register(user: NewUserDto) {
    return this.restService.registerNewUser(user);
  }

  async login(credentials: LoginDto) {
    try {
      const response = await this.restService.login(credentials);
      await this.updateSession({
        token: response.token,
        user_name: response.username
      } as UserSession);
    } catch (err) {
      if (err.bbErrorType === BBErrorType.NOT_AUTHORIZED) {
        await this.updateSession(null);
      }
      throw err;
    }
  }

  async logout() {
    // TODO: server-side not implemented yet
    await this.updateSession(null);
  }

  isLoggedIn(): boolean {
    return this.storage.userSession != null;
  }

  private async updateSession(session: UserSession) {
    await this.storage.setUserSession(session);
  }

}
