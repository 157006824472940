export class AscentListEntry {

  constructor(
    readonly date: Date,
    readonly flash: boolean,
    readonly userName: string,
    readonly personalGrade: number,
    readonly id: string
  ) {

  }

}
