<ion-header *ngIf="visibleItems">
  <ion-toolbar>
    <ion-select [multiple]="true" (ionChange)="filterChanged($event)" interface="popover"
                [label]="'feed-page.your-feed' | translate" [ngModel]="filter" (ngModelChange)="filterChanged($event)"
                toggleIcon="filter-outline" [color]="filter?.length!=5?'danger':'secondary'">
      <ion-select-option value="NEW_BOULDER">{{"feed-page.new-boulders" | translate}}</ion-select-option>
      <ion-select-option value="ASCENT">{{"feed-page.ascents" | translate}}</ion-select-option>
      <ion-select-option value="COMMENT">{{"feed-page.comments" | translate}}</ion-select-option>
      <ion-select-option value="UP_VOTE">{{"feed-page.up-votes" | translate}}</ion-select-option>
      <ion-select-option value="DOWN_VOTE">{{"feed-page.down-votes" | translate}}</ion-select-option>
    </ion-select>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!allItems || allItems?.length > 0 else nofeeditems">
  <loader *ngIf="!visibleItems"></loader>
  <ion-refresher (ionRefresh)="onRefresh($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <feed-item *ngFor="let item of visibleItems" [isNew]="isNew(item?.date)" [item]="item"></feed-item>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadMore($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="{{'feed-page.loading' | translate}}">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ng-template #nofeeditems>
  <div class="no-feed-items ion-padding">
    <h1>{{"feed-page.nothing-going-on" | translate}}</h1>
    <h2><a [routerLink]="'/create-boulder'">{{"feed-page.add-a-boulder" | translate}}</a></h2>
  </div>
</ng-template>
