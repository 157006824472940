import {GymDBDoc} from '../common/docs';
import {GymDBDocType} from '../common/doc-types';

export abstract class DocWriteService {

  abstract addDocument(doc: GymDBDoc) : Promise<void>;

  abstract updateDocument(doc: GymDBDoc) : Promise<void>;

  abstract removeDocument(doc: GymDBDoc) : Promise<void>;

  abstract removeDocOfTypeWithId(docType: GymDBDocType, docId: string) : Promise<void>;

  abstract removeDocsOfTypeWithQuery<T extends GymDBDoc>(docType: GymDBDocType, query) : Promise<void>;

}
