import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from './validation.service';
import {
  EmailIsUsedResponseDto,
  ErrorDto,
  FeedbackDto,
  LoginDto,
  LoginResponseDto,
  NewGymDto,
  NewGymV2ResponseDto,
  NewUserDto,
  ResetPasswordEmailDto,
  UserProfileDto,
  UsernameIsFreeResponseDto
} from '../common/dtos';
import { RestEndpoints } from '../utils/rest-endpoints';
import {
  InternalServerError,
  InvalidDataError,
  InvalidFileUploadError,
  NoInternetError,
  NotAuthorizedError,
  SchemaUpdateError,
  UnrecognizedError,
  UserAlreadyExistsError
} from '../errors/bb-error';
import { BBStorage } from '../utils/bb-storage';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';

/**
 * This service should be used for every request between the app and the server, except the ones against the
 * /db endpoint in which case {@link CouchService} should be used instead.
 * Errors in app-server communication are handled here and transformed to a standard set of app-errors.
 * Each method should more or less only execute the request and return the response or throw an error.
 */
@Injectable()
export class RestService {
  constructor(
    private logger: NGXLogger,
    private httpClient: HttpClient,
    private bbStorage: BBStorage,
    private validationService: ValidationService) {
  }

  private static validateEmptyResponse(response: any) {
    const responseEmpty = response === null || (typeof response === 'undefined') || (Object.keys(response).length === 0);
    if (!responseEmpty) {
      throw new InvalidDataError('response should be empty');
    }
  }

  /**
   * This method transforms standard errors that occur in app-server communication to app-specific error objects.
   * The server is expected to respond always with 400, 401 or 500. In some cases it also responds with 412 and sends
   * some data needed to interpret the error. These cases are handled specifically before this method runs.
   * If there is no response from the server the status might also be 0.
   */
  private static handleStandardErrors(err: HttpErrorResponse, errorBody?: ErrorDto) {
    if (err.status === 400) {
      throw new InvalidDataError(errorBody.data);
    } else if (err.status === 401) {
      throw new NotAuthorizedError();
    } else if (err.status === 500) {
      throw new InternalServerError(err?.error?.message);
    } else if (err.status === 0) {
      // todo: should we check for err.status === 0 or error.error instanceof ErrorEvent as described here
      // https://angular.io/guide/http#error-handling
      // when the server is offline we do not get the latter ? should we / can we differentiate between
      // 'app has no internet connection' and 'server could not be reached / is offline' ?
      throw new NoInternetError();
    } else {
      throw new UnrecognizedError(err);
    }
  }

  public async updateSchema() {
    const couchSchema = await this.getRequest(RestEndpoints.COUCH_SCHEMA, false, errorData => {
      throw new SchemaUpdateError(errorData);
    });
    const restSchema = await this.getRequest(RestEndpoints.REST_SCHEMA, false, errorData => {
      throw new SchemaUpdateError(errorData);
    });
    const typeDefinitions = await this.getRequest(RestEndpoints.TYPE_DEFINITIONS, false, errorData => {
      throw new SchemaUpdateError(errorData);
    });
    await this.bbStorage.setSchema(couchSchema, restSchema, typeDefinitions);
    this.logger.debug('Schema update complete!');
  }

  public async registerNewUser(newUserDto: NewUserDto) {
    this.validationService.validateNewUser(newUserDto);
    const response = await this.postRequest(RestEndpoints.REGISTER, false, newUserDto, errorData => {
      // todo: 412 needs to be implemented on server side
      throw new UserAlreadyExistsError();
    });
    RestService.validateEmptyResponse(response);
  }

  public async deleteAccount() {
    await this.getRequest(RestEndpoints.DELETE_ACCOUNT, true);
  }

  public async login(loginDto: LoginDto): Promise<LoginResponseDto> {
    this.validationService.validateLoginDto(loginDto);
    const loginResponseDto = await this.postRequest(RestEndpoints.LOGIN, false, loginDto) as LoginResponseDto;
    this.validationService.validateLoginResponseDto(loginResponseDto);
    return loginResponseDto;
  }

  public async usernameIsFree(username: string): Promise<boolean> {
    try {
      const usernameIsFreeResponse = await this.getRequest(RestEndpoints.USERNAME_IS_FREE + '/' + username, false) as UsernameIsFreeResponseDto;
      return usernameIsFreeResponse.usernameIsFree;
    } catch (e) {
      this.logger.warn('Could not determine if username is taken ', e);
      return true;
    }
  }

  public async emailIsAlreadyUsed(email: string): Promise<boolean> {
    try {
      const emailIsUsedResponse = await this.getRequest(RestEndpoints.EMAIL_IS_USED + '/' + email, false) as EmailIsUsedResponseDto;
      return emailIsUsedResponse.emailIsAlreadyUsed;
    } catch (e) {
      this.logger.warn('Could not determine if email is used ', e);
      return true;
    }
  }

  public async getUserProfile(username: string): Promise<UserProfileDto> {
    try {
      return await this.getRequest(RestEndpoints.USER_PROFILE + '/' + username, false) as UserProfileDto;
    } catch (e) {
      this.logger.warn('Could not get userprofile for user ' + username, e);
      return null;
    }
  }

  public async validateSession(): Promise<boolean> {
    try {
      // todo: so far we would return false here when the server is down, not really what we want
      await this.getRequest(RestEndpoints.PRIVATE, true);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async resetPassword(resetPasswordEmailDto: ResetPasswordEmailDto) {
    this.validationService.validateResetPasswordEmailDto(resetPasswordEmailDto);
    const response = await this.postRequest(RestEndpoints.RESET_PASSWORD_EMAIL, false, resetPasswordEmailDto);
    RestService.validateEmptyResponse(response);
  }

  /**
   * @return image-id of the uploaded image created by the server
   */
  public async uploadWallImage(imageBlob: Blob, blobName: string): Promise<string> {
    const input = new FormData();
    input.append('image', imageBlob, blobName);
    return await this.postRequest(RestEndpoints.WALL_IMAGE, true, input, errorData => {
      this.logger.error('Error in post request ', errorData);
      throw new InvalidFileUploadError();
    });
  }

  public async sendFeedback(feedbackDto: FeedbackDto): Promise<string> {
    this.validationService.validateFeedbackDto(feedbackDto);
    return await this.postRequest(RestEndpoints.SEND_FEEDBACK, true, feedbackDto, errorData => {
      throw errorData;
    });
  }

  public async createNewGym(newGymDto: NewGymDto): Promise<NewGymV2ResponseDto> {
    this.validationService.validateNewGym(newGymDto);
    const newGymResponseDto = await this.postRequest(RestEndpoints.NEW_GYM_V2, true, newGymDto) as NewGymV2ResponseDto;
    this.validationService.validateNewGymResponse(newGymResponseDto);
    return newGymResponseDto;
  }

  /**
   * Sends a get request to the server.
   * @param url - the server url the request is sent to
   * @param needsLogin - true if calling this url needs authorization, false otherwise
   * @param specificErrorHandler
   */
  private async getRequest(url: string, needsLogin: boolean, specificErrorHandler?: (ErrorDto) => void): Promise<any> {
    if (needsLogin && !this.bbStorage.loggedIn) {
      throw new NotAuthorizedError();
    }
    try {
      return await firstValueFrom(this.httpClient.get(url)) as any;
    } catch (err) {
      this.handleRestError(err, specificErrorHandler);
    }
  }

  /**
   * Sends a post request to the server.
   * @param url - the server url the request is sent to
   * @param needsLogin - true if calling this url needs authorization, false otherwise
   * @param body - the body that is sent along with the request
   * @param specificErrorHandler - callback that accepts the error body in case the server sent status 412,
   *                               can be used to handle the error in a specific way
   */
  private async postRequest(url: string, needsLogin: boolean, body: any, specificErrorHandler?: (ErrorDto) => void): Promise<any> {
    if (needsLogin && !this.bbStorage.loggedIn) {
      throw new NotAuthorizedError();
    }
    try {
      return await firstValueFrom(this.httpClient.post(url, body)) as any;
    } catch (err) {
      this.logger.info('Error when requesting ' + url, JSON.stringify(err));
      this.handleRestError(err, specificErrorHandler);
    }
  }

  private handleRestError(err: HttpErrorResponse, specificErrorHandler: (ErrorDto) => void) {
    if (typeof err.status === 'undefined') {
      throw new UnrecognizedError(err);
    }
    const errorBody = this.validateAndGetErrorBody(err);
    if (err.status === 412) {
      // 412 is used by the server to indicate a specific error
      if (specificErrorHandler) {
        specificErrorHandler(errorBody.data);
      } else {
        throw new UnrecognizedError(err);
      }
    }
    RestService.handleStandardErrors(err, errorBody);
  }

  private validateAndGetErrorBody(err: HttpErrorResponse): ErrorDto {
    if ([400, 401, 412, 500].indexOf(err.status) > -1) {
      const errorBody = err.error as ErrorDto;
      this.validationService.validateErrorDto(errorBody);
      return errorBody;
    } else {
      return null;
    }
  }

}
