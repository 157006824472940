import { createFeature, createReducer, on } from '@ngrx/store';
import { GymListEntry } from 'src/model/gym-list-entry';
import { finishedActiveGymDownload, markActiveGymAsDownloaded, setActiveGym, startedActiveGymDownload } from './active-gym.actions';

export const activeGymFeatureKey = 'activeGym';

export interface ActiveGymState {
    activeGym: GymListEntry;
    isBeingDownloaded: boolean;
    isDownloaded: boolean;
}

export const initialState: ActiveGymState = {
    activeGym: null,
    isBeingDownloaded: false,
    isDownloaded: false
};


export const activeGymReducer = createReducer(
    initialState,
    on(setActiveGym, (state, { activeGym }) => ({ ...state, activeGym: activeGym })),
    on(startedActiveGymDownload, (state) => ({ ...state, isBeingDownloaded: true, isDownloaded: false })),
    on(finishedActiveGymDownload, (state) => ({ ...state, isBeingDownloaded: false, isDownloaded: true })),
    on(markActiveGymAsDownloaded, (state) => ({ ...state, isDownloaded: true }))
);

export const activeGymFeature = createFeature({
    name: activeGymFeatureKey,
    reducer: activeGymReducer
});
