import {environment} from './environment';

export class BBEnv {

  public static getAppServerUrl() {
    return environment.appServerUrl;
  }

  public static isProduction() {
    return environment.production;
  }

}
