<ion-content class="ion-padding">
  <ion-fab slot="fixed" horizontal="end" vertical="top" class="close-button">
    <ion-fab-button translucent="true" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <h1>{{'add-ascent-component.flash'|translate}}</h1>
        <ion-segment (ngModelChange)="flashChosen()" [(ngModel)]="isFlash">
          <ion-segment-button value="flash" data-cy="yes-flash-button">
            <ion-label>{{'add-ascent-component.yes'|translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="noflash"  data-cy="no-flash-button">
            <ion-label>{{'add-ascent-component.no'|translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
      <div class="swiper-slide" *ngIf="!isOwnBoulder">
        <h1>{{'add-ascent-component.good-boulder'|translate}}</h1>
        <ion-segment (ngModelChange)="thumbSelected()" [(ngModel)]="qualityRating">
          <ion-segment-button value="thumb-up">
            <ion-icon name="thumbs-up-outline"></ion-icon>
            <ion-label>{{'add-ascent-component.nice'|translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="thumb-down">
            <ion-icon name="thumbs-down-outline"></ion-icon>
            <ion-label>{{'add-ascent-component.bad'|translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="no-thumb">
            <ion-icon name="remove-outline"></ion-icon>
            <ion-label>{{'add-ascent-component.average'|translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
      <div class="swiper-slide" cy-data="grade-selection">
        <ion-select #gradeSelector [interfaceOptions]="gradeChooserOptions" [(ngModel)]="personalGrade"
                    (ionCancel)="dismiss()"
                    aria-label="select grade"
                    (ngModelChange)="gradeSelected()" cancel-text=""
                    interface="action-sheet">
          <ion-select-option *ngFor="let grade of possibleGrades" label="{{grade.grade}}" data-cy="grade-selection-{{grade.grade}}"
                             [value]="grade.grade" container="grade-conttttt" [text]="grade.description">{{grade.description}}
            <div class="current-grade"
                 *ngIf="currentAverageGrade === grade.grade"><br>({{'add-ascent-component.current-grade'|translate}})
            </div>
          </ion-select-option>
        </ion-select>
      </div>
    </div>
  </div>
</ion-content>
