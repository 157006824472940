import { Injectable } from '@angular/core';
import { SchemaService } from './schema.service';
import { ValidatorFn, Validators } from '@angular/forms';
import {
    ErrorDto,
    FeedbackDto,
    LoginDto,
    LoginResponseDto,
    NewGymDto,
    NewUserDto,
    ResetPasswordEmailDto
} from '../common/dtos';
import { InvalidDataError } from '../errors/bb-error';
import { GymDBDoc } from '../common/docs';
import { GymDBDocType } from '../common/doc-types';

const tv4 = require('../common/lib/tv4.min');

@Injectable()
export class ValidationService {

    constructor(
        private schemaService: SchemaService) {
    }

    private static async initTv4(typeDefinitions) {
        // adding the schema with one argument automatically uses the id property in the schema as url
        tv4.addSchema(typeDefinitions);
    }

    private static getEverythingAfterLastSlash(s: string) {
        const parts = s.split('/');
        if (parts.length < 1) {
            throw new Error('Could not determine string part after slash in ' + s);
        }
        return parts.pop();
    }

    private static validateSchema(data, schema) {
        const checkRecursive = false;
        const banUnknownProperties = true;
        const validationResult = tv4.validateResult(data, schema, checkRecursive, banUnknownProperties);
        if (!validationResult.missing || validationResult.missing.length > 0) {
            throw new Error('Missing schema(s): ' + validationResult.missing);
        }
        if (!validationResult.valid) {
            const prettyObj = JSON.stringify(data, null, 4);
            throw new InvalidDataError(`Object:\n${prettyObj}\ndoes not match schema.${validationResult.error.message} in ${validationResult.error.dataPath}`);
        }
        return true;
    }

    public async initialize() {
        const typeDefinitions = this.schemaService.typeDefinitions;
        await ValidationService.initTv4(typeDefinitions);
    }

    validateErrorDto(errorDto: ErrorDto) {
        ValidationService.validateSchema(errorDto, this.schemaService.errorSchema);
    }

    validateNewUser(newUser: NewUserDto) {
        ValidationService.validateSchema(newUser, this.schemaService.signupCredentialsSchema);
    }

    validateLoginDto(loginDto: LoginDto) {
        ValidationService.validateSchema(loginDto, this.schemaService.loginCredentialsSchema);
    }

    validateLoginResponseDto(loginResponseDto: LoginResponseDto) {
        ValidationService.validateSchema(loginResponseDto, this.schemaService.loginResponseSchema);
    }

    validateFeedbackDto(feedbackDto: FeedbackDto) {
        ValidationService.validateSchema(feedbackDto, this.schemaService.feedbackSchema);
    }

    validateResetPasswordEmailDto(resetPasswordEmailDto: ResetPasswordEmailDto) {
        ValidationService.validateSchema(resetPasswordEmailDto, this.schemaService.resetPasswordEmailSchema);
    }

    validateNewGymResponse(newGymResponse) {
        ValidationService.validateSchema(newGymResponse, this.schemaService.newGymResponseSchema);
    }

    validateNewGym(newGym: NewGymDto) {
        ValidationService.validateSchema(newGym, this.schemaService.newGymSchema);
    }

    validateDoc(doc: GymDBDoc, docType: GymDBDocType) {
        if (doc.type !== docType.toString()) {
            throw new Error('document type mismatch: ' + doc.type + ' vs. ' + docType);
        }
        ValidationService.validateSchema(doc, this.schemaService.docSchema(doc.type));
    }

    validateShortTechnicalName(value: string) {
        return tv4.validateResult(value, this.schemaService.shortTechnicalNameSchema, false, false).valid;
    }

    validateShortHumanReadableName(value: string) {
        return tv4.validateResult(value, this.schemaService.shortHumanReadableNameSchema, false, false).valid;
    }

    /**
     * This method builds an array of Angular FormValidators from a given json schema for
     * a given property name. The json schema has to match a certain format and not all features
     * of json format are supported!
     * todo: maybe a more consistent approach would be building a custom validator that uses
     * tv4 validation underneath (probably by using the tv4.validateMultiple method and then
     * parsing the errors array for the error of the property we are looking at)
     * also see here: https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html
     */
    public getFormValidator(schema: any, property: string): ValidatorFn[] {
        const validators = [];
        if (schema.required && schema.required.indexOf(property) > -1) {
            validators.push(Validators.required);
        }
        if (!schema.properties) {
            throw new Error('Invalid schema, could not find properties');
        }
        let schemaProperty = schema.properties[property];
        if (!schemaProperty) {
            throw new Error('Could not find definition for property ' + JSON.stringify(property));
        }
        if (schemaProperty.$ref) {
            // we have limited support for $ref: it has to point to the type_definitions!
            const typeDefinitions = this.schemaService.typeDefinitions;
            // we expect the value of $ref to be something like type_definitions#/property_name
            const refName = ValidationService.getEverythingAfterLastSlash(schemaProperty.$ref);
            const type = typeDefinitions[refName];
            if (!type) {
                throw new Error('Could not find type definition for reference name ' + refName);
            } else {
                schemaProperty = type;
            }
        }
        if (schemaProperty.type === 'string') {
            if (schemaProperty.pattern) {
                validators.push(Validators.pattern(schemaProperty.pattern));
            }
            if (schemaProperty.minLength) {
                validators.push(Validators.minLength(schemaProperty.minLength));
            }
            if (schemaProperty.maxLength) {
                validators.push(Validators.maxLength(schemaProperty.maxLength));
            }
        } else {
            throw new Error('Property ' + JSON.stringify(schemaProperty) + ' is not of type string. Cannot build Validator for it');
        }
        return validators;
    }

}
