import { LocalGymReferenceDoc } from '../common/docs';
import { CommonUtils } from '../common';
import { BBErrorType, PouchDBError } from '../errors/bb-error';
import { DocsCreator } from '../common/docs-creator';
import { Observable, firstValueFrom } from 'rxjs';
import { CouchService, SyncDirection } from './couch.service';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { PouchUtils } from '../utils/pouch-utils';
import { AlertController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ErrorNotificationService } from './error-notification.service';
import { BBStorage } from '../utils/bb-storage';
import Database = PouchDB.Database;
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

const PouchDB = require('pouchdb').default;

/**
 * Service used to create, delete, download and sync the local pouch databases.
 * Currently we use the following pouch databases:
 *
 * - gym_<uuid> (multiple): these dbs are used to store the various documents for each gym.
 *                          they are synced with their remote couchdb counter parts using {@link CouchService}
 * - local_gyms (one): this db is used to keep track of which gym databases exist for the current user, for each
 *                     user there is one gym reference document for each local gym db.
 *
 * We use the same local dbs for every user on the same device, but use different gym references for each user.
 * All remote couch accesses are delegated to {@link CouchService}.
 */
@Injectable()
export class PouchDBService {

  /**
   * Used to keep track of the gym pouch dbs that exist on the current device.
   */
  private localGymsDB = new PouchDB('local_gyms');

  constructor(
    private activeGymFacade: ActiveGymFacade,
    private bbStorage: BBStorage,
    private logger: NGXLogger,
    private errorHandler: ErrorNotificationService,
    private couchService: CouchService,
    private authService: AuthService) {

    this.activeGymFacade.activeGymId$.subscribe(async gymId => {
      const localGymIds = await firstValueFrom(this.getLocalGymIds());
      if (gymId && localGymIds.indexOf(gymId) === -1) {
        this.activeGymFacade.startedActiveGymDownload();
        try {
          await this.addGymDatabase(gymId);
        } finally {
          this.activeGymFacade.finishedActiveGymDownload();
        }
      } else {
        this.activeGymFacade.markActiveGymAsDownloaded();
      }
    });

  }

  public async addGymDatabase(gymId: string) {
    const start = performance.now();
    // create local pouch
    const pouch = await this.getNewPouchDB(gymId);
    /*
    TODO: create indexes
    await pouch.createIndex({
      index: {
        fields: ['boulderId', 'wallId', 'wallImageId'],
        name: 'ids_idx',
      }
    });

     */

    // download remote couch to local pouch and add reference for new local gym
    try {
      await this.couchService.syncLocalGymDBWithRemote(gymId, pouch, 'from-server');
      await this.addGymReference(gymId);
      this.logger.debug(`Downloading gym ${gymId} took ${performance.now() - start}`);
    } catch (err) {
      // revert our changes
      await pouch.destroy();
      // todo: error handling ?
      throw new PouchDBError(err, 'Error while adding gym database');
    }
  }

  private async handleSyncError(error, gymId) {
    this.logger.error('handleSyncError', JSON.stringify(error));
    // we reset update sequence for couch and pouch to prevent skipped syncing
    await this.bbStorage.setCouchUpdateSeqLastSync(null);
    await this.bbStorage.setPouchUpdateSeqLastSync(null);
    if (error?.bbErrorType === BBErrorType.GYM_SYNC_DENIED || error?.data?.error === 'forbidden') {
      await this.deleteAndResyncGym(gymId);
      await this.errorHandler.showBBError(error);
    } else if (error?.data?.result) {
      const result = error.data.result;
      if (result.status === 401) {
        await this.errorHandler.handleUnauthorized(error);
      } else if (result.status === 'aborting' && result.doc_write_failures > 0) {
        await this.errorHandler.showBBError(error);
      }
    }
    return error;
  }

  public async deleteGymDatabase(gymId: string) {
    try {
      const pouch = await this.getExistingPouch(gymId);
      await pouch.destroy();
      await this.removeGymReference(gymId);
    } catch (err) {
      throw new PouchDBError(err, 'Error while deleting gym database');
    }
  }

  public async syncGymDatabase(gymId: string, syncDirection: SyncDirection = 'both') {
    if (await this.gymReferenceExists(gymId)) {
      try {
        const pouch = await this.getExistingPouch(gymId);
        await this.couchService.syncLocalGymDBWithRemote(gymId, pouch, syncDirection);
      } catch (err) {
        await this.handleSyncError(err, gymId);
      }
    }
  }

  private async deleteAndResyncGym(gymId) {
    this.logger.info('Delete and add gym again: ', gymId);
    await this.deleteGymDatabase(gymId);
    await this.addGymDatabase(gymId);
  }


  /**
   * Use this method to get a list of all gym ids gyms that are available locally for the current user
   */
  public getLocalGymIds(): Observable<string[]> {
    const username = this.getCurrentUsername();
    return PouchUtils.getAllDocsWithPrefix<LocalGymReferenceDoc>(this.localGymsDB, username + DocsCreator.DOC_SEPARATOR)
      .pipe(
        map(refs => refs.map(ref => CommonUtils.getGymIdForDBName(ref.gymDBName)))
      );
  }

  /**
   * Gives direct access to a gym pouch db, should only be used by {@link PouchDocService}
   */
  public async getExistingPouch(gymId: string): Promise<Database> {
    const exists = await this.gymReferenceExists(gymId);
    if (!exists) {
      throw new Error('No local gym db registered for gym ' + gymId);
    }
    return new PouchDB(CommonUtils.getDBNameForGymId(gymId));
  }

  /**
   * This should be the only place where a new local gym pouch db is created.
   */
  private async getNewPouchDB(gymId): Promise<Database> {
    await this.checkGymReferenceDoesNotExist(gymId);
    return new PouchDB(CommonUtils.getDBNameForGymId(gymId));
  }

  private async addGymReference(gymId: string) {
    await this.checkGymReferenceDoesNotExist(gymId);
    await this.localGymsDB.put(DocsCreator.createLocalGymReference(this.getCurrentUsername(), gymId));
  }

  private async removeGymReference(gymId: string) {
    if (!(await this.gymReferenceExists(gymId))) {
      throw new Error('no gym reference exists for gym id ' + gymId);
    }
    const doc = await this.getGymReference(gymId);
    await this.localGymsDB.remove(doc);
  }

  private async checkGymReferenceDoesNotExist(gymId: string) {
    const gymRefExists = await this.gymReferenceExists(gymId);
    if (gymRefExists) {
      // downloading a gym that is already available locally should be forbidden by the UI already
      throw new Error('gym ' + gymId + ' has already been created');
    }
  }

  public async gymReferenceExists(gymId: string) {
    try {
      const gymReference = await this.getGymReference(gymId);
      return gymReference !== null && gymReference !== undefined;
    } catch {
      return false;
    }
  }

  private async getGymReference(gymId: string) {
    const gymRefId = DocsCreator.createLocalGymReferenceId(this.getCurrentUsername(), gymId);
    return await this.localGymsDB.get(gymRefId);
  }

  private getCurrentUsername() {
    const userName = this.authService.getCurrentUsername();
    // todo: should/can we align this with user name validation ? maybe move this somewhere else
    if (!userName || userName.length < 3) {
      throw new Error('invalid username, maybe no user session ? ' + userName);
    }
    return userName;
  }

}
