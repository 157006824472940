import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Wall} from '../../model/wall';
import {LoadingController, Platform} from '@ionic/angular';
import {ImageUploadService} from '../../services/image-upload.service';
import {WallService} from '../../services/wall.service';
import {ErrorNotificationService} from '../../services/error-notification.service';
import {WallImage} from '../../model/wall-image';
import {TranslateService} from '@ngx-translate/core';
import {Camera, CameraResultType, Photo} from '@capacitor/camera';
import {CameraSource, ImageOptions} from '@capacitor/camera/dist/esm/definitions';
import {NGXLogger} from 'ngx-logger';
import {decode} from 'base64-arraybuffer';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-upload-wall-image',
  templateUrl: './upload-wall-image.component.html',
  styleUrls: ['./upload-wall-image.component.scss'],
})
export class UploadWallImageComponent implements OnInit {

  capacitor: boolean;

  @Output() onImageUploaded: EventEmitter<WallImage> = new EventEmitter();

  public chosenFileSrc: any = null;

  private imageFile: File;
  private photo: Photo;

  wall: Wall;

  constructor(
    private sanitizer: DomSanitizer,
    private logger: NGXLogger,
    private imageUploadService: ImageUploadService,
    private wallService: WallService,
    private loadingCtrl: LoadingController,
    private errorNotificationService: ErrorNotificationService,
    private platform: Platform,
    private translate: TranslateService
  ) {
  }

  @Input('wall')
  public set wallFor(wall: Wall) {
    this.wall = wall;
  }

  async uploadWallImage() {
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('upload-wall-image-component.photo-is-being-uploaded'),
      duration: 100000
    });
    loader.present();
    try {
      const imageId = await this.uploadImage();
      this.logger.info('Image uploaded!', imageId);
      const wallImage = await this.wallService.addWallImage(this.wall.wallId, imageId);
      this.onImageUploaded.emit(wallImage);
    } catch (error) {
      this.logger.error('Error during wall-upload', error);
      await this.errorNotificationService.showBBError(error);
    } finally {
      await loader.dismiss();
      this.imageFile = null;
      this.photo = null;
    }
  }

  ngOnInit() {
    this.capacitor = this.platform.is('capacitor');
  }

  chooseLocalImageFile(event) {
    this.imageFile = event.target.files.item(0);
    this.photo = null;
    const reader = new FileReader();
    reader.readAsDataURL(this.imageFile);
    reader.onload = (event) => {
      this.chosenFileSrc = event.target.result;
    };
  }

  chooseImageFileWithCamera() {
    this.doChooseImageFileWithCamera().then((photo) => {
      this.photo = photo;
      this.chosenFileSrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
        + this.photo.base64String);
    }).catch(error => {
      this.logger.error('Error while getting photo ', error);
    });
  }

  public imageChosen() {
    return this.imageFile != null || this.photo != null;
  }

  private doChooseImageFileWithCamera(): Promise<Photo> {
    const cameraOptions: ImageOptions = {
      source: CameraSource.Photos,
      allowEditing: true,
      saveToGallery: true,
      resultType: CameraResultType.Base64,
      quality: 100,
      width: 3000,
      height: 2000,
      correctOrientation: true
    };
    return Camera.getPhoto(cameraOptions);
  }

  private async uploadImage() {
    const imageBlob = this.photo ? await this.createBlob(this.photo) : this.imageFile;
    return await this.imageUploadService.uploadWallImage(imageBlob);
  }

  private async createBlob(photo: Photo): Promise<Blob> {
    const decodedBase64 = decode(photo.base64String);
    return new Blob([new Uint8Array(decodedBase64)], {
      type: `image/${photo.format}`,
    });
  }


}
