import {ErrorHandler, Injectable} from '@angular/core';
import {BBError, BBErrorType} from './bb-error';
import {ErrorNotificationService} from '../services/error-notification.service';
import {MatomoTracker} from 'ngx-matomo-client';
import {serializeError} from 'serialize-error';
import {NGXLogger} from 'ngx-logger';
import * as Sentry from '@sentry/angular';
import {BBStorage} from 'src/utils/bb-storage';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { first } from 'cypress/types/lodash';
import { firstValueFrom } from 'rxjs';

const stringify = require('json-stringify-safe');

@Injectable()
export class BBErrorHandler extends ErrorHandler {


  constructor(
    private activeGymFacade: ActiveGymFacade,
    private bbStorage: BBStorage,
    private logger: NGXLogger,
    private matomoTracker: MatomoTracker,
    private errorNotificationService: ErrorNotificationService
  ) {
    super();
  }

  handleError(error: any): void {
    this.logger.error('handleError', error);
    this.trackError(error);
    if (!this.containsErrorMessage(error)) {
      this.matomoTracker.trackEvent('error', 'handle-empty-error');
      this.logger.debug('empty error', error);
    }
    if (BBErrorHandler.isEmptyPouchError(error) || BBErrorHandler.isEmptyPouchError(error?.rejection)) {
      this.matomoTracker.trackEvent('error', 'handle-empty-pouch-error');
      this.logger.debug('empty-pouch-error', error);
    } else if (error?.bbErrorType?.length > 0) {
      this.matomoTracker.trackEvent('error', 'handle-' + error.bbErrorType + '-error');
      this.errorNotificationService.showBBError(error.rejection ? error.rejection : error);
    } else if (error.rejection) {
      this.matomoTracker.trackEvent('error', 'handle-rejection-error');
      this.errorNotificationService.showBBError(error.rejection);
      this.logger.debug('rejection-error', error);
    }
  }

  private async trackError(error) {
    try {
      const activeGymId = await firstValueFrom(this.activeGymFacade.activeGymId$);
      Sentry.setContext('bbuser', {
        name: this.bbStorage?.userSession?.user_name,
        gym: activeGymId,
      });
      const scope = new Sentry.Scope();
      scope.setTag('gym_id', activeGymId);
      scope.setTag('username', this.bbStorage?.userSession?.user_name);
      Sentry.captureException(error.originalError || error, scope);
    } catch (e) {
      this.logger.warn('Error tracking error with sentry', e);
    }
  }

  private static isEmptyPouchError(error) {
    return error?.bbErrorType === BBErrorType.POUCH_DB_ERROR && BBErrorHandler.errorToString(error?.data) === '{}'
  }

  private containsErrorMessage(error: any) {
    if (BBErrorHandler.isEmpty(error)) {
      return false;
    }
    if (error.bbErrorType) {
      const bbError = error as BBError;
      if (BBErrorHandler.isEmpty(bbError.data) && BBErrorHandler.isEmpty(bbError.message)) {
        return false;
      }
    }
    return true;
  }

  public static errorToString(error) {
    return stringify(serializeError(error));
  }

  private static isEmptyObject(object) {
    return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
  }

  private static isEmpty(property) {
    return BBErrorHandler.isEmptyObject(property) || BBErrorHandler.errorToString(property) === '{}';
  }

}
