import { Injectable } from '@angular/core';
import { WallImage } from '../model/wall-image';
import { ImageCachingService } from './image-caching.service';

@Injectable()
export class ImageService {


  constructor(
    private imageCachingService: ImageCachingService
  ) {
  }

  public async wallImagePath(wallImage: WallImage) {
    return await this.imageCachingService.getImageSrc(wallImage.imageId);
  }

  public async cacheImage(wallImageId: string) {
    return await this.imageCachingService.cacheImage(wallImageId);
  }
}
