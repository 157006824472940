import {Injectable} from '@angular/core';
import {DocWriteService} from './doc.write.service';
import {DocQueryService} from './doc.query.service';
import {AuthService} from './auth.service';
import {ReactionDoc} from '../common/docs';
import {DocsCreator} from '../common/docs-creator';
import {GymDBDocType} from '../common/doc-types';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ReactionService {

  constructor(
    private docWriteService: DocWriteService,
    private docQueryService: DocQueryService,
    private authService: AuthService) {
  }

  private async addReactionDocForCurrentUser(docId: string, emoji: any) {
    await this.addReactionDocForAuthor(docId, emoji, this.authService.getCurrentUsername());
  }

  async addReactionDocForAuthor(docId: string, emoji: any, author: string) {
    const reactionDoc = DocsCreator.createReaction(docId, author, emoji);
    await this.docWriteService.addDocument(reactionDoc);
  }

  public async getReactions(docId: string): Promise<string[]> {
    const reactionDocs: ReactionDoc[] = await this.getAllReactionDocs(docId);
    return reactionDocs.map(doc => doc.emoji);
  }

  private async getAllReactionDocs(docId: string): Promise<ReactionDoc[]> {
    return await firstValueFrom(this.docQueryService.queryDocs$({
      selector: {
        docId: docId,
        type: GymDBDocType.REACTION
      }
    }));
  }

  private getReactionDocForCurrentUser(docId: string): Observable<ReactionDoc> {
    return this.docQueryService.getDocsOfType$<ReactionDoc>(GymDBDocType.REACTION).pipe(
      map(docs => {
        return docs.filter(d => d.docId == docId && d.author == this.authService.getCurrentUsername())[0];
      })
    );
  }

  async removeReaction(docId: string) {
    const reactionDocs = await firstValueFrom(this.docQueryService.queryDocs$({
      selector: {
        docId: docId,
        author: this.authService.getCurrentUsername(),
        type: GymDBDocType.REACTION
      }
    }));
    if (reactionDocs?.length === 1) {
      await this.docWriteService.removeDocument(reactionDocs[0]);
    }
  }

  public async createOrUpdateReactionDoc(docId: string, emoji: string): Promise<void> {
    const reactionDoc = await firstValueFrom(this.getReactionDocForCurrentUser(docId));
    if (!reactionDoc) {
      await this.addReactionDocForCurrentUser(docId, emoji);
    } else if (reactionDoc.emoji != emoji) {
      (reactionDoc as any).emoji = emoji;
      await this.docWriteService.updateDocument(reactionDoc);
    }
  }

}
